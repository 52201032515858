import React from 'react'
import Icon from '@components/icons'
import style from '../index.module.scss'
import { useTranslation } from 'react-i18next'

export const POPUP_TYPES = {
  CREATE: 'CREATE',
  EDIT_PREVIEW: 'EDIT_PREVIEW',
  EDIT_INVOICE: 'EDIT_INVOICE',
  EDIT_SERIES: 'EDIT_SERIES'
}

export const paymentStatusListForDeclinedAndSubmited = [
  'rejected',
  'declined',
  'new',
  'applied',
  'underwriting',
  'expired',
  'restricted'
]

export const customOptions = ({ value, label, subLabel, image, contactObj, index }, { context }) => {
  if (context === 'value') {
    return (
      <div key={index} className="d-flex flex-grow-0">
        <div className="mr-10">
          <figure className={`mb-0 ${style['b-image']}`}>
            <img
              className={`w-100 rounded-circle ${style['image']}`}
              src={image || 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'}
              alt=""
            />
          </figure>
        </div>
        <div className="d-flex flex-grow-1 justify-content-between align-items-center">
          <div>{label || subLabel}</div>
        </div>
      </div>
    )
  }
  if (context === 'menu') {
    return (
      <React.Fragment>
        <div data-test="select-invoice-contact-option-value" id="contactOptionValue" className="d-flex flex-grow-0">
          <div className="mr-10">
            <figure className={`mb-0 ${style['b-image']}`}>
              <img
                className={`w-100 rounded-circle ${style['image']}`}
                src={image || 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'}
                alt=""
              />
            </figure>
          </div>
          <div className="d-flex align-items-center flex-grow-1 justify-content-between">
            <div className="text-truncate max-w">{label || subLabel}</div>
            <div className="text-truncate max-w">{label && subLabel ? `${subLabel}` : ''}</div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export const customOptionsService = ({ value, label, subLabel, index }, { context }) => {
  if (context === 'value') {
    if (label && !label.props) {
      return (
        <div key={index} className="d-flex flex-grow-0">
          <div className="mr-10"></div>
          <div className="d-flex flex-grow-1 justify-content-between align-items-center">
            <div className="text-truncate max-170">
              <strong>{label || subLabel}</strong>
            </div>
            <Icon name="arrowDownThin" className="ml-4 mt-4" />
          </div>
        </div>
      )
    }
  }
  if (context === 'menu') {
    return (
      <React.Fragment>
        <div data-test="pick-invoice-service-item" id="contactOptionValue11" className="d-flex flex-grow-0">
          <div className="d-flex flex-grow-1 justify-content-between align-items-center">
            <div>{label || subLabel}</div>
            <div>{label && subLabel ? `$${subLabel}` : ''}</div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export const createNewVisible = (array) => {
  if (array.length > 0) {
    if (array[0] && array[0].options.length === 0) {
      return true
    } else return false
  } else return false
}

export const getContactAddText = (invoiceData) => {
  const { t } = useTranslation()
  if (invoiceData && invoiceData.isEmailChanged && invoiceData.isMobileChanged && invoiceData.isNameChanged) {
    return t('payments.update-contact-with-new-phone-number-email-and-name')
  }
  if (invoiceData && invoiceData.isEmailChanged && invoiceData.isMobileChanged && !invoiceData.isNameChanged) {
    return t('payments.update-contact-with-new-phone-number-and-email')
  }
  if (invoiceData && invoiceData.isEmailChanged && !invoiceData.isMobileChanged && invoiceData.isNameChanged) {
    return t('payments.update-contact-with-new-email-and-name')
  }
  if (invoiceData && !invoiceData.isEmailChanged && invoiceData.isMobileChanged && invoiceData.isNameChanged) {
    return t('payments.update-contact-with-new-phone-number-and-name')
  } else if (invoiceData && invoiceData.isEmailChanged && !invoiceData.isMobileChanged && !invoiceData.isNameChanged) {
    return t('payments.update-contact-with-new-email')
  } else if (invoiceData && !invoiceData.isEmailChanged && invoiceData.isMobileChanged && !invoiceData.isNameChanged) {
    return t('payments.update-contact-with-new-phone-number')
  } else if (invoiceData && !invoiceData.isEmailChanged && !invoiceData.isMobileChanged && invoiceData.isNameChanged) {
    return t('payments.update-contact-with-new-name')
  }
}

export const isPastPacificTime = (hourInPacific) => {
  const pacific10AM = new Date(new Date().toLocaleString('en-us', { timeZone: 'America/Los_Angeles' }))
  pacific10AM.setHours(hourInPacific, 0, 0)
  const currentTimeInPacific = new Date(new Date().toLocaleString('en-us', { timeZone: 'America/Los_Angeles' }))

  return Date.parse(pacific10AM) <= Date.parse(currentTimeInPacific)
}
