import notification from '@components/notification'
import * as service from './service'
import {
  setCookies,
  clearCookies,
  getCookie,
  getAdminCookie,
  getAdminOrPartnerCookie,
  setEnterpriseGroupCookies,
  getReferralAppSource,
  setReferralAppSource
} from '@helpers/utility'
import { capitalize } from '@helpers/index'
import { USER_ROLES, ROLE_NAME_PATH } from '@helpers/constants'
import { getConfigCatIdFromMe, getAllExperiments } from '@helpers/configcat'
import _ from 'lodash'

export default {
  state: {
    user: getCookie() || {},
    adminUser: getAdminCookie() || {},
    adminOrPartner: getAdminOrPartnerCookie() || {},
    editedurl: '',
    apiError: null,
    notificationCount: 0,
    hasMeValue: false,
    qbsettings: {},
    isPartner: false,
    referralApp: getReferralAppSource(),
    users: [],
    locations: [],
    maxUsers: 0,
    maxLocation: 0,
    activeLocationCount: 0,
    activeUserCount: 0,
    totalLocationCount: 0,
    totalUserCount: 0
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true,
        apiError: null
      }
    },
    onError(state, data) {
      const err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
          ? data.data.message
          : data && data.error
          ? data.error
          : data && data.data && data.data.error
          ? data.data.error
          : data && data.data && data.data.err_msg
          ? data.data.err_msg
          : 'Please try again later'
      notification('error', capitalize(err))
      return {
        ...state,
        loading: false,
        apiError: err
      }
    },
    onupdateUserFromCookieSuccess(state, data) {
      return {
        ...state,
        user: getCookie() || {}
      }
    },
    onresetPasswordError(state, data) {
      const err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
          ? data.data.message
          : data && data.error
          ? data.error
          : data && data.data && data.data.error
          ? data.data.error
          : data && data.data && data.data.err_msg
          ? data.data.err_msg
          : 'Please try again later'
      return {
        ...state,
        loading: false,
        apiError: err
      }
    },
    onPasswordStatusError(state, data) {
      return {
        ...state,
        loading: false,
        showRestForm: false
      }
    },
    onMagicLoginError(state, data) {
      console.log(data)
      let isProspect = false
      if (data.requestPayload && data.requestPayload.role && data.requestPayload.role === 'prospect') isProspect = true

      return {
        ...state,
        loading: false,
        errorMessage: 'This link has been expired or already used.',
        requestPayload: data.requestPayload,
        isProspect
      }
    },
    onSignUpError(state, error) {
      const data = error && error.data ? error.data : {}

      let contactAdmin = false
      let resendemail = false
      let msgText = ''
      if (data == null && typeof data === 'object') {
        msgText = 'please enter valid credentials'
      } else {
        if (
          data.error === 'Incorrect password' ||
          data.error === 'Invalid email' ||
          data.error === 'Invalid credentials'
        ) {
          msgText = 'Invalid credentials'
        } else if (data.error === 'Inactive account') {
          contactAdmin = true
          msgText = 'Your account is currently inactive. Please contact administrator'
        } else if (data.error === 'Suspended account') {
          contactAdmin = true
          msgText = 'Your account is currently suspended. Please contact administrator'
        } else if (data.error === 'Email not verified') {
          msgText = 'Your email is not verified'
          resendemail = true
        } else {
          msgText = data.error
        }
      }

      return {
        ...state,
        loading: false,
        apiError: msgText,
        contactAdmin,
        resendemail,
        signUpApiError: msgText
      }
    },
    onVerifyEmailError(state, data) {
      return {
        ...state,
        loading: false,
        errorMessage: 'Your email address is already verified or the link has been expired'
      }
    },
    onResendMagicLinkError(state, data) {
      return {
        ...state,
        loading: false,
        resendMagicerror: 'Your email address is already verified or the link has been expired'
      }
    },
    onLoginSuccess(state, data) {
      setCookies(data.data)
      return {
        ...state,
        loading: false,
        user: data.data
      }
    },
    onMarkInvoiceUnlocked(state, data) {
      if (_.get(state, 'me.account_settings.payment.isInvoiceUnlocked') !== undefined) {
        state.me.account_settings.payment.isInvoiceUnlocked = true
      }

      return {
        ...state
      }
    },
    onMeSuccess(state, data) {
      const currentProducts = []

      const disableProducts = []

      const allProducts = []
      if (data && data.products && data.products.length) {
        for (let i = 0; i < data.products.length; i++) {
          if (data.products[i].product_id && data.products[i].status === 1) {
            currentProducts.push(data.products[i].product_id.product_name)
            allProducts.push(data.products[i].product_id)
          } else if (data.products[i].product_id && data.products[i].status === 0) {
            disableProducts.push(data.products[i].product_id.product_name)
          }
        }
      }
      if (data.enterprise_group) {
        setEnterpriseGroupCookies(data.user_unique_id, data.enterprise_group)
      }

      return {
        ...state,
        loading: false,
        me: data,
        products: currentProducts,
        disabledProducts: disableProducts,
        allProducts: allProducts
      }
    },
    onValidateDomainSuccess(state, data) {
      const domainDeatils = data.data
      return {
        ...state,
        loading: false,
        domainSettings: domainDeatils,
        isPartner: data.role === 'partner',
        role: data.role,
        siteTitle: domainDeatils.generalSettings ? domainDeatils.generalSettings.site_title : 'GoSite Dashboard',
        canRegister: domainDeatils.generalSettings ? domainDeatils.generalSettings.can_register : true,
        emailVerified: domainDeatils.generalSettings ? domainDeatils.generalSettings.email_verified : false,
        enable_feedback: domainDeatils.generalSettings ? domainDeatils.generalSettings.enable_feedback : true,
        showRecaptcha: domainDeatils.generalSettings ? domainDeatils.generalSettings.showRecaptcha : false
      }
    },
    async onLogout(state, data) {
      await clearCookies('lastLoggedInUuid')
      await clearCookies(state.user.user_unique_id)
      localStorage.removeItem('savedFilter')
      return {
        loading: false
      }
    },
    onAuthSignupSuccess(state, data) {
      setCookies(data.data)
      return {
        ...state,
        loading: false,
        user: data.data
      }
    },
    onSignUpSuccess(state, data) {
      return {
        ...state,
        loading: false,
        signUpuser: data.data
      }
    },
    onSendFeedbackSuccess(state, data) {
      notification('success', 'Thank you for letting us know and considering us as part of your business plan')
      return {
        ...state,
        loading: false
      }
    },
    onQuickBookAuthSuccess(state, data) {
      // if(window.location.pathname.indexOf("business-settings")>-1){
      //     notification("success", "Your QuickBooks handle has been linked successfully");
      // }
      return {
        ...state,
        loading: false,
        quickbook: data
      }
    },
    onThumbtackAuthSuccess(state, data) {
      // if(window.location.pathname.indexOf("business-settings")>-1){
      //     notification("success", "Your QuickBooks handle has been linked successfully");
      // }
      return {
        ...state,
        loading: false,
        thumbtack: data
      }
    },
    onGetNotificationsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        notifications: data
      }
    },
    onSuccess(state, data, msg) {
      if (msg) {
        notification('success', msg)
      }
      return {
        ...state,
        loading: false
      }
    },
    onCountSuccess(state, data) {
      return {
        ...state,
        loading: false,
        notificationCount: data.unread
      }
    },
    handleOnMeError(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onSetMyAccountActiveTab(state, data) {
      return {
        ...state,
        loading: false,
        myAccountActiveTab: data
      }
    },
    onSetbusinessSettingActiveTab(state, data) {
      return {
        ...state,
        loading: false,
        businessSettingsActiveTab: data
      }
    },
    onMagicLoginSucess(state, data) {
      const u = getCookie()
      if (u && u.user_unique_id && data.data && data.data.role && data.data.role === 'prospect') {
        // clearing the existing logged in user
        clearCookies(u.user_unique_id)
      }
      setCookies(data.data)
      return {
        ...state,
        loading: false,
        user: data.data
      }
    },
    onVerifyOtpSuccess(state, data) {
      setCookies(data.data)
      return {
        ...state,
        loading: false,
        user: data.data
      }
    },
    onresetPasswordSuccess(state, data) {
      return {
        ...state,
        loading: false,
        resetSuccessMessage: data.message
      }
    },
    onregenerateOtpSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    clearError(state) {
      return {
        ...state,
        loading: false,
        apiError: null
      }
    },
    updateUserStatusSuccess(state, data) {
      const user = state.user
      user.status = 'active'
      return {
        ...state,
        loading: false,
        user
      }
    },
    onGetLocationsSuccess(state, data) {
      const customers = []
      const locations = []

      const formatedLocations = []
      if (data && data.locations) {
        data.locations.forEach((loc) => {
          if (loc.location_identifier && loc.city) loc.name = loc.location_identifier + ' ' + loc.city
          else if (loc.city) loc.name = loc.city
          else if (loc.location_identifier) loc.name = loc.location_identifier
          else if (loc.locationName) loc.name = loc.locationName
          else loc.name = loc.displayname
          if (loc.products.indexOf('review-monitor') > -1 || loc.products.indexOf('review-request') > -1) {
            customers.push({
              c: loc.customer,
              r: loc.review_min_rating,
              i: loc.customerData.reference_number
            })
            locations.push({
              _id: loc.customer,
              name: loc.name,
              review_min_rating: loc.review_min_rating,
              i: loc.customerData.reference_number,
              displayname: loc.displayname
            })
          }
          formatedLocations.push({
            _id: loc.customer,
            name: loc.name,
            review_min_rating: loc.review_min_rating,
            i: loc.customerData.reference_number,
            products: loc.products,
            location_id: loc.location_id,
            customer: loc.customer,
            displayname: loc.displayname,
            city: loc.city
          })
        })
      }
      return {
        ...state,
        loading: false,
        enterpriselocations: data.locations,
        locations: locations,
        customers: customers,
        formatedLocations // for universal locations filter
      }
    },

    onIsExistingYextCustomerSuccess(state, data) {
      return {
        ...state,
        loading: false,
        isExistingYextCustomer: data.isExistingYextCustomer
      }
    },

    updateHasMeValueSuccess(state) {
      return {
        ...state,
        hasMeValue: true
      }
    },
    onresetPasswordStatusSuccess(state, data) {
      let showRestForm = false
      if (data.message === 'valid link') showRestForm = true
      return {
        ...state,
        loading: false,
        showRestForm
      }
    },
    onresetPasswordsSuccess(state, data) {
      notification('success', 'You have successfully reset your password')
      return {
        ...state,
        loading: false,
        resetSucess: data
      }
    },
    onverifyEmailSucess(state, data) {
      return {
        ...state,
        loading: false,
        verifyMessage: 'Your email has been successfully verified'
      }
    },
    onResendMagicLinkSucess(state, data) {
      const resendMagiclogin = data
      console.log(resendMagiclogin)
      let message = `A new magic login link has been sent to the e-mail ID ${resendMagiclogin.email}`
      if (resendMagiclogin && resendMagiclogin.contact_phone) {
        message = `${message}  and mobile number ending with ${resendMagiclogin.contact_phone.substr(
          resendMagiclogin.contact_phone.length - 4
        )} `
      }
      resendMagiclogin.message = message

      return {
        ...state,
        loading: false,
        resendMagiclogin
      }
    },
    quickBooksettings(state, data) {
      return {
        ...state,
        loading: false,
        qbsettings: data
      }
    },
    onGetFeedbackDetailsSucess(state, data) {
      const feedBackDeatils = {}
      feedBackDeatils.pageTitle = data.bussiness_name
      feedBackDeatils.logo = data.logo
      feedBackDeatils.headerColor = data.headerbg
      feedBackDeatils.orderId = data.content.orderid
      feedBackDeatils.isPaymentFeedbackEnabled = data.payment_feedback
      feedBackDeatils.email = data.content.customer_email
      if (data.content.customer_phone && data.twilioStatus) {
        feedBackDeatils.sms = true
      } else {
        feedBackDeatils.sms = false
      }
      return {
        ...state,
        loading: false,
        feedBackAllDetails: data,
        feedBackDeatils
      }
    },
    onSendPaymentFeedbackSucess(state, data) {
      const paymentsFeedbacks = data && data.data ? data.data : data
      return {
        ...state,
        loading: false,
        paymentsFeedbacks
      }
    },
    onGetFeedbackSettingsSucess(state, data) {
      const feedBackSettings = {}
      feedBackSettings.pageTitle = data.bussiness_name
      feedBackSettings.logo = data.logo
      feedBackSettings.headerColor = data.headerbg
      feedBackSettings.review_id = data.review_id
      feedBackSettings.sucesslogo = data.links
      feedBackSettings.isCommentenabled = data.review_type
      return {
        ...state,
        loading: false,
        feedBackSettings
      }
    },
    onGetFeedbackStatusSuccess(state, data) {
      return {
        ...state,
        loading: false,
        feedback_status: data
      }
    },
    onGetCommonFeedbackStatusSuccess(state, data) {
      return {
        ...state,
        loading: false,
        common_feedback_status: data
      }
    },
    onGetReviewSettingsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        review_settings: data.data
      }
    },
    onSendReviewFeedbackSuccess(state, data) {
      return {
        ...state,
        loading: false,
        reviewFeedback: data
      }
    },
    onGetThemeSettings(state, data) {
      return {
        ...state,
        loading: false,
        themeSettings: data
      }
    },
    onSetReferralApp(state, app) {
      setReferralAppSource(app || '')
      return {
        ...state,
        referralApp: app || ''
      }
    },
    onGetAppsSuccess(state, data) {
      return {
        ...state,
        referralAppsData: data && data.data
      }
    },
    onGetSettingsDataSuccess(state, data) {
      return {
        ...state,
        settingsOptionData: data && data.settings
      }
    },
    setEnterpriseDashboardForMultiLocation(state) {
      return {
        ...state,
        isEnterpriseDashboardForMultiLocation: true,
        isSMBDashboardForMultiLocation: false
      }
    },
    setSMBDashboardForMultiLocation(state) {
      return {
        ...state,
        isEnterpriseDashboardForMultiLocation: false,
        isSMBDashboardForMultiLocation: true
      }
    },
    ongetChargebeeSubscriptionSuccess(state, data) {
      return {
        ...state,
        loading: false,
        chargeBeeData: data && data.chargeBeeData
      }
    },
    ongetSubscriptionSuccess(state, data) {
      let subscriptionData = {}
      let planDetails = {}
      let hasSubscription = false
      let invoiceEstimate = {}

      if (data.data) {
        subscriptionData = data.data
        planDetails = data.planDetails
        invoiceEstimate = data.estimate ? data.estimate.invoice_estimate : {}
        hasSubscription = true
      } else {
        hasSubscription = false
      }
      return {
        ...state,
        loading: false,
        subscriptionData: subscriptionData,
        planDetails: planDetails,
        invoiceEstimate: invoiceEstimate,
        hasSubscription: hasSubscription
      }
    },
    onCreateManagerSuccess(state, data) {
      notification('success', 'User has been created successfully')
      return {
        ...state,
        loading: false,
        createdManager: data.data
      }
    },
    onManageUsersSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    ongetSavedFiltersSUccess(state, data) {
      return {
        ...state,
        loading: false,
        savedLocationFilter: data
      }
    },
    reduceUNBStatusForEnterprise(state, data) {
      return {
        ...state,
        unbTabStatusForEnterprise: {
          fromUNB: data.fromUNB,
          tab: data.tab
        }
      }
    },
    ongetPricingLimitsSuccess(state, data) {
      return {
        ...state,
        users: data.users,
        locations: data.locations,
        maxUsers: data.max_users,
        maxLocation: data.max_locations,
        activeLocationCount: data.active_location_count,
        activeUserCount: data.active_user_count,
        totalLocationCount: data.total_location_count,
        totalUserCount: data.total_user_count
      }
    }
  },
  effects: {
    markInvoiceUnlocked() {
      this.onMarkInvoiceUnlocked()
    },
    async saveUNBTabStatusForEnterprise(payload, rootState) {
      await this.reduceUNBStatusForEnterprise(payload)
    },
    async getSavedFilters(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getSavedFilters(payload)
        this.ongetSavedFiltersSUccess(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async manageUsers(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.manageUsers(payload)
        this.onManageUsersSuccess(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async createManager(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.createManager(payload)
        this.onCreateManagerSuccess(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async enterpriseDashboardForMultiLocation() {
      await this.setEnterpriseDashboardForMultiLocation()
    },
    async smbDashboardForMultiLocation() {
      await this.setSMBDashboardForMultiLocation()
    },
    async googleAuth(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.googleAuth(payload)
        if (res && res.cred) {
          const signupRes = await service.googleAuth({ cred: res.cred })
          await this.onAuthSignupSuccess(signupRes)
        } else {
          await this.onLoginSuccess(res)
          return res
        }
      } catch (e) {
        this.onError(e)
      }
    },
    async googleAuthsignup(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.googleAuth(payload)
        if (res.data.signin === true) {
          await this.onAuthSignupSuccess(res)
        } else {
          res = await service.googleAuth({ cred: res.cred })
          await this.onAuthSignupSuccess(res)
        }
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async facebookAuth(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.facebookAuth(payload)
        if (res && res.data && !res.data.token) {
          console.log('<==HAS NO CRED===>')
          res = await service.facebookAuth(payload)
        }
        await this.onLoginSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async login(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.login(payload)
        await this.onLoginSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async validateDomain() {
      this.onRequest()
      try {
        const res = await service.validateDomain()
        this.onValidateDomainSuccess(res)
        return res
      } catch (e) {
        //  this.onError(e)
      }
    },
    async getMe(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.me(payload)
        const chargeBeeRes = await service.getChargebeeSubscription()
        const subscriptionRes = await service.getSubscription()
        const features = await getAllExperiments(getConfigCatIdFromMe(res))
        const isReadOnlyRole = res[ROLE_NAME_PATH] === USER_ROLES.READ_ONLY
        const me = {
          ...res,
          ...{ isReadOnlyRole },
          ...{ chargeBeeData: chargeBeeRes.chargeBeeData },
          ...{ subscriptionData: subscriptionRes.data ? subscriptionRes.data : subscriptionRes },
          ...{ features: features }
        }
        await this.onMeSuccess(me)
        return me
      } catch (e) {
        // this.onError(e)
        this.handleOnMeError(e)
      }
    },
    async getLocale() {
      const res = await service.locale()
      if (res.locale) {
        localStorage.setItem('language', `${res.locale}`.toLowerCase())
      }
    },
    async updateLocale() {
      await service.patchLocale(localStorage.getItem('language'))
    },
    async logout(payload, rootState) {
      try {
        const res = await service.logout(payload)
        await this.onLogout(res)
        return res
      } catch (e) {
        // this.onError(e)
      }
    },
    async signup(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.signup(payload)
        await this.onSignUpSuccess(res)
        return res
      } catch (e) {
        this.onSignUpError(e)
      }
    },
    async getGositeNotifications(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getNotifications(payload)
        this.onGetNotificationsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async readNotification(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.readNotification(payload)
        this.onSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getNotificationCount(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getNotificationCount(payload)
        this.onCountSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async sendFeedback(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.sendFeedback(payload)
        await this.onSendFeedbackSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async quickBookAuth(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.quickBookAuth(payload)
        await this.onQuickBookAuthSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async thumbtackAuth(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.thumbtackAuth(payload)
        await this.onThumbtackAuthSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async setMyAccountActiveTab(payload) {
      try {
        this.onSetMyAccountActiveTab(payload)
        return payload
      } catch (e) {
        console.log(e)
      }
    },
    async setbusinessSettingsActiveTab(payload) {
      try {
        this.onSetbusinessSettingActiveTab(payload)
        return payload
      } catch (e) {
        console.log(e)
      }
    },
    async magicLogin(payload, rootState) {
      this.onRequest()
      const requestPayload = payload.queryData

      try {
        delete payload.queryData
        const res = await service.magicLogin(payload)
        res.requestPayload = requestPayload
        await this.onMagicLoginSucess(res)
        return res
      } catch (e) {
        e.requestPayload = requestPayload
        this.onMagicLoginError(e)
      }
    },
    async onResendtOtp(payload, rootstate) {
      this.onRequest()
      try {
        const res = await service.onResendtOtp(payload)
        await this.onSuccess(res, 'TFA generated successfully')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async verifyOtp(payload, rootstate) {
      this.onRequest()
      try {
        const res = await service.verifyOtp(payload)
        await this.onVerifyOtpSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async smsVerifyOtp(payload, rootstate) {
      this.onRequest()
      try {
        const res = await service.smsOTPVerification(payload)
        notification('success', 'Phone number has been verified successfully')
        this.onSuccess(res, '')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async resetPassword(payload) {
      this.onRequest()
      try {
        const res = await service.resetPassword(payload)
        this.onresetPasswordSuccess(res)
        return res
      } catch (e) {
        this.onresetPasswordError(e)
      }
    },
    async regenerateOtp(payload) {
      this.onRequest()
      try {
        const res = await service.regenerateOtp(payload)
        this.onresetPasswordSuccess(res)
        return res
      } catch (e) {
        this.onresetPasswordError(e)
      }
    },
    async regenerateOtpCall(payload) {
      this.onRequest()
      try {
        const res = await service.regenerateOtpCall(payload)
        this.onresetPasswordSuccess(res)
        return res
      } catch (e) {
        this.onresetPasswordError(e)
      }
    },
    async sendOtp(payload) {
      this.onRequest()
      try {
        const res = await service.sendOtp(payload)
        this.onregenerateOtpSuccess(res)
        return res
      } catch (e) {
        this.onresetPasswordError(e)
      }
    },
    async clearApiError(payload) {
      try {
        this.clearError()
      } catch (e) {
        console.log(e)
      }
    },
    updateUserStatus(payload) {
      this.updateUserStatusSuccess(payload)
    },
    async getEnterpriseLocations(payload) {
      try {
        const res = await service.getEnterpriseLocations(payload)
        await this.onGetLocationsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getIsExistingYextCustomer() {
      try {
        const res = await service.getIsExistingYextCustomer()
        await this.onIsExistingYextCustomerSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async resetPasswordStatus(payload) {
      this.onRequest()
      try {
        const res = await service.resetPasswordStatus(payload)
        this.onresetPasswordStatusSuccess(res)
        return res
      } catch (e) {
        this.onPasswordStatusError(e)
      }
    },
    async resetPasswords(payload) {
      this.onRequest()
      try {
        const res = await service.resetPasswords(payload)
        this.onresetPasswordsSuccess(res)
        return res
      } catch (e) {
        this.onPasswordStatusError(e)
      }
    },
    updateHasMeValue() {
      this.updateHasMeValueSuccess()
    },
    async verifyEmail(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.verifyEmail(payload)
        await this.onverifyEmailSucess(res)
        return res
      } catch (e) {
        this.onVerifyEmailError(e)
      }
    },
    async resendMagicLink(payload, rootState) {
      try {
        const res = await service.resendMagicLink(payload)
        await this.onResendMagicLinkSucess(res.data)
        return res
      } catch (e) {
        this.onResendMagicLinkError(e)
      }
    },
    async updteaQBsettings(payload, rootState) {
      await this.quickBooksettings(payload)
      return payload
    },
    async getFeedbackDetails(payload, rootState) {
      try {
        const res = await service.getFeedbackDetails(payload)
        await this.onGetFeedbackDetailsSucess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async sendPaymentFeedback(payload, rootState) {
      try {
        const res = await service.sendPaymentFeedback(payload)
        await this.onSendPaymentFeedbackSucess(res.data)
        return res
      } catch (e) {
        this.onSendPaymentFeedbackSucess(e.data)
      }
    },
    async sendPaymentSuccessFeedback(payload, rootState) {
      try {
        const res = await service.sendPaymentSuccessFeedback(payload)
        await this.onSendPaymentFeedbackSucess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getFeedbackSettings(payload, rootState) {
      try {
        const res = await service.getFeedbackSettings(payload)
        await this.onGetFeedbackSettingsSucess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async trackPaymenturl(payload, rootState) {
      try {
        const res = await service.trackPaymenturl(payload)
        await this.onSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async sendFeedbackComment(payload, rootState) {
      try {
        const res = await service.sendFeedbackComment(payload)
        await this.onSuccess(res)
        //  notification("success", "Comments Added");
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateUserFromCookie(rootState) {
      try {
        await this.onupdateUserFromCookieSuccess()
      } catch (e) {
        this.onError(e)
      }
    },
    async getFeedbackStatus(payload, rootState) {
      try {
        const res = await service.getFeedbackStatus(payload)
        await this.onGetFeedbackStatusSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getCommonFeedbackStatus(payload, rootState) {
      try {
        const res = await service.getCommonFeedbackStatus(payload)
        await this.onGetCommonFeedbackStatusSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getReviewSettings(payload, rootState) {
      try {
        const res = await service.getReviewSettings(payload)
        await this.onGetReviewSettingsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async sendReviewFeedback(payload, rootState) {
      try {
        const res = await service.sendReviewFeedback(payload)
        await this.onSendReviewFeedbackSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getThemeSettings(payload, rootState) {
      try {
        const res = await service.getThemeSettings(payload)
        this.onGetThemeSettings(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async getEnterpriseNotifications(payload) {
      try {
        const res = await service.getEnterpriseNotifications(payload)
        this.onGetNotificationsSuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async setReferralApp(app) {
      try {
        await this.onSetReferralApp(app)
      } catch (e) {
        console.log(e)
      }
    },
    async getReferralAppProperties() {
      try {
        const res = await service.getReferralAppProperties()
        this.onGetAppsSuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async getSettingsData(payload) {
      try {
        const res = await service.getSettingsData(payload)
        this.onGetSettingsDataSuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async getChargebeeSubscription(payload) {
      this.onRequest()
      try {
        const res = await service.getChargebeeSubscription()
        this.ongetChargebeeSubscriptionSuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async getSubscription(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getSubscription(payload)
        this.ongetSubscriptionSuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async getPricingLimits(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getPricingLimits(payload)
        this.ongetPricingLimitsSuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    }
  }
}
