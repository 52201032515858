import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import Icons from "./icons";
const mapDispatchToProps = ({ auth }) => {
    return {
        ...auth
    };
};

const mapStateToProps = ({ auth }) => {
    return {
        ...auth
    };
};

class Footer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            submitSpinner: false,
            comments: "",
            email: "",
        }
    }
    showFeedbackPopup() {
        this.setState({ open: !this.state.open })
    }
    handleCancel(e) {
        e.preventDefault()
        this.setState({ open: false })
    }
    feedbackOpenButton() {
        document.body.classList.toggle("footerFeedbackActive");
    }
    async feedbackHandleSubmit(e) {
        e.preventDefault()
        let payload = {
            comments: this.state.comments,
            contact_me: true,
            customer_email: this.state.email
        }
        await this.props.sendFeedback({ feedback: payload })
        this.handleCancel(e)
    }
    async componentDidMount() {
        if (!this.props.domainSettings)
            await this.props.validateDomain()
        if (this.props.me && this.props.me.user_email) {
            this.setState({ email: this.props.me.user_email })
        }
    }

    render() {
        return (
            <React.Fragment>
                <footer className="main-footer">
                    <div className="d-flex position-relative p-10 footer-container">

                        <div className="d-none d-md-flex align-items-center mr-0 ml-5">
                            <button className="border-0 footer-action-button" onClick={() => this.feedbackOpenButton()} type="button">
                                <i className="btn-icon footer-icon-down-arrow d-flex top-minus-2"><Icons name="fillArrowDown" /></i>
                            </button>
                            <button className="border-0 footer-action-button footer-action-email-button" onClick={() => this.showFeedbackPopup()} type="button">
                                <i className="btn-icon footer-icon-email d-flex"><Icons name="email" /></i>
                            </button>
                        </div>
                        <div className="b-content w-100 justify-content-center align-items-center justify-content-md-between b-list">

                            <div className="d-flex ">
                                <div className="list-item d-none d-md-block mr-md-0">
                                    <span className="list-item-text">Powered by GoSite</span>
                                </div>
                                <span className="d-none d-md-block list-item-link mx-5 separator">|</span>
                                <div className="list-item mr-md-0"><a className="list-item-link text-decoration-none" href="https://www.gosite.com/terms-of-service">Terms of Service</a></div>
                                <span className="d-none d-md-block list-item-link mx-5 separator">|</span>
                                <div className="list-item mr-md-0"><a className="list-item-link text-decoration-none" href="https://www.gosite.com/privacy-policy">Privacy Policy</a></div>
                            </div>

                            <div className="d-flex align-items-center">
                                <div className="list-item mr-md-0"><a href="https://www.gosite.com/knowledge/" className="list-item-link text-decoration-none">FAQ</a></div>
                                {this.props.domainSettings && this.props.domainSettings.generalSettings.enable_feedback === true ?
                                    <div className="d-flex align-items-center list-item mr-0">
                                        <span className="d-none d-md-block list-item-link mx-5 separator">|</span>
                                        <button className="border-0 list-item-link p-0" type="button" onClick={() => this.showFeedbackPopup()}>Feedback
                                            <i className="btn-icon ml-10 icon-feedback d-md-none"><Icons name="chat" /></i>
                                        </button>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                    {this.state.open ?
                        <div className="feedback-popover-wrapper shadow-lg bg-white rounded-lg p-30">
                            <div className="content">
                                <h3 className="title mb-20">Send us your feedback!</h3>

                                <form>
                                    <div className="form-group mb-20">
                                        <label className="label-text">SMS TEMPLATE</label>
                                        <textarea className="form-control rounded-sm custom-textarea pr-15"
                                            placeholder="Enter your feedback"
                                            name="comments"
                                            id=""
                                            value={this.state.comments}
                                            onChange={(e) => { this.setState({ comments: e.target.value }) }}
                                        />
                                    </div>
                                    <div className="d-flex">
                                        <button onClick={(e) => this.feedbackHandleSubmit(e)} disabled={this.state.submitSpinner} id="" type="submit" className="btn btn-primary px-30 mr-10 border-0">
                                            {this.state.submitSpinner ? (<i className={"btn-icon top-minus-2 mr-5 btn-loader"}><Icons name="spinner" /></i>) : null}
                      SAVE
                                        </button>
                                        <button onClick={(e) => this.handleCancel(e)} id="" type="reset" className="btn px-15 btn-footer-cancel">
                      CANCEL
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                        : null}
                </footer>
            </React.Fragment>

        )
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Footer);