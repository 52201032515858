// enum of all payment statuses
export const PAYMENT_STATUS = Object.freeze({
  PROCESSING: 'processing',
  REFUND_PROCESSING: 'refund_processing',
  REFUND_FAILED: 'refund_failed',
  ACCEPTED: 'accepted', // non-invoice payment successfully completed
  PAID: 'paid', // invoice payment successfully completed
  DECLINED: 'declined',
  CANCELLED: 'cancelled',
  CANCELED: 'canceled',
  DRAFT: 'draft',
  OPEN: 'open',
  SCHEDULED: 'scheduled',
  DUE: 'due', // invoice payment is overdue
  CAPTURE: 'capture',
  REFUND: 'refund',
  PENDING: 'pending',
  VOIDED: 'voided'
})

// enum of all payment sources
export const PAYMENT_SOURCE = Object.freeze({
  BOOKING: 'booking',
  DIRECT: 'direct',
  INVOICE: 'invoice',
  GET_PAID: 'getpaid',
  WEBSITE: 'website',
  END_USER: 'end-user-payment', // old source not available for new payments
  GET_PAID_OLD: 'get_paid' // old source not available for new payments
})

// mapping of payment source icons
export const PAYMENT_SOURCE_ICON = Object.freeze({
  [PAYMENT_SOURCE.BOOKING]: {
    label: 'Booking',
    iconName: 'sourceBooking'
  },
  [PAYMENT_SOURCE.DIRECT]: {
    label: 'Direct Payment',
    iconName: 'sourceDirect'
  },
  [PAYMENT_SOURCE.GET_PAID]: {
    label: 'Payment Link',
    iconName: 'sourceGetPaid'
  },
  [PAYMENT_SOURCE.INVOICE]: {
    label: 'Invoice',
    iconName: 'sourceInvoice'
  },
  [PAYMENT_SOURCE.WEBSITE]: {
    label: 'Website',
    iconName: 'sourceWebsite'
  },
  [PAYMENT_SOURCE.END_USER]: {
    // old source not available for new payments
    label: 'Website',
    iconName: 'sourceWebsite'
  },
  [PAYMENT_SOURCE.GET_PAID_OLD]: {
    label: 'Payment Link', // old source not available for new payments
    iconName: 'sourceGetPaid'
  }
})

// payment source filter menu options
export const PAYMENT_SOURCE_FILTER_OPTIONS = [
  {
    label: 'All Transactions',
    value: 'all'
  },
  {
    label: 'Direct Payment',
    value: PAYMENT_SOURCE.DIRECT
  },
  {
    label: 'Booking',
    value: PAYMENT_SOURCE.BOOKING
  },
  {
    label: 'Website',
    value: PAYMENT_SOURCE.WEBSITE
  },
  {
    label: 'Invoice',
    value: PAYMENT_SOURCE.INVOICE
  },
  {
    label: 'Payment Link',
    value: PAYMENT_SOURCE.GET_PAID
  }
]

//currency formatter
export const USDOLLAR = new Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD'
})

// payment source filter menu options
export const PAYMENT_SOURCE_FILTER_PAYMENT_OPTIONS = [
  {
    label: 'All Transactions',
    value: 'all'
  },
  {
    label: 'Direct Payment',
    value: PAYMENT_SOURCE.DIRECT
  },
  {
    label: 'Booking',
    value: PAYMENT_SOURCE.BOOKING
  },
  {
    label: 'Website',
    value: PAYMENT_SOURCE.WEBSITE
  },
  {
    label: 'Payment Link',
    value: PAYMENT_SOURCE.GET_PAID
  }
]

// payment status filter menu options
export const PAYMENT_STATUS_FILTER_OPTIONS = [
  {
    label: 'All Status',
    value: 'all'
  },
  {
    label: 'Overdue',
    value: `${PAYMENT_STATUS.DUE}` // for payments with overdue status
  },
  {
    label: 'Refund Processing',
    value: `${PAYMENT_STATUS.REFUND_PROCESSING}` // for payments with refund processing status
  },
  {
    label: 'Refund Failed',
    value: `${PAYMENT_STATUS.REFUND_FAILED}` // for payments with refund failed status
  },
  {
    label: 'Due',
    value: `${PAYMENT_STATUS.OPEN}` // for payments with due status
  },
  {
    label: 'Paid',
    value: `${PAYMENT_STATUS.PAID},${PAYMENT_STATUS.ACCEPTED}`
  },
  {
    label: 'Processing',
    value: `${PAYMENT_STATUS.PROCESSING},${PAYMENT_STATUS.CAPTURE}`
  },
  {
    label: 'Pending',
    value: `${PAYMENT_STATUS.PENDING}`
  },
  {
    label: 'Refunded',
    value: PAYMENT_STATUS.REFUND
  },
  {
    label: 'Declined',
    value: PAYMENT_STATUS.DECLINED
  },
  {
    label: 'Canceled',
    value: `${PAYMENT_STATUS.CANCELLED},${PAYMENT_STATUS.CANCELED}`
  },
  {
    label: 'Scheduled',
    value: PAYMENT_STATUS.SCHEDULED
  },
  {
    label: 'Voided',
    value: PAYMENT_STATUS.VOIDED
  }
]

export const paymentPaginationName = 'go_payment_pagination'
