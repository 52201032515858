import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import Popup from '@components/popups/basic-modal'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { phoneNumberAutomasking } from '@helpers/utility'
import { numberMask } from '@helpers/inputmask'
import Style from '../index.module.scss'
import * as Yup from 'yup'
import Icons from '@components/icons'
import { segmentEventTrack } from '@helpers/segment'
import { Button, Box } from '@gositeinc/ui'
import config from '@config/index'
import notification from '@app/components/notification'
import { useCaptchaVerification } from '@app/helpers/useCaptchaVerification'

export const VerifyMobilePopup = ({
  open,
  onClose,
  apiError,
  verifyOtp,
  onRequestOtp,
  onRequestOtpOnCall,
  securityText,
  title
}) => {
  const [submitting, setSubmitting] = useState(false)
  const [verifyPopupData, setVerifyPopupData] = useState({
    contact_phone: '',
    submitting: false,
    otpSubmitting: false,
    otpPage: false
  })
  const dispatch = useDispatch()
  const me = useSelector((state) => state.auth.me)
  const { capthcaValue,
    noOfRetries,
    isResendCodeDisabled,
    showReCaptcha,
    timer,
    onChangeRecaptche,
    setIsResendCodeDisabled,
    resetTimer,
    incrementNumberOfRetries } = useCaptchaVerification()

  useEffect(() => {
    const getState = async () => {
      if (!me) await dispatch.auth.getMe()
    }
    getState()
  }, [])

  const verifyPhoneNumber = (contact_phone) => new Promise((resolve) => {
    onRequestOtp({ contact_phone }).then(function (response) {
      resolve(response)
    })
  })

  const handleVerifyPopupSubmit = async (values) => {
    await setSubmitting(true)
    setVerifyPopupData({ ...verifyPopupData, contact_phone: values.txt_phone })
    const resData = await verifyPhoneNumber(values.txt_phone)
    if (!resData) {
      trackPhoneNumber(false)
    } else {
      resetTimer()
      trackPhoneNumber(true)
      await setVerifyPopupData({ ...verifyPopupData, contact_phone: values.txt_phone, otpPage: true })
    }
    await setSubmitting(false)
  }
  const trackPhoneNumber = (valid) => {
    let data = {}
    data = {
      event: 'entered_mobile_phone_verification',
      properties: {
        user_id: me && me._id,
        valid_number: valid,
        role: me && me.role_id && me.role_id.role_name
      }
    }
    segmentEventTrack(data)
  }

  const trackResendOTP = (type) => {
    let data = {}
    data = {
      event: 'retried_mobile_phone_verification',
      properties: {
        user_id: me && me._id,
        retry_method: type,
        role: me && me.role_id && me.role_id.role_name
      }
    }
    segmentEventTrack(data)
  }
  const trackOTP = (valid) => {
    let data = {}
    data = {
      event: 'entered_code_verification',
      properties: {
        user_id: me && me._id,
        valid_code: valid,
        role: me && me.role_id && me.role_id.role_name
      }
    }
    segmentEventTrack(data)
  }
  const handleResend = async () => {
    incrementNumberOfRetries()
    setIsResendCodeDisabled(true)
    resetTimer()
    trackResendOTP('text')
    await onRequestOtp({ contact_phone: verifyPopupData.contact_phone })
  }

  const handleOtpOnCall = async () => {
    incrementNumberOfRetries()
    setIsResendCodeDisabled(true)
    resetTimer()
    trackResendOTP('call')
    await onRequestOtpOnCall({ contact_phone: verifyPopupData.contact_phone })
  }
  const handleOtpSubmit = async (values, { resetForm }) => {
    let payload = {}
    payload.otp = values.txt_otp
    payload.contact_phone = verifyPopupData.contact_phone
    payload = { ...me, ...payload }
    if (noOfRetries >= 2 && !capthcaValue) {
      notification('error', 'Please complete the reCaptcha')
      return
    }
    setVerifyPopupData({ ...verifyPopupData, otpSubmitting: true })
    const res = await verifyOtp({ payload, resetForm })
    if (res) {
      trackOTP(true)
      setVerifyPopupData({ ...verifyPopupData, otpSubmitting: false, txt_otp: '' })
    } else {
      trackOTP(false)
      setVerifyPopupData({ ...verifyPopupData, otpSubmitting: false, txt_otp: '' })
    }
  }

  const handleCancel = async (values, { resetForm }) => {
    await setVerifyPopupData({ ...verifyPopupData, contact_phone: '', submitting: false, otpSubmitting: false, otpPage: false })
    closeModal(true, resetForm)
  }

  const closeModal = (resetFlag, resetForm) => {
    onClose()
    setVerifyPopupData({ ...verifyPopupData, otpSubmitting: false })
    if (resetFlag) reset(resetForm)
  }
  const reset = (resetForm) => {
    setVerifyPopupData({ ...verifyPopupData, txt_phone: '' })

    resetForm({ txt_phone: '' })
  }
  const handleVerifyPopupCancel = () => {
    setVerifyPopupData({ ...verifyPopupData, otpPage: false })
    onClose()
  }
  const handleReset = () => {
    setSubmitting(false)
    onClose()
  }

  return (
    <Popup
      id="modalPhoneVerify7"
      open={open}
      title=""
      onCancel={() => handleVerifyPopupCancel()}
      type="small"
      modalBackdropClicked={() => handleReset()}
      hideHeader={true}>
      {!verifyPopupData.otpPage ? (
        <React.Fragment>
          <div className="modal-header border-0 position-relative justify-content-center pb-0 pl-10 pr-10">
            <div className="mb-0">
              <div className="text-center mb-5 mt-5">
                <img
                  alt=""
                  src="https://dufzo4epsnvlh.cloudfront.net/image/icon-new/verify-icon.svg"
                  className="mb-20"
                />
                <h2 className="mb-10 fz-24">{`${title || 'Verify your account'}`}</h2>
              </div>
              {securityText &&
          <p className="text-center">
            {securityText}
          </p>
              }
              <p className="text-center mb-0">
            Enter your mobile number below and we will send you a confirmation code in the next few seconds.
              </p>
            </div>
          </div>

          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize
            initialValues={{
              txt_phone: verifyPopupData.contact_phone ? phoneNumberAutomasking(verifyPopupData.contact_phone.replace('+1', '')) : ''
            }}
            validationSchema={Yup.object().shape({
              txt_phone: Yup.string().required('Required')
            })}
            onReset={handleCancel}
            onSubmit={(values) => {
              handleVerifyPopupSubmit(values)
            }}
            render={({ values, setFieldValue, resetForm }) => {
              return (
                <Form id="frm_phoneVerify">
                  <button type="reset" className={'close remove-modal-close'}>
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="label-text">
                          <ErrorMessage name="txt_phone" component="span" className="form-error" />
                        </label>
                        <Field
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Phone Number"
                          name="txt_phone"
                          value={values.txt_phone ? numberMask(values.txt_phone.replace('+1', '')) : ''}
                          onChange={(event) => {
                            setFieldValue('txt_phone', numberMask(event.target.value))
                          }}
                          id="frm_phoneVerifyPhone_txtPhone"
                          maxLength={14}
                        />
                      </div>
                    </div>
                  </div>
                  <footer className="d-block text-center mt-20">
                    <button
                      type="submit"
                      disabled={submitting}
                      className={'btn btn-primary btn-lg btn-block d-block border-0'}
                      id="frm_phoneVerifyPhone_btnNext">
                      {' '}
                  NEXT
                      {submitting && (
                        <i className="btn-icon top-minus-2">
                          <img
                            className="spinner"
                            src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg"
                            alt=""
                          />
                        </i>
                      )}
                    </button>
                    <button
                      type="reset"
                      style={{ width: 'fit-content' }}
                      id="frm_phoneVerifyPhone_btnSkip"
                      className={'btn btn-lg mx-auto btn-block d-block btn-link text-decoration-none'}>
                      {' '}
                  SKIP
                    </button>
                  </footer>
                </Form>
              )
            }}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="modal-header d-block border-0 position-relative justify-content-center pb-15 pl-0 pr-0">
            <div className="mb-0">
              <div className="text-center mb-5">
                <img
                  alt=""
                  className="mb-20"
                  src="https://dufzo4epsnvlh.cloudfront.net/image/icon-new/verify-icon.svg"
                />
                <h2 className="mb-10 fz-24">Enter verification code</h2>
              </div>
              <p className="text-center ">
            A 4 digit code has been sent to
                <span className="d-block">{verifyPopupData.contact_phone}</span>
              </p>
            </div>
          </div>
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize
            initialValues={{
              txt_otp: ''
            }}
            validationSchema={Yup.object().shape({
              txt_otp: Yup.string().required('Required')
            })}
            onReset={handleCancel}
            onSubmit={handleOtpSubmit}
            render={({ values, setFieldValue, resetForm }) => {
              return (
                <Form>
                  <button type="reset" className={'close remove-modal-close'}>
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="d-block text-center">
                      Enter the 4-digit verification code
                          <ErrorMessage name="txt_otp" component="span" className="form-error" />
                        </label>
                        <Field
                          className="form-control form-control-lg"
                          type="text"
                          name="txt_otp"
                          maxLength={4}
                          placeholder="Enter verification code"
                        />
                        <div className="text-center mt-10">
                          <span>We just sent a text message to</span>
                          <span className="d-block">{verifyPopupData.contact_phone}</span>
                          {noOfRetries <= 3 && <div className={`${Style['receive-code']}`}>
                          Didn&apos;t receive the code{timer > 0 ? `? resend code in ${timer} seconds.` : ' ?'}
                            <Box>
                              <Button
                                disabled={isResendCodeDisabled && timer !== 0}
                                variant="text"
                                onClick={handleResend}
                                className={`border-0 ${Style['undo-btn']}`}>
                                <i className={`${Style['icons-undo']}`}>
                                  <Icons name="undo" />
                                </i>
                            Resend Code
                              </Button>
                              <Button
                                disabled={isResendCodeDisabled && timer !== 0}
                                variant="text"
                                onClick={handleOtpOnCall}
                                className={`border-0 ${Style['undo-btn']}`}>
                                <i className={`${Style['icons-undo']}`}>
                                  <Icons name="undo" />
                                </i>
                            OTP on call
                              </Button>
                            </Box>
                          </div>}
                          {showReCaptcha
                            ? <div className="col-12 pt-20">
                              <ReCAPTCHA
                                sitekey={config.googleReCAPTCHAKey}
                                onChange={(val) => onChangeRecaptche(val)}
                              />
                            </div>
                            : null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <footer className="d-block mt-10">
                    <button
                      type="submit"
                      disabled={verifyPopupData.otpSubmitting || (showReCaptcha && !capthcaValue)}
                      className={'btn btn-primary btn-lg btn-block border-0 d-block'}
                      id="get-paid">
                      {' '}
                  NEXT
                      {verifyPopupData.otpSubmitting && (
                        <i className="btn-icon top-minus-2">
                          <img
                            className="spinner"
                            src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg"
                            alt=""
                          />
                        </i>
                      )}
                    </button>
                    <button type="reset" style={{ width: 'fit-content' }} className={'btn btn-lg mx-auto btn-block d-block btn-link text-decoration-none'}>
                      {' '}
                  SKIP
                    </button>
                  </footer>
                </Form>
              )
            }}
          />
        </React.Fragment>
      )}
    </Popup>
  )
}
