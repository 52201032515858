import * as React from 'react'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// types
import { ContactData, ContactGroupData, SelectedContact } from './types'
import { ActionMeta, OptionTypeBase, OptionsType } from 'react-select/src/types.js'

// components
import Popup from '../basic-modal'
import notification from '../../notification'
import Icon from '../../icons'
import { ClosePopupButton } from '../components/closePopupButton'
import { PreviewMessage } from './previewMessage'
import { ContactCreatableSelect } from './contactCreatableSelect'
import { CopyToClipboardButton } from './copyToClipboardButton'
import { LoadingSubmitButton, CancelButton } from '../components/buttons'

// util
import { isEnabled, validateEmail, validateMobile } from '@helpers/utility'
import * as _ from 'lodash'
import { parseContactSearchResults } from './helpers'
import { useReviewShareableLink } from '@app/helpers/useReviewShareableLink'

interface ReviewRequestPopupProps {
  open: boolean;
  onClose: () => void;
  onSubmitSuccess?: () => void;
  defaultContact?: ContactData;
  flow?: string;
}

export const ReviewRequestPopup = ({ open, onClose, defaultContact, onSubmitSuccess, flow }: ReviewRequestPopupProps) => {
  const [selectedContacts, setSelectedContacts] = useState<SelectedContact[]>([])
  const [required, setRequired] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [copied, setCopied] = useState<boolean>(false)
  const { shareableURL } = useReviewShareableLink()

  // rematch
  // we don't currently have types for any of the API responses.
  const me: any = useSelector<any>((state) => state.auth.me)
  const products: any = useSelector<any>((state) => state.auth.products)
  const accountSettings: any = useSelector<any>((state) => state.accountsettings.accountsettings)
  const dispatch = useDispatch()
  // get anything that's missing from the global store
  useEffect(() => {
    if (!me) dispatch.auth.getMe()
    if (!accountSettings) dispatch.accountsettings.fetchAccountSettings()
  }, [])

  useEffect(() => {
    // input the default contact if one is given as props
    if (defaultContact && defaultContact.value && defaultContact.valueData) {
      setSelectedContacts([
        {
          label: defaultContact.valueData,
          value: defaultContact.value
        }
      ])
    }
    // use open as dependency so that the user can safely remove the defaultContact
  }, [open])


  const handleReset = () => {
    setRequired(false)
    setSelectedContacts([])
    setSubmitting(false)
    onClose()
  }

  const handleSubmit = async () => {
    if (selectedContacts.length !== 0) {
      setSubmitting(true)
      const recipientContacts = _.map(selectedContacts, (contact) => (contact.label ? contact.label : contact.value))
      const payload = {
        review_type: 'first_party',
        send_now: true,
        sent_to_all: false,
        recipient_groups: [],
        recipient_contacts: recipientContacts,
        include_unsubscribe: true,
        created_by_flow: flow
      }

      await dispatch.review.sendReview(payload)
      if (onSubmitSuccess) onSubmitSuccess()

      handleReset()
      setSubmitting(false)
    }
    setRequired(true)
  }

  // for creatable select
  const handleSelectChange = (
    newValue: OptionsType<ContactData | ContactGroupData>,
    actionMeta: ActionMeta<OptionTypeBase>
  ) => {
    // remove a selected contact
    if (actionMeta.action === 'remove-value') {
      const filterContacts = selectedContacts.filter((item, index) => index !== actionMeta.removedValue.index)
      setSelectedContacts(filterContacts)
    } else {
      // add a selected contact
      const newContacts = [...selectedContacts]
      if (newValue && newValue.length !== 0) {
        setRequired(false)

        for (const contact of newValue) {
          // add all contacts in a group into the selected contacts arr
          if (contact.type === 'group') {
            for (const groupMember of contact.value) {
              if (typeof groupMember === 'object') { newContacts.push({ label: groupMember.valueData, value: groupMember.value }) }
            }
          }
          // add all single contact into selected contacts arr
          if (contact.type === 'contact' && typeof contact.value === 'string') {
            newContacts.push({ label: contact.valueData, value: contact.value })
          }
          // add newly created contact into selected contacts arr
          if ('__isNew__' in contact && contact.__isNew__) {
            if (contact.value && !validateMobile(contact.value) && !validateEmail(contact.value)) {
              const message = me.account_settings.twilio.length
                ? 'Please enter a valid email or mobile'
                : 'Please enter a valid email'
              notification('error', message)
            } else if (typeof contact.value === 'string') {
              newContacts.push({ label: contact.valueData, value: contact.value })
            }
          }
        }
      }
      // remove duplicates
      const uniqNewContacts = _.uniqBy(newContacts, 'label')
      setSelectedContacts(uniqNewContacts)
    }
  }

  const autoSuggestContacts = async (input: string) => {
    // helper function
    const trimContactGroup = (contactGroup?: ContactGroupData): ContactGroupData | void => {
      if (contactGroup && contactGroup.type === 'group' && contactGroup.value.length > 0) {
        contactGroup.value = _.uniq(contactGroup.value)
        return contactGroup
      }
    }

    // make a query
    const contactSearchQuery = {
      nopaging: true,
      status: 'active',
      excludeNullGroup: true,
      search: input || ''
    }

    // this function will update `searchRes` in the global store but we need the value NOW
    const searchRes = await dispatch.contact.autosuggestContacts(contactSearchQuery)

    const { contactList, contactGroupList } = parseContactSearchResults(searchRes)

    if (products) {
      const groupOptions = _.map(contactGroupList, (contactGroup) => trimContactGroup(contactGroup))
      var contactOptions = []
      if (isEnabled('contact-manager', products)) {
        contactOptions = _.isEqual(contactList, []) ? [] : contactList
      } else {
        contactOptions = _.isEqual(contactList, []) ? [] : []
      }
      return [
        {
          label: 'Group',
          options: groupOptions
        },
        {
          label: 'Contacts',
          options: contactOptions
        }
      ]
    }
    return []
  }

  return (
    <Popup
      id="reviews_modalSendReviewRequest"
      open={open}
      title="New Review Request"
      type="small"
      overrideForm={true}
      modalBackdropClicked={handleReset}
    >
      <div className="sent-review-request-form-wrapper">
        <ClosePopupButton id="closeIcon" onClick={() => handleReset()} />

        <div className="form-group review-send custom-form-group-dropdown">
          <div className="auto-search-wrapper">
            <label className="label-text">
              {required && (
                <span id="frmSendReviewRequest_errorMessage" className="form-error">
                  Required
                </span>
              )}
            </label>
            <ContactCreatableSelect
              id="frmReviewRequest_recieverContact"
              placeholder={
                me && me.account_settings && me.account_settings.twilio.length ? 'Search by Name/Email/Mobile Number' : 'Search by Name/Email'
              }
              onChange={handleSelectChange}
              loadOptions={autoSuggestContacts}
              value={
                selectedContacts
                  ? selectedContacts.map((item, index) => {
                    return { label: item.label, value: item.value, index: index }
                  })
                  : []
              }
              isValidNewOption={(inputValue, value, options) => {
                return (
                  options.length > 0 &&
                  options[0] &&
                  options[0].options.length === 0 &&
                  options[1] &&
                  options[1].options.length === 0
                )
              }}
            />
          </div>

          <PreviewMessage message={(accountSettings && accountSettings.comment) || ''} />

          <div>
            {me && (
              <CopyToClipboardButton
                textToCopy={shareableURL}
                onCopy={() => setCopied(true)}
                isCopied={copied}
                hasCopiedMsg="Copied!"
                hasNotCopiedMsg="Copy link"
              >
                <i className="btn-icon" style={{ marginRight: '10px' }}>
                  <Icon name="linkIcon" />
                </i>
                <p className='shareable-link-text' style={{ fontFamily: 'Open Sans Demi Bold' }} >{shareableURL}</p>
              </CopyToClipboardButton>
            )}
          </div>
        </div>

        <footer className="d-block d-sm-flex">
          <LoadingSubmitButton onClick={() => handleSubmit()} id="frmSendReviewRequest_Send" type="button" isSubmitting={submitting}>
            SEND REVIEW REQUEST
          </LoadingSubmitButton>
          <CancelButton type="reset" id="reviews_btnSendReviewRequestCloses" onClick={() => handleReset()}>
            CANCEL
          </CancelButton>
        </footer>
      </div>
    </Popup>
  )
}
