import React, { Component, Fragment } from 'react'
import { compose } from 'recompose'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { CardNumberElement as StripeCardNumberElement } from '@stripe/react-stripe-js'
import Popup from '@components/popups/basic-modal'
import { nFormatter, setpaymentAttempt, getPaymentCount } from '@helpers/utility'
import config from '@config/index'
import style from '../index.module.scss'
import Tooltip from '@components/tooltip'
import notification from '@components/notification'
import ReCAPTCHA from 'react-google-recaptcha'
import { CardCvcElement, CardExpiryElement, CardNumberElement, ElementState, withElementsConsumer } from './stripe'
import { withTranslation } from 'react-i18next'

let isFormChanged = false

class ManualTransactionStripe extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      timeSlots: '',
      cardToken: '',
      serviceSelected: false,
      dateSelected: false,
      me: '',
      products: [],
      loadedServices: false,
      isTokengenerated: false,
      showReCaptcha: false,
      capthcaValue: '',
      disableAmount: false,
      txtAmount: '',
      invoiceData: null,
      clicked: false
    }
  }

  componentDidMount() {
    if (getPaymentCount() >= 10) {
      this.setState({ showReCaptcha: true })
    }
    if (this.props.invoiceData) {
      if (this.props.invoiceData.amount) {
        this.setState({ txtAmount: this.props.invoiceData.amount, disableAmount: true })
      }
      this.setState({ invoiceData: this.props.invoiceData })
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.me !== state.me || props.products !== state.products) {
      return { me: props.me, products: props.products }
    } else {
      return null
    }
  }

  showPopup() {
    this.setState({ open: true })
  }

  closeModal(reset, resetForm) {
    this.props.close(isFormChanged)
    isFormChanged = false
  }

  reset(resetForm) {
    resetForm({
      txtCardHolder: '',
      txtAmount: '',
      totalamount: '',
      min_amount: '',
      txtPostal: '',
      card_number: Yup.object().cardNumberElement(),
      card_expiry: Yup.object().cardExpiryElement(),
      card_cvc: Yup.object().cardCvcElement()
    })
  }

  async handleSubmit(values, { resetForm }) {
    this.setState({ clicked: true })
    const { stripe, stripeElements: elements } = this.props
    if (this.state.showReCaptcha) {
      if (!this.state.capthcaValue) {
        notification('error', this.props.t('payments.please-complete-the-recaptcha'))
        return
      }
    }
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardNumberElement = elements.getElement(StripeCardNumberElement)

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        name: values.txtCardHolder
      }
    })
    if (error) {
      notification('error', this.props.t('payments.error-collecting-payment'))
      return
    }
    this.setState({ cardError: false })
    const payload = {}
    payload.card_token = this.state.cardToken

    if (!values['selService']) {
      payload.source = 'direct'
    }

    payload.card_holder = values.txtCardHolder
    payload.amount = values.txtAmount
    payload.expiry = values.txtExpiry
    payload.postal = values.txtPostal
    payload.isStripe = true

    payload.stripeObj = stripe
    payload.stripePaymentMethod = paymentMethod
    if (this.state.invoiceData) {
      payload.invoice_number = this.state.invoiceData.invoice_number
      payload.source = this.state.invoiceData.source
    }
    setpaymentAttempt()
    this.props.onSubmit({ payload, resetForm })
  }

  handleCancel(values, { resetForm }) {
    this.props.close(isFormChanged)
    isFormChanged = false
  }

  onChangeRecaptche(val) {
    this.setState({ capthcaValue: val })
  }

  render() {
    const { submitting } = this.props
    const { showReCaptcha, txtAmount, disableAmount } = this.state
    const t = this.props.t
    return (
      <Fragment>
        <Popup
          open={true}
          title={t('payments.manually-add-a-transaction')}
          hideFooter={true}
          onCancel={() => this.setState({ open: false })}
          type="basic"
          modalBackdropClicked={() => console.log(null)}
          id="modalAddTransaction">
          <Formik
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize={true}
            initialValues={{
              txtCardHolder: '',
              txtAmount: txtAmount,
              totalamount: '',
              min_amount: '',
              txtPostal: '',
              card_number: new ElementState(),
              card_expiry: new ElementState(),
              card_cvc: new ElementState()
            }}
            validationSchema={Yup.object().shape({
              txtCardHolder: Yup.string().required(t('payments.required')),
              txtAmount: Yup.number().required(t('payments.required')),
              txtPostal: Yup.string().required(t('payments.required')),
              card_number: Yup.object().cardNumberElement(),
              card_expiry: Yup.object().cardExpiryElement(),
              card_cvc: Yup.object().cardCvcElement()
            })}
            onReset={this.handleCancel.bind(this)}
            onSubmit={this.handleSubmit.bind(this)}
            render={({ values, setFieldValue }) => {
              return (
                <Form
                  id="frmAddTransaction"
                  name="frmAddTransaction"
                  className={`pt-20 ${style['add-transaction-form']}`}>
                  <button
                    name="btnCloseModal"
                    type="reset"
                    className="close custom-modal-close-button remove-modal-close">
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="row">
                    <div className="col-12 col-sm-12">
                      <div className="form-group">
                        <label className="label-text ">
                          {t('payments.amount-1')} <i className="star">*</i>
                        </label>
                        <ErrorMessage
                          id="frmAddTrans_amountError"
                          name="txtAmount"
                          component="span"
                          className="form-error"
                        />
                        <Field
                          data-test="transaction-form-amount-field"
                          id="frmAddTrans_txtAmount"
                          className="form-control form-control-lg mb-20"
                          type="text"
                          placeholder={t('payments.enter-amount-0')}
                          name="txtAmount"
                          value={values.txtAmount}
                          maxLength={config.amountFieldLength}
                          disabled={disableAmount}
                          onChange={(e) => {
                            const { value } = e.target
                            const regex = /^(0*[0-9][0-9]*(\.[0-9]{0,2})?|0*\.[0-9]{0,2})$/
                            if (regex.test(value.toString()) || value.length === 0) {
                              setFieldValue('txtAmount', value)
                              isFormChanged = true
                            }
                          }}
                        />
                        <div className={`${this.state.serviceSelected ? 'd-block d-md-flex' : 'd-none'}`}>
                          {values.totalamount ? (
                            <div className="d-flex flex-grow-1 mb-15 mb-md-0 align-items-center">
                              <h6>TOTAL:</h6>
                              <strong className={`text-primary ml-5 ${style['highlight']}`}>
                                ${nFormatter(values.totalamount)}
                              </strong>
                            </div>
                          ) : null}
                          {values.min_amount ? (
                            <div className="d-flex flex-grow-1 align-items-center">
                              <h6>PARTIAL:</h6>
                              <strong className={`text-primary ml-5 ${style['highlight']}`}>
                                ${nFormatter(values.min_amount)}
                              </strong>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12">
                      <div className="form-group">
                        <label className="label-text ">
                          {t('payments.cardholder-name')} <i className="star">*</i>{' '}
                          <Tooltip
                            position="top"
                            message={t(
                              'payments.please-enter-the-full-name-displayed-on-the-payment-card-being-processed'
                            )}
                          />
                        </label>
                        <ErrorMessage
                          id="frmAddTrans_amountError"
                          name="txtCardHolder"
                          component="span"
                          className="form-error"
                        />

                        <Field
                          data-test="transaction-cardholder-name-field"
                          id="frmAddTrans_txtCardHolder"
                          className="form-control form-control-lg mb-20"
                          type="text"
                          placeholder={t('payments.enter-cardholder-name')}
                          name="txtCardHolder"
                          value={values.txtCardHolder}
                          onChange={(event) => {
                            setFieldValue('txtCardHolder', event.target.value)
                            isFormChanged = true
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12">
                      <div className="form-group">
                        <label className="label-text ">
                          {t('payments.card-number')} <i className="star">*</i>
                        </label>
                        <ErrorMessage name="card_number" component="span" className="form-error" />
                        <div id="payment" className="card-iframe-group position-relative">
                          <Field
                            name="card_number"
                            className="form-control pt-10 form-control-lg mb-20"
                            component={CardNumberElement}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label className="label-text ">
                          {' '}
                          EXP
                          <i className="star">*</i>
                          <ErrorMessage name="card_expiry" component="span" className="form-error" />
                        </label>
                        <Field
                          name="card_expiry"
                          className="form-control pt-10 form-control-lg"
                          component={CardExpiryElement}
                        />
                        {/* <CardExpiryElement name="card_expiry" /> */}
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label className="label-text ">
                          CVV <i className="star">*</i>
                          <ErrorMessage name="card_cvc" component="span" className="form-error" />
                        </label>
                        <Field
                          name="card_cvc"
                          className="form-control pt-10 form-control-lg"
                          component={CardCvcElement}
                        />
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label className="label-text ">
                          ZIP <i className="star">*</i>
                          <ErrorMessage
                            id="frmAddTrans_postalError"
                            name="txtPostal"
                            component="span"
                            className="form-error"
                          />
                        </label>
                        <Field
                          data-test="transaction-card-zip-field"
                          placeholder={t('payments.zip')}
                          id="frmAddTrans_txtZip"
                          className="form-control form-control-lg"
                          type="text"
                          name="txtPostal"
                          onChange={(event) => {
                            setFieldValue('txtPostal', event.target.value)
                            isFormChanged = true
                          }}
                        />
                      </div>
                    </div>
                    {showReCaptcha ? (
                      <div className="col-12">
                        <ReCAPTCHA
                          sitekey={config.googleReCAPTCHAKey}
                          onChange={(val) => this.onChangeRecaptche(val)}
                        />
                      </div>
                    ) : null}
                  </div>
                  <footer className="d-block d-sm-flex mt-10">
                    <button
                      data-test="transaction-form-submit-collect-payment-btn"
                      id="btnCollectPayment"
                      name="btnCollectPayment"
                      disabled={submitting || this.state.clicked}
                      type="submit"
                      className="btn btn-primary btn-xl border-0 flex-grow-1 add-btn d-block d-sm-inline-block mb-20 mb-sm-0 mr-20">
                      {t('payments.collect-payment-0')}
                      {submitting ? (
                        <i className="btn-icon top-minus-2">
                          <img
                            className="spinner"
                            src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg"
                            alt=""
                          />
                        </i>
                      ) : null}
                    </button>
                    <button
                      id="btnAddTransactionCancel"
                      name="btnAddTransactionCancel"
                      className={'btn btn-xl m-0 cancel-btn custom-btn-width'}
                      type="reset">
                      {t('payments.cancel-0')}
                    </button>
                  </footer>
                </Form>
              )
            }}
          />
        </Popup>
      </Fragment>
    )
  }
}

export default withTranslation(null, { withRef: true })(compose(withElementsConsumer)(ManualTransactionStripe))
