import * as React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { ActionMeta, OptionTypeBase, OptionsType, GroupTypeBase } from 'react-select/src/types.js';
import { components, FormatOptionLabelMeta, ValueContainerProps } from 'react-select';
import * as _ from 'lodash';

interface ContactCreatableSelectProps {
  id: string;
  placeholder: string;
  onChange: (value: OptionsType<any>, action: ActionMeta<OptionTypeBase>) => void;
  loadOptions: (inputValue: string) => Promise<readonly (OptionTypeBase | GroupTypeBase<OptionTypeBase>)[]>;
  value: OptionTypeBase | readonly OptionTypeBase[] | null;
  isValidNewOption: (
    inputValue: string,
    value: OptionsType<OptionTypeBase>,
    options: readonly (OptionTypeBase | GroupTypeBase<OptionTypeBase>)[]
  ) => boolean;
}
interface ContactCreatableSelectState {
  focus: boolean;
}
// It seems like the `react-select` library only works with class components.
export class ContactCreatableSelect extends React.Component<ContactCreatableSelectProps, ContactCreatableSelectState> {
  state = {
    focus: false,
  };

  throttleLoadOptions = _.throttle(this.props.loadOptions, 600);

  // format creatable select
  customOptions = (option: OptionTypeBase, labelMeta: FormatOptionLabelMeta<OptionTypeBase, true>) => {
    if (labelMeta.context === 'value') {
      return <div key={option.index}>{option.value}</div>;
    }
    if (labelMeta.context === 'menu') {
      return (
        <>
          <div>{option.label}</div>
          <div>{option.subLabel || ''}</div>
        </>
      );
    }
  };

  ValueContainer = ({
    children,
    ...props
  }: ValueContainerProps<OptionTypeBase, true, GroupTypeBase<OptionTypeBase>>) => {
    const maxToShow = 2;
    const displayLength = props.getValue().length - maxToShow;
    return (
      <components.ValueContainer {...props}>
        {children}
        <div className="badge-collapse">{`${
          !this.state.focus && displayLength >= 1 ? `${displayLength} more` : ''
        }`}</div>
      </components.ValueContainer>
    );
  };
  render() {
    return (
      <AsyncCreatableSelect
        id={this.props.id}
        isClearable={false}
        isMulti
        className={`dropdown-select ${this.state.focus ? 'is-focus' : 'is-not-focus'}`}
        classNamePrefix="dropdownSelect"
        openMenuOnClick={false}
        placeholder={this.props.placeholder}
        onChange={this.props.onChange}
        loadOptions={this.throttleLoadOptions}
        formatOptionLabel={this.customOptions}
        value={this.props.value}
        onFocus={(): void => this.setState({ focus: true })}
        onBlur={(): void => this.setState({ focus: false })}
        components={{ ValueContainer: this.ValueContainer }}
        formatCreateLabel={(userInput) => `${userInput}(new)`}
        isValidNewOption={this.props.isValidNewOption}
      />
    );
  }
}
