import React from "react"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import LocationFilter from "@components/locationFilter/universal"
import Icons from "@components/icons"
import Creatable from "react-select/creatable"


const validationSchema = Yup.object().shape({
    firstname: Yup.string()
        .required("Required"),
    lastname: Yup.string(),
    email: Yup.string()
        .required("Required")
        .email("Required"),
    locations: Yup.array().required("Required"),
    categories: Yup.array()
})

class ManagerForm extends React.Component {

    state = {

    }

    resetForm() {
        if (this.resetForm) this.resetForm()
    }

    render() {
        let { is_edit } = this.props
        return (
            <Formik
                enableReinitialize
                initialValues={{
                    firstname: this.props.manager ? this.props.manager.first_name || "" : "",
                    lastname: this.props.manager ? this.props.manager.last_name || "" : "",
                    locations: this.props.manager && this.props.manager.locations && this.props.locations.length ?
                        this.props.manager.locations.map(item => ({ _id: item }))
                        : 
                        this.props.locations && this.props.locations.length ? this.props.locations.map(item => ({ _id: item._id })) : [],
                    categories: this.props.manager && this.props.manager.tags && this.props.manager.tags.length ?
                        this.props.manager.tags.map((item, index) => ({ value: item.tag_id._id, label: item.tag_id.tag_name, index, color_code: item.tag_id.color_code }))
                        : [],
                    email: this.props.manager ? this.props.manager.user_email || "" : ""
                }}
                validationSchema={validationSchema}
                onSubmit={this.props.onSubmit}
            >
                {({ errors, touched, setFieldValue, values, resetForm }) => {
                    this.resetForm = resetForm
                    return (
                        <Form className="add-managers-form">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="firstname" className="label-text text-uppercase">First Name</label>
                                        <i className="star">&nbsp;*</i>
                                        {errors.firstname && touched.firstname ? (
                                            <span className="form-error">{errors.firstname}</span>
                                        ) : null}
                                        <Field id={`firstname${is_edit ? "edit" : ""}`} name="firstname" placeholder="Enter first name" className="form-control form-control-lg" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="lastname" className="label-text text-uppercase">Last Name</label>
                                        <Field id={`lastname${is_edit ? "edit" : ""}`} name="lastname" placeholder="Enter last name" className="form-control form-control-lg" />
                                        {/* {errors.lastname && touched.lastname ? (
                                            <span className="form-error">{errors.lastname}</span>
                                        ) : null} */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="email" className="label-text text-uppercase">Email</label>
                                        <i className="star">&nbsp;*</i>
                                        {errors.email && touched.email ? (
                                            <span className="form-error">{errors.email}</span>
                                        ) : null}
                                        <Field id={`email${is_edit ? "edit" : ""}`} name="email" placeholder="Enter email address" className="form-control form-control-lg" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="locations" className="label-text text-uppercase">Locations</label>
                                        <i className="star">&nbsp;*</i>
                                        {errors.locations && touched.locations ? (
                                            <span className="form-error">{errors.locations}</span>
                                        ) : null}
                                        <Field name="locations">
                                            {() => (
                                                <LocationFilter
                                                    page={"managers"}
                                                    locations={this.props.locations}
                                                    selectLocations={(locations) => {
                                                        setFieldValue("locations", locations, true)
                                                    }}
                                                    selectedLocations={values.locations}
                                                    showInitalAllLocation={true}
                                                    initialValue={this.props.is_edit ? null : []}
                                                    // showInitalNoLocation={true}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="form-group custom-react-search-select-wrapper custom-group-select mb-30">
                                        <label htmlFor="categories" className="label-text text-uppercase">Categories</label>
                                        {/* <i className="star">&nbsp;*</i>
                                        {errors.categories && touched.categories ? (
                                            <span className="form-error">{errors.categories}</span>
                                        ) : null} */}
                                        <Field id={`categories${is_edit ? "edit" : ""}`} name="categories">
                                            {({ field }) => (
                                                <div>
                                                    <Creatable
                                                        className="search-select h-100"
                                                        classNamePrefix="globalSelect"
                                                        placeholder=""
                                                        isMulti={true}
                                                        options={
                                                            this.props.categories && this.props.categories.length ?
                                                                this.props.categories.map((item, index) => (
                                                                    { value: item._id, label: item.tag_name, index, color_code: item.color_code }
                                                                ))
                                                                : []
                                                        }
                                                        onChange={(values, action) => {
                                                            setFieldValue("categories", values)
                                                        }}
                                                        // menuIsOpen={true || this.state.menuIsOpen}
                                                        onFocus={() => this.setState({ menuIsOpen: true })}
                                                        onBlur={() => this.setState({ menuIsOpen: false })}
                                                        isDisabled={!values.locations.length || !values.locations.length}
                                                        value={values.categories || []}
                                                        // value={this.props.manager && this.props.manager.tags && this.props.manager.tags.length ?
                                                        //     this.props.manager.tags.map((item, index) => ({ value: item.tag_id._id, label: item.tag_id.tag_name, index, color_code: item.tag_id.color_code }))
                                                        //     : []}
                                                        formatOptionLabel={({ label, color_code }) => (
                                                            <div className="b-colored py-3 text-secondary" style={{ background: color_code || "unset" }}>
                                                                {label}
                                                            </div>
                                                        )}
                                                        createOptionPosition="first"
                                                        formatCreateLabel={input => `Create tag for '${input || ""}'`}
                                                        isValidNewOption={(input, selectValue, selectOption) => {
                                                            if (input) {
                                                                if (selectOption.find(item => item.label && item.label.includes(input))) {
                                                                    return false
                                                                }
                                                                return true
                                                            }
                                                            return false
                                                        }}
                                                        filterOption={(data, search) => {
                                                            if (data.label && data.label.length && data.label.includes(search)) {
                                                                return true
                                                            }
                                                            else return false
                                                        }}
                                                        onCreateOption={value => {
                                                            let newOption = {
                                                                label: value,
                                                                index: this.props.categories.length,
                                                                color_code: this.props.tagColors && this.props.tagColors.length ?
                                                                    this.props.tagColors[Math.floor(Math.random() * this.props.tagColors.length)]
                                                                    : null
                                                            }
                                                            // this.setState(prevState => {
                                                            //     return {
                                                            //         categories: [
                                                            //             ...prevState.categories,
                                                            //             newOption
                                                            //         ]
                                                            //     }
                                                            // }, () => {
                                                            setFieldValue("categories", [...(values.categories || []), newOption])
                                                            // })
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <footer className="d-block d-sm-flex">
                                <button type="submit"
                                    name="btnSaveEditColumnModal"
                                    id={`manage_btnSave${is_edit ? "edit" : ""}`}
                                    className="btn btn-primary border-0 btn-xl mb-10 mb-sm-0 mr-20 btn-block"
                                    disabled={this.props.submiting} >
                                    {this.props.button_text || "SAVE"}
                                    {this.props.submiting ? (<i className={"btn-icon top-minus-2 ml-10 btn-loader"}><Icons name="spinner" /></i>) : null}
                                </button>
                                <button
                                    name="btnCancelEditColumnModal"
                                    id={`manage_btnCancel${is_edit ? "edit" : ""}`}
                                    type="reset"
                                    className="btn btn-xl m-0 btn-outline custom-btn-width"
                                    onClick={() => {
                                        resetForm()
                                        this.props.closeModal()
                                    }}
                                >
                                    {"CANCEL"}
                                </button>
                            </footer>
                        </Form>
                    )
                }}
            </Formik>
        )
    }
}

export default ManagerForm