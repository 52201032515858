import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Popup from '@components/popups/basic-modal'
import Style from '../index.module.scss'
import Select from 'react-select'
import ContentLoader from 'react-content-loader'
import Dropzone from 'react-dropzone-uploader'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import config from '@config/index'
import { Line } from 'rc-progress'
import notification from '@components/notification'
import ImageCropModal from '@components/image-edit-modal'
import Icon from '@components/icons'
import { checkPermissionWOproduct, getDocIcon } from '@helpers/utility'
import { segmentEventTrack } from '@helpers/segment'
import { mediaApi } from '@helpers/axios'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import DropzoneComponent from 'react-dropzone'
import WithGoogleLoginHOC from '@app/helpers/WithGoogleLoginHOC'
import { useTranslation, withTranslation } from 'react-i18next'

const supportedImageTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/heic']

const docExtens = [
  '.pdf',
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.xls',
  '.xlsx',
  '.odp',
  '.odt',
  '.epub',
  '.PDF',
  '.DOC',
  '.DOCX',
  '.PPT',
  '.PPTX',
  '.XLS',
  '.XLSX',
  '.ODP',
  '.ODT',
  '.EPUB',
  '.csv',
  '.CSV',
  '.txt',
  '.ods'
]

const mapDispatchToProps = ({ auth, common, medialibrary }) => {
  return {
    ...auth,
    ...common,
    ...medialibrary
  }
}

const mapStateToProps = ({ auth, common, medialibrary }) => {
  return {
    ...auth,
    ...common,
    ...medialibrary
  }
}
const DropAndDragLayoutImg = ({ accept, onFiles, files, getFilesFromEvent }) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <div className={'d-flex justify-content-between align-items-center ' + Style['drop-files']}>
        <div id="MediaLibraryDragAndDrop" className={'pr-10 ' + Style['left-info']}>
          {t('settings.drop-files-here-or-upload-manually-by-clicking')}{' '}
          <strong>
            <span>{t('settings.upload-image')}</span>
          </strong>{' '}
          button
        </div>
        <label id="MediaLibraryUpload" className={'btn btn-primary border-0 mb-0 ' + Style['media-upload-btn']}>
          <i className={`mr-md-5 ${Style['media-upload-img']}`}>
            <Icon name="upload" />{' '}
          </i>
          {t('settings.upload-image-0')}
          <input
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            multiple
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) =>
                chosenFiles.filter((file) => !supportedImageTypes.includes(file.type)).length > 0
                  ? notification('error', this.errorImageType)
                  : onFiles(chosenFiles)
              )
            }}
            onClick={(event) => {
              event.target.value = null
            }}
          />
        </label>
      </div>
    </React.Fragment>
  )
}

const getFilesFromEvent = (e) => {
  return new Promise((resolve) => {
    getDroppedOrSelectedFiles(e).then((chosenFiles) => {
      resolve(chosenFiles.map((f) => f.fileObject))
    })
  })
}

const DropAndDragLayoutVideo = ({ accept, onFiles, files, getFilesFromEvent }) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <div className={'d-flex justify-content-between align-items-center ' + Style['drop-files']}>
        <div className={Style['left-info']}>
          {t('settings.drop-files-here-or-upload-manually-by-clicking')}{' '}
          <strong>
            <span>{t('settings.upload-video')}</span>
          </strong>{' '}
          button
          <br />
          <span>{t('settings.maximum-limit-is-200-mb')}</span>
        </div>
        <label className={'btn btn-primary mb-0 ' + Style['media-upload-btn']}>
          <i className={Style['media-upload-img']}>
            <Icon name="upload" />{' '}
          </i>
          {t('settings.upload-video-0')}
          <input
            type="file"
            accept="video/*"
            style={{ display: 'none' }}
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles)
              })
            }}
          />
        </label>
      </div>
    </React.Fragment>
  )
}
const DropAndDragLayoutDoc = ({ accept, onFiles, files, getFilesFromEvent }) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <div className={'d-flex justify-content-between align-items-center ' + Style['drop-files']}>
        <div className={Style['left-info']}>
          {t('settings.drop-files-here-or-upload-manually-by-clicking')}{' '}
          <strong>
            <span>{t('settings.upload-document')}</span>
          </strong>{' '}
          button
        </div>
        <label className={'btn btn-primary mb-0 ' + Style['media-upload-btn']}>
          <i className={Style['media-upload-img']}>
            <Icon name="upload" />{' '}
          </i>
          {t('settings.upload-document-0')}
          <input
            type="file"
            accept=".pdf, .doc, .docx, .ppt, .pptx, .ppsx, .xls, .xlsx, .odp, .odt,.ods,.epub,.txt"
            style={{ display: 'none' }}
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles)
              })
            }}
          />
        </label>
      </div>
    </React.Fragment>
  )
}

class MediaLibraryPopup extends Component {
  t = this.props.t
  errorImageType = this.t('settings.file-type-not-supported-please-only-upload-jpg-png-gif-or-heic-files')
  sortFilter = [
    {
      label: this.t('settings.sort-by-date'),
      value: 'createdAt'
    },
    {
      label: this.t('settings.sort-by-name'),
      value: 'displayName'
    }
  ]
  constructor(props) {
    super(props)
    this.state = {
      site_title: 'GoSite',
      medias: [],
      socailMedias: [],
      type: '',
      isImage: false,
      isVideo: false,
      isIcon: false,
      isShutterstock: false,
      isDocument: false,
      isStockImage: false,
      isSocial: false,
      limit: config.media_library_limit,
      start: 0,
      search: '',
      sortOrder: false,
      status: 'active',
      open: false,
      selectedFolder: '',
      selectedSocial: 'google',
      sort: { label: 'Sort by Date', value: 'createdAt' },
      hasmore: true,
      selectedMedias: [],
      media: '',
      selectedMediaIds: [],
      scrollStart: false,
      folders: [],
      orientation: 'horizontal',
      busy: false,
      totalShutterImgCount: 0,
      shutterPageCount: 1,
      shutterStock: false,
      submitLoader: false,
      progressBar: []
    }
    this.list = this.list.bind(this)
    this.listFolders = this.listFolders.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.googleResponse !== '' && prevProps.googleResponse.code !== this.props.googleResponse.code) {
      this.responseGoogle('gmbImport', this.props.googleResponse)
    }
    if (this.props.error && prevProps.error !== this.props.error) {
      this.responseGoogle('gmbImport', this.props.error)
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.open !== state.open) {
      if (props && props.type && props.type.includes('image')) {
        state.isStockImage = true
        state.isSocial = !props.fromEnterprise
        state.isImage = true
      }
      if (props && props.type && props.type.includes('video')) {
        state.isVideo = true
      }
      if (props && props.type && props.type.includes('doc')) {
        state.isDocument = true
      }
      if (props && props.type && props.type.includes('icon')) {
        state.isIcon = true
      }
      if (props && props.type && props.type.includes('shutterstock')) {
        state.isShutterstock = true
      }
      if (
        props &&
        props.me &&
        props.me.company_settings &&
        props.me.company_settings.generalSettings &&
        props.me.company_settings.generalSettings.site_title
      ) {
        state.site_title = props.me.company_settings.generalSettings.site_title
      }
      state.type = props.type[0]
      state.selectedMedias = []
      state.selectedMediaIds = []
      state.open = props.open
      return state
    } else {
      return null
    }
  }
  componentWillUnmount() {
    window.removeEventListener(
      'dragover',
      function (e) {
        e.preventDefault()
      },
      false
    )
    window.removeEventListener(
      'drop',
      function (e) {
        e.preventDefault()
      },
      false
    )
  }
  async componentDidMount() {
    window.addEventListener(
      'dragover',
      function (e) {
        e.preventDefault()
      },
      false
    )
    window.addEventListener(
      'drop',
      function (e) {
        e.preventDefault()
      },
      false
    )
    this.list()
    // this.listFolders()
  }
  async closeModal(from = 'button') {
    this.props.onCancel(from)
  }
  async clickedTab(tab) {
    this.setState({
      start: 0,
      limit: config.media_library_limit,
      search: '',
      selectedMediaIds: [],
      selectedMedias: [],
      shutterStock: false,
      shutterStockSearch: ''
    })
    if (tab === 'isImage') {
      await this.setState({ isImage: true, type: 'image' })
      await this.list()
    } else if (tab === 'isVideo') {
      await this.setState({ isVideo: true, type: 'video' })
      await this.list()
    }
    if (tab === 'isIcon') {
      await this.setState({ isIcon: true, type: 'icon' })
      await this.list()
    }
    if (tab === 'isDocument') {
      await this.setState({ isDocument: true, type: 'doc' })
      await this.list()
    }
    if (tab === 'stockImage') {
      await this.setState({ isStockImage: true, type: 'stockImage' })
      await this.listFolders()
    }
    if (tab === 'isSocial') {
      await this.setState({ isSocial: true, type: 'social' })
      await this.list()
    }
    if (tab === 'shutterstock') {
      await this.setState({ isSocial: true, type: 'shutterstock' })
      await this.listShutterstock()
    }
  }
  async listFolders() {
    await this.props.fetchFolders() // this.props.folders
    if (this.props.folders && this.props.folders.docs && this.props.folders.docs.length) {
      this.setState({ folders: this.props.folders.docs })
      const folder = {
        _id: 'unsorted',
        folder: 'Others'
      }
      const fol = [...this.state.folders]
      fol.push(folder)
      this.setState({ folders: fol })
      this.getImagesFromFolder(this.state.folders[0])
    }
  }
  async getImagesFromFolder(folder) {
    await this.setState({ selectedFolder: folder._id, start: 0 })
    await this.list()
  }
  async sortMedia(e) {
    await this.setState({ sort: e, start: 0 })
    await this.list()
  }
  async list() {
    const mediaData = {
      asc: this.state.sortOrder,
      limit: this.state.limit,
      search: this.state.search,
      sort: this.state.sort.value,
      start: this.state.start,
      status: this.state.status,
      type: this.state.type === 'doc' ? 'doc' : this.state.type
    }
    if (this.state.type === 'stockImage') {
      mediaData.stock_image = true
      mediaData.stock_image_status = 'active'
      mediaData.folder_id = this.state.selectedFolder
    }
    if (this.state.type === 'social') {
      mediaData.source = this.state.selectedSocial
    }
    await this.props.fetchMedia(mediaData) // this.props.media
    if (this.state.start === 0 && this.props.media && this.props.media.medias) {
      await this.setState({ media: this.props.media.medias })
    } else {
      if (this.props.media && this.props.media.medias) {
        await this.setState({ media: [...this.state.media, ...this.props.media.medias] })
        this.setState({ scrollStart: false })
      }
    }
  }
  async searchShutterStockOrien(search, type) {
    this.setState({ shutterPageCount: 1, totalShutterImgCount: 0, selectedImages: 0, orientation: type })
    this.searchShutterStock(search)
  }
  async segmentTrackingFn(search, type) {
    let data = {}
    if (type === 'search') {
      data = {
        event: 'stock_search',
        properties: {
          user_id: this.props.me && this.props.me._id,
          role: this.props.me && this.props.me.role_id.role_name,
          search_keyword: search,
          step: 'stock-search'
        }
      }
      if (
        this.props.businessSettings.primaryCategories &&
        this.props.businessSettings.primaryCategories.category_name
      ) {
        data.properties.business_category = this.props.businessSettings.primaryCategories.category_name
      }
    } else if (type === 'select') {
      data = {
        event: 'stock_select',
        properties: {
          user_id: this.props.me && this.props.me._id,
          role: this.props.me && this.props.me.role_id.role_name,
          image_id: search.sst_id,
          image_keywords: search.sst_keywords,
          step: 'stock-select'
        }
      }
    }
    segmentEventTrack(data)
  }
  async searchShutterStock(search, tracking) {
    if (search) {
      if (this.state.busy) {
      } else {
        if (!this.state.searchold || this.state.searchold !== search) {
          await this.setState({ totalShutterImgCount: 0 })
        }
        await this.setState({ searchold: search, busy: true })
        if (this.state.totalShutterImgCount === 0) {
          this.setState({ shutterPageCount: 1, loading: true })
          window.$('#scrollul').scrollTop(0, 0)
        } else {
          this.setState({ scrollStart: true, shutterPageCount: this.state.shutterPageCount + 1 })
          if (this.state.shutterPageCount * this.state.limit >= this.state.totalShutterImgCount) {
            this.setState({ scrollStart: false })
            return
          }
        }
        const data = {
          query: search,
          orientation: this.state.orientation,
          page: this.state.shutterPageCount,
          per_page: this.state.totalShutterImgCount
        }
        await this.props.getShutterStockMedia(data)
        const shutterMedia = []
        this.props.shutterstockMedia &&
          this.props.shutterstockMedia.data &&
          this.props.shutterstockMedia.data.forEach((media) => {
            const data = {
              file_extension: 'image/jpeg',
              type: 'image',
              id: media.id,
              _id: media.id,
              status: 'active',
              stock_image: true,
              isPublic: true,
              displayName: media.description,
              previewUrl: media.assets.huge_thumb.url,
              fileUrl: media.assets.huge_thumb.url
            }
            shutterMedia.push(data)
          })
        if (this.state.shutterPageCount === 1) {
          await this.setState({ media: [] })
          this.setState({ media: shutterMedia })
        } else {
          const obj = Object.assign(this.state.media)
          shutterMedia &&
            shutterMedia.forEach((media) => {
              obj.push(media)
            })
          this.setState({ media: obj })
        }
        if (this.state.totalShutterImgCount === 0) {
          window.$('#scrollul').scrollTop(0, 0)
        }
        this.setState({
          busy: false,
          scrollStart: false,
          loading: false,
          totalShutterImgCount: this.props.shutterstockMedia && this.props.shutterstockMedia.data.total_count,
          shutterStock: true
        })
        if (tracking) {
          this.segmentTrackingFn(search, 'search')
          this.setState({ selectedMediaIds: [], selectedMedias: [] })
        }
      }
    } else {
      this.setState({
        shutterPageCount: 1,
        totalShutterImgCount: 0,
        shutterStock: false,
        selectedMediaIds: [],
        selectedMedias: []
      })
      this.listShutterstock()
    }
  }
  async addShutterStockImage(media) {
    this.setState({ shutterStock: false, shutterPageCount: 1, totalShutterImgCount: 0, submitLoader: true })
    const newarray = []
    if (this.props.multiple) {
      media.forEach(function (med) {
        newarray.push(med.id)
      })
      const data = {
        images: newarray
      }
      const url = {
        orientation: this.state.orientation
      }
      await this.props.saveShutterstock(data, url)
      if (this.props.shutterstockSavedMedia) {
        this.setState({ selectedMediaIds: [], selectedMedias: [], submitLoader: false })
        for (let i = 0; i < this.props.shutterstockSavedMedia.length; i++) {
          this.segmentTrackingFn(this.props.shutterstockSavedMedia[i], 'select')
        }
        this.props.onSubmit(this.props.shutterstockSavedMedia)
      }
    }
  }
  async listShutterstock() {
    let searchKey = ''
    if (
      this.props.businessSettings &&
      this.props.businessSettings.primaryCategories &&
      this.props.businessSettings.primaryCategories.category_name
    ) {
      searchKey = this.props.businessSettings.primaryCategories.category_name
      this.setState({ searchKey: searchKey, shutterPageCount: 1, totalShutterImgCount: 0 })
      this.searchShutterStock(searchKey)
    } else {
      this.setState({ media: [] })
    }
  }
  async handleSortOrder() {
    await this.setState({ sortOrder: !this.state.sortOrder, start: 0 })
    await this.list()
  }
  async scrollFetchMore() {
    if (this.state.shutterStock) {
      if (this.state.shutterStockSearch) {
        this.searchShutterStock(this.state.shutterStockSearch)
      } else if (this.state.searchKey) {
        this.searchShutterStock(this.state.searchKey)
      }
    } else {
      await this.setState({ start: this.state.start + this.state.limit, scrollStart: true })
      await this.list()
    }
  }
  async mediaSelected(media) {
    // if (media.status !== 'processing') { // allow to add video even if the status is still processing (same as mobile)
    if (this.props.multiple) {
      if (this.state.selectedMediaIds && this.state.selectedMediaIds.includes(media._id)) {
        const med = [...this.state.selectedMediaIds]
        med.splice(this.state.selectedMediaIds.indexOf(media._id), 1)
        const meds = [...this.state.selectedMedias]
        meds.splice(this.state.selectedMedias.indexOf(media), 1)
        await this.setState({ selectedMediaIds: med, selectedMedias: meds })
      } else {
        if (this.state.shutterStock && this.state.selectedMediaIds.length >= 5) {
          notification('error', this.t('settings.you-can-select-upto-5-images-only'))
          return
        }
        const med = [...this.state.selectedMediaIds]
        med.push(media._id)
        const meds = [...this.state.selectedMedias]
        meds.push(media)
        await this.setState({ selectedMediaIds: med, selectedMedias: meds })
      }
    } else {
      if (this.state.selectedMediaIds && this.state.selectedMediaIds.includes(media._id)) {
        const med = [...this.state.selectedMediaIds]
        med.splice(this.state.selectedMediaIds.indexOf(media._id), 1)
        const meds = [...this.state.selectedMedias]
        meds.splice(this.state.selectedMedias.indexOf(media), 1)
        await this.setState({ selectedMediaIds: med, selectedMedias: meds })
      } else {
        await this.setState({ selectedMediaIds: [], selectedMedias: [] })
        const medids = []
        medids.push(media)
        await this.setState({ selectedMediaIds: [...this.state.selectedMediaIds, media._id], selectedMedias: medids })
      }
    }
    // }
  }
  async deleteImg(media) {
    await this.props.deleteMedia({ id: media._id, start: 0 })
    await this.setState({ media: [], loading: true, start: 0 })
    await this.list()
    this.setState({ loading: false })
  }
  async socialFilter(type) {
    await this.setState({ selectedSocial: type, start: 0 })
    await this.list()
  }
  async updateProgressBar(value, progressbar) {
    const percentCompleted = Math.round((value.loaded * 100) / value.total)
    const progress = [...this.state.progressBar]
    const index = progress.indexOf(progressbar)
    if (index && progress[index]) progress[index].progress = percentCompleted
    this.setState({ progressBar: progress })
  }
  async UpdateUploadedMedia(media, progress) {
    if (media && media.data) {
      const fol = [...this.state.media]
      if (media.data[0].type === 'video') {
        media.data[0].previewUrl = media.data[0].video_thumb_url
          ? media.data[0].video_thumb_url
          : 'https://dufzo4epsnvlh.cloudfront.net/image/nophoto_video_thumb_normal.png'
      } else {
        media.data[0].previewUrl = media.data[0].fileUrl
      }
      fol.unshift(media.data[0])
      progress = [...this.state.progressBar]
      const index = progress.indexOf(progress)
      progress.splice(index, 1)
      this.setState({ progressBar: progress, media: fol })
    }
  }
  async errorOnupload(progress) {
    progress = [...this.state.progressBar]
    const index = progress.indexOf(progress)
    progress.splice(index, 1)
    this.setState({ progressBar: progress })
  }
  async handleDropNext({ file, meta }, status) {
    if (status !== 'preparing') {
      const progressbar = {
        id: Math.floor(Math.random() * 1000),
        name: file.name || 'Upload at ' + new Date().toLocaleTimeString(),
        progress: 0,
        file: file
      }
      const progress = [...this.state.progressBar]
      progress.push(progressbar)
      this.setState({ progressBar: progress })
      let link
      let data
      if (status === 'done') {
        data = new FormData()
        data.append('file', file)
        if (this.state.type === 'doc') {
          link = 'doc/'
        } else if (this.state.type === 'video') {
          link = 'video/'
        } else {
          link = ''
        }
      }
      const configTest = {
        onUploadProgress: (progressEvent) => this.updateProgressBar(progressEvent, progressbar)
      }
      if (link) {
        await mediaApi()
          .post(`${config.routes.fetchMedia}/${link}upload`, data, configTest)
          .then((res) => this.UpdateUploadedMedia(res, progressbar))
          .catch((res) => this.errorOnupload(progressbar))
      } else {
        await mediaApi()
          .post(config.routes.onUploadMedia, data, configTest)
          .then((res) => this.UpdateUploadedMedia(res, progressbar))
          .catch((res) => this.errorOnupload(progressbar))
      }
    }
  }
  async uploadClick(e) {
    const file = await new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        if (chosenFiles && chosenFiles.length === 1) {
          resolve(chosenFiles[0].fileObject)
        } else {
          const files = chosenFiles.map((file) => file.fileObject)
          resolve(files)
        }
      })
    })
    if (file && file.length) {
      file.forEach((element) => {
        this.handleDropNext({ file: element }, 'done')
      })
    } else {
      this.handleDropNext({ file }, 'done')
    }
  }
  async searchIcon(e) {
    await this.setState({ search: e.target.value })
  }
  async searchSearchValue(e) {
    await this.setState({ shutterStockSearch: e.target.value })
  }
  async searchShutterStockClick(e) {
    if (e.key === 'Enter') {
      this.searchShutterStock(this.state.shutterStockSearch, true)
    }
  }
  async keyPress(e) {
    if (e.keyCode === 13) {
      await this.setState({ start: 0 })
      await this.list()
    }
  }
  handleSocialLoginGoogle(user) {
    if (user && user.code) {
      const obj = {
        access_token: user.code
      }
      this.props.getGooglePhotos(obj)
      this.setState({ googleNotif: true })
    }
  }
  handleSocialLoginFailure(err) {
    console.log(err)
    // notification("error", "Unable to connect");
  }
  handleSocialLoginFb = (user) => {
    if (user && user.accessToken) {
      const obj = {
        access_token: user.accessToken
      }
      this.props.getFbPhotos(obj)
      this.setState({ fbNotif: true })
    }
  }
  async submitClick(e) {
    e.preventDefault()
    if (this.state.selectedMedias && this.state.selectedMedias.length) {
      if (this.props.crop) {
        await this.setState({ showCrop: true })
        this.ImageCropModal.showPopup(this.state.selectedMedias[0].fileUrl)
      } else {
        if (this.state.shutterStock) {
          if (this.state.selectedMedias.length > 5) {
            notification('error', this.t('settings.you-can-select-upto-5-images-only'), 'error')
          } else {
            this.addShutterStockImage(this.state.selectedMedias)
          }
        } else {
          this.props.onSubmit(this.state.selectedMedias)
        }
      }
    } else {
      notification('error', this.t('settings.please-select-at-least-one-media-to-continue'))
    }
  }
  async cropImage(binary, type) {
    if (type === 'crop') {
      await this.props.editUpload(binary)
      await this.setState((prevState) => {
        let selectedMedias = prevState.selectedMedias
        selectedMedias = this.props.editedurl
        return { selectedMedias }
      })
      await this.props.onSubmit(this.state.selectedMedias)
    } else {
      await this.props.onSubmit(this.state.selectedMedias)
    }
  }
  gotoGuidelines() {
    window.open(`${window.location.origin}/shutterstock-guidelines`, '_blank')
  }
  showError(e) {
    // console.log("e==========>",e)
  }
  handleMediaDrop(files) {
    files.filter((file) => !supportedImageTypes.includes(file.type)).length > 0
      ? notification('error', this.errorImageType)
      : this.handleFileUpload(files)
  }
  handleFileUpload(files) {
    files &&
      Array.isArray(files) &&
      files.forEach((element) => {
        this.handleDropNext({ file: element }, 'done')
      })
  }
  render() {
    const t = this.t
    const media =
      this.state.media &&
      !this.props.loading &&
      this.state.media.length &&
      this.state.media.map(function (media, index) {
        return (
          <li
            id={'mediaItem-' + media._id}
            key={index}
            className={` ${
              this.state.selectedMediaIds && this.state.selectedMediaIds.includes(media._id)
                ? Style['media-list-active'] + ' ' + Style['media-list']
                : Style['media-list']
            }`}>
            <figure
              className={`d-flex align-items-center ${
                media.type === 'video'
                  ? Style['video-item-play-icon'] + ' ' + Style['media-figure']
                  : Style['media-figure']
              }`}
              onClick={() => {
                this.mediaSelected(media)
              }}>
              {media.type === 'image' || media.type === 'social' ? (
                <img className={Style['media-img']} src={media.image_profile_url || media.fileUrl} alt="" />
              ) : null}
              {media.type === 'video' ? <img className={Style['media-img']} src={media.previewUrl} alt="" /> : null}
              {media.type === 'doc' ? (
                <img className={`${Style['media-doc-img']}`} src={getDocIcon(media.fileUrl)} alt=""></img>
              ) : null}
              {media.type === 'icon' ? <img className={Style['media-icons']} src={media.location} alt="" /> : null}
            </figure>
            {this.state.type === 'image' ||
            this.state.type === 'video' ||
            this.state.type === 'social' ||
            this.state.type === 'doc' ? (
              <button
                id={'MediaLibraryDelete-' + media._id}
                className={'btn btn-lg rounded-circle ' + Style['deleteImg']}
                onClick={this.deleteImg.bind(this, media)}>
                <i>
                  <Icon name="trash" />
                </i>
              </button>
            ) : null}
            <div id="MediaLibraryInfo" onClick={this.mediaSelected.bind(this, media)} className={Style['queue-info']}>
              {media.displayName || 'unknown'}
            </div>
            {media.type === 'video' && media.status === 'processing' ? (
              <div className={Style['video-queue-info']}>
                {this.t(
                  'settings.your-video-is-in-queue-to-be-processed-you-will-be-notified-when-it-is-ready-to-be-viewed'
                )}
              </div>
            ) : null}
          </li>
        )
      }, this)
    const mediaLoader =
      (this.props.loading || this.state.loading) &&
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(function (l, index) {
        return (
          <li key={index} className={'mt-10 ' + Style['media-list']}>
            <ContentLoader>
              <rect className="rounded" x="0" y="15" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
            <ContentLoader>
              <rect className="rounded" x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </li>
        )
      })

    const InfiniteMediaLoader =
      this.state.scrollStart &&
      [1, 2, 3, 4, 5].map(function (l, index) {
        return (
          <li key={index} className={Style['media-list']}>
            <ContentLoader>
              <rect className="rounded" x="0" y="15" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
            <ContentLoader>
              <rect className="rounded" x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </li>
        )
      })
    const folderList =
      this.state.isStockImage &&
      this.state.type === 'stockImage' &&
      this.state.folders.map(function (folder) {
        return (
          <li
            key={folder._id}
            className={`${
              this.state.selectedFolder === folder._id
                ? Style['media-active-folder'] + ' ' + Style['media-folder-list']
                : Style['media-folder-list']
            }`}
            onClick={this.getImagesFromFolder.bind(this, folder)}>
            {folder.folder}
            <Icon name="paginationArrowRight" />
          </li>
        )
      }, this)
    return (
      <React.Fragment>
        <Popup
          open={this.props.open && !this.state.showCrop}
          title={
            this.state.type === 'doc'
              ? this.t('settings.choose-a-document')
              : this.state.type === 'video'
              ? this.t('settings.choose-a-video')
              : this.state.type === 'image' ||
                this.state.type === 'social' ||
                this.state.type === 'icon' ||
                this.state.type === 'stockImage' ||
                this.state.type === 'shutterstock'
              ? this.t('settings.choose-an-image')
              : ''
          }
          onCancel={() => this.setState({ open: false })}
          type="media-library-popup"
          overrideForm={true}
          id={this.props.id}
          hideFooter={false}
          modalBackdropClicked={this.closeModal.bind(this, 'backdrop')}>
          <div className={Style['media-popup-content']}>
            <button
              type="reset"
              onClick={this.closeModal.bind(this, 'button')}
              className="close custom-modal-close-button remove-modal-close">
              <span aria-hidden="true">×</span>
            </button>
            <ul className={'pl-0 ' + Style['media-popup-ul']}>
              {this.state.isImage ? (
                <li
                  id="MediaLibraryImageTab"
                  onClick={this.clickedTab.bind(this, 'isImage')}
                  className={
                    'btn btn-sm ' + Style['media-popup-li'] + ` ${this.state.type === 'image' ? Style['active'] : ''}`
                  }>
                  {t('settings.my-images')}
                </li>
              ) : null}
              {this.state.isShutterstock &&
              this.props.me &&
              checkPermissionWOproduct('Shutterstock.Image.View', this.props.me) ? (
                <li
                  id="MediaLibraryShutterStockTab"
                  onClick={this.clickedTab.bind(this, 'shutterstock')}
                  className={
                    'btn btn-sm ' +
                    Style['media-popup-li'] +
                    ` ${this.state.type === 'shutterstock' ? Style['active'] : ''}`
                  }>
                  {t('settings.gosite-images')}
                </li>
              ) : null}
              {this.state.isVideo ? (
                <li
                  id="MediaLibraryVideoTab"
                  onClick={this.clickedTab.bind(this, 'isVideo')}
                  className={
                    'btn btn-sm ' + Style['media-popup-li'] + ` ${this.state.type === 'video' ? Style['active'] : ''}`
                  }>
                  VIDEOS
                </li>
              ) : null}
              {this.state.isSocial ? (
                <li
                  id="MediaLibrarySocialTab"
                  onClick={this.clickedTab.bind(this, 'isSocial')}
                  className={
                    'btn btn-sm ' + Style['media-popup-li'] + ` ${this.state.type === 'social' ? Style['active'] : ''}`
                  }>
                  {t('settings.my-social-images')}
                </li>
              ) : null}
              {this.state.isIcon ? (
                <li
                  id="MediaLibraryIconTab"
                  onClick={this.clickedTab.bind(this, 'isIcon')}
                  className={
                    'btn btn-sm ' + Style['media-popup-li'] + ` ${this.state.type === 'icon' ? Style['active'] : ''}`
                  }>
                  SHAPES
                </li>
              ) : null}
              {this.state.isDocument ? (
                <li
                  id="MediaLibraryDocTab"
                  onClick={this.clickedTab.bind(this, 'isDocument')}
                  className={
                    'btn btn-sm ' + Style['media-popup-li'] + ` ${this.state.type === 'doc' ? Style['active'] : ''}`
                  }>
                  DOCUMENTS
                </li>
              ) : null}
            </ul>
          </div>
          {this.state.type === 'image' ||
          this.state.type === 'video' ||
          this.state.type === 'icon' ||
          this.state.type === 'doc' ? (
            <div className={'clearfix p-10 mb-10 rounded ' + Style['media-manging']}>
              {this.state.type === 'image' ? (
                <Dropzone
                  PreviewComponent="false"
                  InputComponent={DropAndDragLayoutImg}
                  onChangeStatus={this.handleDropNext.bind(this)}
                  accept="image/*"
                  multiple
                  getFilesFromEvent={getFilesFromEvent}
                />
              ) : null}
              {this.state.type === 'video' ? (
                <Dropzone
                  PreviewComponent="false"
                  InputComponent={DropAndDragLayoutVideo}
                  onChangeStatus={this.handleDropNext.bind(this)}
                  accept="video/*"
                  minSize={0}
                  maxSize={200000}
                  multiple
                  getFilesFromEvent={getFilesFromEvent}
                />
              ) : null}
              {this.state.type === 'doc' ? (
                <Dropzone
                  PreviewComponent="false"
                  InputComponent={DropAndDragLayoutDoc}
                  onChangeStatus={this.handleDropNext.bind(this)}
                  accept=".pdf, .doc, .docx, .ppt, .pptx, .ppsx, .xls, .xlsx, .odp, .odt, .ods, .epub,.txt"
                  minSize={0}
                  maxSize={25000}
                  multiple
                  getFilesFromEvent={getFilesFromEvent}
                />
              ) : null}
              {this.state.type === 'icon' ? (
                <div className="d-flex justify-content-end">
                  <div className="col-md-3 p-0">
                    <input
                      type="text"
                      onKeyDown={this.keyPress.bind(this)}
                      value={this.state.search}
                      onChange={this.searchIcon.bind(this)}
                      placeholder={t('settings.search-icon')}
                      className="form-control"
                    />
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="row">
            {this.state.type === 'stockImage' ? (
              <div className="col-md-3">
                <div className={Style['medias-folders-wrapper']}>
                  <div className={Style['media-option']}>
                    <div className={Style['media-tab']}>{folderList}</div>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.type === 'social' ? (
              <div className="col-md-3">
                <div className={Style['medias-folders-wrapper']}>
                  <div className={Style['media-option']}>
                    <div className={Style['media-tab']}>
                      <li
                        id="MediaLibraryGoogle"
                        className={`${
                          this.state.selectedSocial === 'google'
                            ? Style['media-active-folder'] + ' ' + Style['media-folder-list']
                            : Style['media-folder-list']
                        }`}
                        onClick={this.socialFilter.bind(this, 'google')}>
                        Google
                        <Icon name="paginationArrowRight" />
                      </li>
                      <li
                        id="MediaLibraryFacebook"
                        className={`${
                          this.state.selectedSocial === 'facebook'
                            ? Style['media-active-folder'] + ' ' + Style['media-folder-list']
                            : Style['media-folder-list']
                        }`}
                        onClick={this.socialFilter.bind(this, 'facebook')}>
                        Facebook
                        <Icon name="paginationArrowRight" />
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div
              className={`b-media-library-dropdown ${
                this.state.type === 'stockImage' || this.state.type === 'social' ? 'col-md-9' : 'col-md-12'
              }`}>
              <div className={Style['media-listing']}>
                {this.state.type === 'image' ||
                this.state.type === 'stockImage' ||
                this.state.type === 'video' ||
                this.state.type === 'doc' ||
                this.state.type === 'social' ||
                this.state.type === 'icon' ||
                this.state.type === 'shutterstock' ? (
                  <div>
                    <div
                      className={`d-flex align-items-center ${Style['sort-media-box']} ${
                        this.state.type === 'shutterstock' ? 'height-auto' : null
                      } `}>
                      {this.state.type === 'social' ? (
                        <div>
                          <div className="pl-0">
                            {this.state.selectedSocial === 'google' ? (
                              <button
                                id="socialLoginGoogleBtn"
                                onClick={this.props.onClickGoogleLogin}
                                className="btn btn-md mr-15 border">
                                <i className={'mr-5 ' + Style['connect-google']}>
                                  <Icon name="google" />
                                </i>
                                {t('settings.connect-with-google')}
                              </button>
                            ) : null}
                            {this.state.selectedSocial === 'facebook' ? (
                              <FacebookLogin
                                appId={config.facebook_client_id}
                                autoLoad={false}
                                fields="name,email,picture"
                                responseType="code"
                                scope="email"
                                render={(renderProps) => (
                                  <button
                                    onClick={renderProps.onClick}
                                    className="btn btn-md mr-15 border"
                                    id="facebookConnect">
                                    <i className={'mr-5 ' + Style['connect-facebook']}>
                                      <Icon name="facebook" />
                                    </i>
                                    {t('settings.connect-with-facebook')}
                                  </button>
                                )}
                                redirectUri={window.location.origin + '/socialconnect'}
                                onFailure={(err) => this.handleSocialLoginFailure(err)}
                                callback={(user) => this.handleSocialLoginFb(user)}
                              />
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                      {this.state.type === 'shutterstock' ? (
                        <div className="col-md-12 d-flex p-0 pb-lg-15">
                          <div className="position-relative flex-fill mr-15">
                            <input
                              type="text"
                              onKeyDown={this.searchShutterStockClick.bind(this)}
                              value={this.state.shutterStockSearch}
                              onChange={this.searchSearchValue.bind(this)}
                              placeholder={t('settings.type-then-hit-enter-to-search')}
                              className="form-control"
                            />
                            <span className={'fz-12 mb-5 mt-4 m-lg-0 d-block ' + Style['powered-ss']}>
                              {t('settings.powered-by-shutterstock')}
                            </span>
                          </div>
                          <div className="position-relative">
                            <span className={'inner-link hor-ver-img cursor-pointer ' + Style['ss-rotate-img']}>
                              {this.state.orientation === 'horizontal' ? (
                                <img
                                  src="https://gosite-assets.s3-us-west-1.amazonaws.com/image/icon-new/device-rotate-black.svg"
                                  title={t('settings.horizontal-images')}
                                  onClick={this.searchShutterStockOrien.bind(
                                    this,
                                    this.state.shutterStockSearch,
                                    'vertical'
                                  )}
                                  alt=""
                                />
                              ) : (
                                <img
                                  src="https://gosite-assets.s3-us-west-1.amazonaws.com/image/icon-new/device-rotate-black.svg"
                                  title={t('settings.vertical-images')}
                                  onClick={this.searchShutterStockOrien.bind(
                                    this,
                                    this.state.shutterStockSearch,
                                    'horizontal'
                                  )}
                                  alt=""
                                />
                              )}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex ml-auto">
                          <div className={`custom-form-group-dropdown mr-15 ${Style['sort-by']}`}>
                            <Select
                              className={`dropdown-select ${Style['media-sort']}`}
                              classNamePrefix="dropdownSelect"
                              closeMenuOnSelect={true}
                              options={this.sortFilter}
                              isMulti={false}
                              name="sort"
                              id="MediaLibrarySort"
                              value={this.state.sort}
                              maxMenuHeight="200px"
                              onChange={this.sortMedia.bind(this)}
                            />
                          </div>
                          <div className={Style['media-sort-type']}>
                            <span onClick={this.handleSortOrder.bind(this)}>
                              <div
                                className={`caret-button-up m-2 ${
                                  this.state.sortOrder ? Style['media-sort-desc-active'] : Style['media-sort-desc']
                                }`}></div>
                              <div
                                className={`caret-button-down m-2 ${
                                  !this.state.sortOrder ? Style['media-sort-asc-active'] : Style['media-sort-asc']
                                }`}></div>
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* NEW : Added new drop area domponent here */}
                    <DropzoneComponent
                      minSize={0}
                      maxSize={this.state.type === 'video' ? 200000000 : this.state.type === 'doc' ? 25000000 : null} // in bytes
                      disabled={!!(this.state.type === 'social' || this.state.type === 'icon')}
                      onDropRejected={(file) => this.showError(file)}
                      onDrop={(files) => this.handleMediaDrop(files)}
                      accept={
                        this.state.type === 'doc'
                          ? docExtens
                          : this.state.type === 'icon' ||
                            this.state.type === 'image' ||
                            this.state.type === 'stockImage'
                          ? ['image/*']
                          : this.state.type === 'video'
                          ? ['video/*']
                          : ['image/*']
                      }>
                      {({ getRootProps, getInputProps, isDragActive }) => (
                        <div
                          {...getRootProps({
                            className: 'dropzone drop-section',
                            onDrop: (event) => event.preventDefault(),
                            onClick: (event) => event.stopPropagation(),
                            style: { outline: 'none' }
                          })}>
                          <input {...getInputProps()} />
                          <div className="position-relative">
                            {isDragActive ? (
                              <div className="media-drop-overlay position-absolute d-flex align-items-center justify-content-center">
                                {t('settings.drop-your-files-here')}
                              </div>
                            ) : null}
                            <>
                              {this.props &&
                              this.state.media &&
                              this.state.media.length === 0 &&
                              !this.props.loading &&
                              this.state.progressBar.length === 0 ? (
                                <div className={'d-flex align-items-center ' + Style['noImage-content']}>
                                  {this.state.type === 'icon' ||
                                  this.state.type === 'image' ||
                                  this.state.type === 'stockImage' ||
                                  this.state.type === 'social' ? (
                                    <p className="m-auto">{t('settings.no-images')}</p>
                                  ) : null}
                                  {this.state.type === 'video' ? (
                                    <p className="m-auto">{t('settings.no-videos')}</p>
                                  ) : null}
                                  {this.state.type === 'doc' ? (
                                    <p className="m-auto">
                                      {t('settings.start-adding-docs-its-easy')} <br />
                                      {t(
                                        'settings.drag-and-drop-files-into-this-window-or-click-upload-docs-to-get-started'
                                      )}
                                    </p>
                                  ) : null}
                                </div>
                              ) : null}

                              {this.state.media && this.state.media.length && !this.props.loading ? (
                                <div id="scrollul" className={Style['galleryImages']}>
                                  <InfiniteScroll
                                    dataLength={this.state.media && this.state.media.length}
                                    next={this.scrollFetchMore.bind(this)}
                                    hasMore={
                                      this.state.shutterStock
                                        ? true
                                        : this.props.media &&
                                          this.props.media.totalCount > (this.state.media && this.state.media.length)
                                    }
                                    loader={InfiniteMediaLoader}
                                    scrollableTarget={'scrollul'}>
                                    <ul
                                      id="listMediaGallery"
                                      className={`d-inline-block w-100 pl-0 pr-0 ${Style['b-media-gallery']}`}>
                                      {this.state.type === 'social' &&
                                      this.state.selectedSocial === 'facebook' &&
                                      this.state.isSocial &&
                                      this.state.fbNotif ? (
                                        <div className={'mb-10 ' + Style['social-success-msg']}>
                                          {t('settings.we-are-fetching-your-photos-this-will-take-some-time')}
                                        </div>
                                      ) : null}
                                      {this.state.type === 'social' &&
                                      this.state.selectedSocial === 'google' &&
                                      this.state.isSocial &&
                                      this.state.googleNotif ? (
                                        <div className={'mb-10 ' + Style['social-success-msg']}>
                                          {t('settings.we-are-fetching-your-photos-this-will-take-some-time')}
                                        </div>
                                      ) : null}
                                      {this.state.progressBar && this.state.progressBar.length > 0 ? (
                                        <span>
                                          {this.state.progressBar.map((p, index) => (
                                            <li key={index} className={Style['media-list']}>
                                              <figure className="rounded overflow-hidden mb-0 h-100">
                                                {this.state.type === 'image' ? (
                                                  <img
                                                    className={`w-100 rounded ${Style['media-img progress-bar-img']}`}
                                                    src={URL.createObjectURL(p.file)}
                                                    alt=""
                                                  />
                                                ) : null}
                                                <div
                                                  className={`position-absolute w-100 rounded ${Style['media-list-progress']}`}>
                                                  <Line
                                                    percent={p.progress}
                                                    className={'px-10 '}
                                                    trailWidth="3"
                                                    trailColor="#ffffff"
                                                    strokeWidth="3"
                                                    strokeColor="#0258ff"
                                                  />
                                                </div>
                                              </figure>
                                            </li>
                                          ))}
                                        </span>
                                      ) : null}
                                      {media}
                                    </ul>
                                  </InfiniteScroll>{' '}
                                </div>
                              ) : null}
                              {this.state.media &&
                              !this.state.media.length &&
                              !this.props.loading &&
                              this.state.progressBar &&
                              this.state.progressBar.length > 0 ? (
                                <div id="scrollul" className={Style['galleryImages']}>
                                  <ul
                                    id="listMediaGallery"
                                    className={`d-inline-block w-100 pl-0 pr-0 ${Style['b-media-gallery']}`}>
                                    <span>
                                      {this.state.progressBar.map((p, index) => (
                                        <li key={index} className={Style['media-list']}>
                                          <figure className="rounded overflow-hidden mb-0 h-100">
                                            {this.state.type === 'image' ? (
                                              <img
                                                className={`w-100 rounded ${Style['media-img progress-bar-img']}`}
                                                src={URL.createObjectURL(p.file)}
                                                alt=""
                                              />
                                            ) : null}
                                            <div
                                              className={`position-absolute w-100 rounded ${Style['media-list-progress']}`}>
                                              <Line
                                                percent={p.progress}
                                                className={'px-10 '}
                                                trailWidth="3"
                                                trailColor="#ffffff"
                                                strokeWidth="3"
                                                strokeColor="#0258ff"
                                              />
                                            </div>
                                          </figure>
                                        </li>
                                      ))}
                                    </span>
                                  </ul>{' '}
                                </div>
                              ) : null}
                            </>
                          </div>
                        </div>
                      )}
                    </DropzoneComponent>
                    {/* DROP component till here */}
                    {this.props.loading ? <div className={Style['galleryImages']}>{mediaLoader}</div> : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <footer className="d-block d-sm-flex mt-10 align-items-center">
            <button
              id="btnMediaLibrarySubmit"
              type="submit"
              disabled={this.state.submitLoader}
              className="btn btn-primary btn-xl border-0 mr-20"
              onClick={this.submitClick.bind(this)}>
              {t('settings.continue')}
              {this.state.submitLoader && (
                <i className={'btn-icon top-minus-2 mr-5 btn-loader'}>
                  <Icon name="spinner" />
                </i>
              )}
            </button>
            <button
              id="btnMediaLibraryCancel"
              type="reset"
              className="btn btn-xl border"
              onClick={this.props.onCancel.bind(this, 'button')}>
              {t('settings.cancel-0')}
            </button>
            {this.state.type === 'shutterstock' ? (
              <span
                className={'d-block mt-10 ml-auto cursor-pointer ' + Style['guidlines']}
                onClick={this.gotoGuidelines.bind(this)}>
                {t('settings.stock-image-use-guidelines')}
              </span>
            ) : (
              ''
            )}
          </footer>
        </Popup>
        {this.state.showCrop ? (
          <ImageCropModal
            ref={(ref) => (this.ImageCropModal = ref)}
            cropImage={this.cropImage.bind(this)}
            close={() => {
              this.setState({ showCrop: false })
            }}
          />
        ) : null}
      </React.Fragment>
    )
  }
}
export default withTranslation()(
  compose(WithGoogleLoginHOC, connect(mapStateToProps, mapDispatchToProps))(MediaLibraryPopup)
)
