import * as React from 'react';
import { useState } from 'react';
import { LocationData } from './types';
import OnClickOutside from '../../outsideClickComponent';
import Icons from '../../icons';

interface LocationListProps {
  bulkUpdate?: boolean;
  formattedLocations?: LocationData[];
  onListItemClick: (value: any) => void;
}
export const LocationList = ({ bulkUpdate, formattedLocations, onListItemClick }: LocationListProps) => {
  const [showList, setShowList] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [selected, setSelected] = useState<LocationData | undefined>();

  const handleListItemClick = (e: React.MouseEvent<HTMLLIElement>, loc: LocationData) => {
    e.stopPropagation();
    setSelected(loc);
    setShowList(false);
    if (loc && loc.customer) onListItemClick(loc.customer);
  };

  return (
    <div onClick={() => setShowList(true)} className={'dropdown select-tag b-manage-dropdown b-location-filter w-100'}>
      <div
        className={
          'dropdown-toggle text-truncate btn btn-md border dropdown-toggle-manage d-flex align-items-center justify-content-between'
        }
        data-toggle="dropdown"
      >
        {formattedLocations && formattedLocations.length > 0 ? (
          <span className="font-family-base text-truncate w-100 text-left">{`${
            selected && selected.name ? selected.name : 'Select a Location'
          }`}</span>
        ) : (
          <span className="font-family-base text-truncate w-100 text-left">No Locations</span>
        )}
        <i className="btn-icon ml-10 manage-icon-arrow">
          <Icons name="arrowDown" />
        </i>
      </div>
      {formattedLocations && formattedLocations.length > 0 && (
        <>
          {showList && (
            <OnClickOutside handleClickOutside={() => setShowList(false)}>
              <ul
                className={`dropdown-menu show height-200 dropdown-menu-manage overflow-y-scrollbar ${
                  !bulkUpdate ? 'dropdown-menu-right' : ''
                }`}
              >
                <li className=" p-0  d-flex align-items-center  position-relative">
                  <button
                    type="button"
                    className={
                      'btn btn-icon btn-sm position-absolute d-flex align-items-center right-0 pr-15 top-minus-1'
                    }
                  >
                    <Icons name="search" />
                  </button>
                  <input
                    className="form-control border-0 px-15"
                    type="text"
                    placeholder="Search.."
                    onChange={(e) => setSearchKey(e.currentTarget.value)}
                  />
                </li>
                {formattedLocations
                  .filter((loc) => loc.name.toLowerCase().includes(searchKey.toLowerCase()))
                  .map((loc) => (
                    <li onClick={(e) => handleListItemClick(e, loc)} key={loc.name} className="px-15 py-5">
                      <div className="form-group mb-0">
                        <label>
                          <span className="checkbox-icon"></span>
                          {loc.name}
                        </label>
                      </div>
                    </li>
                  ))}
              </ul>{' '}
            </OnClickOutside>
          )}
        </>
      )}
    </div>
  );
};
