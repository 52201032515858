import React, { Component, Fragment } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Popup from '@components/popups/basic-modal'
import { nFormatter, setpaymentAttempt, getPaymentCount } from '@helpers/utility'
import config from '@config/index'
import style from '../index.module.scss'
import InputMask from 'react-input-mask'
import Tooltip from '@components/tooltip'
import notification from '@components/notification'
import ReCAPTCHA from 'react-google-recaptcha'
import { withTranslation } from 'react-i18next'
let isFormChanged = false

class ManualTransaction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      timeSlots: '',
      cardToken: '',
      serviceSelected: false,
      dateSelected: false,
      me: '',
      products: [],
      loadedServices: false,
      isTokengenerated: false,
      showReCaptcha: false,
      capthcaValue: '',
      disableAmount: false,
      txtAmount: '',
      invoiceData: null,
      clicked: false
    }
    this.listenCardConnect = this.listenCardConnect.bind(this)
  }
  componentDidMount() {
    window.addEventListener('message', this.listenCardConnect)
    if (getPaymentCount() >= 10) {
      this.setState({ showReCaptcha: true })
    }
    if (this.props.invoiceData) {
      if (this.props.invoiceData.amount) {
        this.setState({ txtAmount: this.props.invoiceData.amount, disableAmount: true })
      }
      this.setState({ invoiceData: this.props.invoiceData })
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.me !== state.me || props.products !== state.products) {
      return { me: props.me, products: props.products }
    } else {
      return null
    }
  }
  componentWillUnmount() {
    window.removeEventListener('message', this.listenCardConnect)
  }
  showPopup() {
    this.setState({ open: true })
  }
  closeModal(reset, resetForm) {
    this.props.close(isFormChanged)
    isFormChanged = false
  }
  reset(resetForm) {
    resetForm({
      txtCardHolder: '',
      txtAmount: '',
      totalamount: '',
      min_amount: '',
      txtExpiry: '',
      txtPostal: '',
      txtCvv: ''
    })
  }
  submitBtnClick() {
    if (!this.state.cardToken) {
      this.setState({ cardError: true })
    } else {
      this.setState({ cardError: false })
    }
  }
  handleSubmit(values, { resetForm }) {
    this.setState({ clicked: true })
    if (this.state.showReCaptcha) {
      if (!this.state.capthcaValue) {
        notification('error', this.props.t('payments.please-complete-the-recaptcha'))
        return
      }
    }
    if (!this.state.cardToken) {
      this.setState({ cardError: true })
      return
    }
    if (values.txtExpiry) {
      const arr = values.txtExpiry.split('/')
      if (arr[0] && arr[1]) {
        const currentYear = new Date().getFullYear().toString().substr(-2)
        if (Number(arr[1]) < currentYear || arr[0] > 12 || arr[0] === '00') {
          notification('error', this.props.t('payments.wrong-expiration'))
          return
        }
      }
    }
    this.setState({ cardError: false })
    const payload = {}
    payload.card_token = this.state.cardToken

    if (!values['selService']) {
      payload.source = 'direct'
    }

    payload.card_holder = values.txtCardHolder
    payload.amount = values.txtAmount
    payload.expiry = values.txtExpiry
    payload.postal = values.txtPostal
    payload.cvv = values.txtCvv
    if (this.state.invoiceData) {
      payload.invoice_number = this.state.invoiceData.invoice_number
      payload.source = this.state.invoiceData.source
    }
    setpaymentAttempt()
    this.props.onSubmit({ payload, resetForm })
  }
  handleCancel(values, { resetForm }) {
    this.props.close(isFormChanged)
    isFormChanged = false
  }

  listenCardConnect(event) {
    if (
      event &&
      event.data &&
      event.origin &&
      (event.origin.indexOf('https://fts-uat.cardconnect.com') === 0 ||
        event.origin.indexOf('https://fts.cardconnect.com') === 0)
    ) {
      const cardConnectEvent = JSON.parse(event.data)
      if (cardConnectEvent.cssLoaded) {
        this.setState({
          showIframe: true
        })
      }
      if (cardConnectEvent.message && cardConnectEvent.message.length) {
        this.setState({
          cardToken: cardConnectEvent.message,
          cardError: false
        })
      }
      if (cardConnectEvent.validationError && cardConnectEvent.validationError.length) {
        this.setState({
          cardToken: '',
          cardError: true
        })
      }
    }
  }

  handleDateChangeRaw = (e) => {
    e.preventDefault()
  }
  expiryValidation(setFieldValue, str) {
    str = str.replace(/_/g, '')

    const arr = str.split('/')
    if (arr[0] && !arr[1]) {
      if (Number(arr[0]) > 12) {
        arr[0] = '12'
      }
      const reg1 = /^(0[1-9]|1[012])$/
      if (arr[0].length <= 1 || reg1.test(arr[0])) {
        setFieldValue('txtExpiry', `${arr[0]}/`)
      }
    } else if (arr[0] && arr[1]) {
      const currentYear = new Date().getFullYear().toString().substr(-2)
      if (arr[1].length > 1 && Number(arr[1]) < currentYear) arr[1] = currentYear

      const reg2 = /^(0[1-9]|[12]\d|3[01])$/
      if (arr[1].length <= 2 || reg2.test(arr[1])) {
        setFieldValue('txtExpiry', `${arr[0]}/${arr[1]}`)
      }
    } else {
      setFieldValue('txtExpiry', '')
    }
  }
  onChangeRecaptche(val) {
    this.setState({ capthcaValue: val })
  }
  render() {
    const t = this.props.t
    const { submitting } = this.props
    const { showReCaptcha, txtAmount, disableAmount } = this.state
    return (
      <Fragment>
        <Popup
          open={true}
          title={t('payments.manually-add-a-transaction')}
          hideFooter={true}
          onCancel={() => this.setState({ open: false })}
          type="basic"
          modalBackdropClicked={() => console.log(null)}
          id="modalAddTransaction">
          <Formik
            validateOnChange={false}
            validateOnBlur={true}
            enableReinitialize={true}
            initialValues={{
              txtCardHolder: '',
              txtAmount: txtAmount,
              totalamount: '',
              min_amount: '',
              txtExpiry: '',
              txtPostal: '',
              txtCvv: ''
            }}
            validationSchema={Yup.object().shape({
              txtCardHolder: Yup.string().required(t('payments.required')),
              txtAmount: Yup.number().required(t('payments.required')),
              txtExpiry: Yup.string().required(t('payments.required')),
              txtPostal: Yup.string().required(t('payments.required')),
              txtCvv: Yup.string()
                .required(t('payments.required'))
                .test('cvv', t('payments.invalid-cvv'), (val) => {
                  if (val) return val.length >= 3
                  else return false
                })
            })}
            onReset={this.handleCancel.bind(this)}
            onSubmit={this.handleSubmit.bind(this)}
            render={({ values, setFieldValue }) => {
              return (
                <Form
                  id="frmAddTransaction"
                  name="frmAddTransaction"
                  className={`pt-20 ${style['add-transaction-form']}`}>
                  <button
                    name="btnCloseModal"
                    type="reset"
                    className="close custom-modal-close-button remove-modal-close">
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="row">
                    <div className="col-12 col-sm-12">
                      <div className="form-group">
                        <label className="label-text ">
                          AMOUNT <i className="star">*</i>
                        </label>
                        <ErrorMessage
                          id="frmAddTrans_amountError"
                          name="txtAmount"
                          component="span"
                          className="form-error"
                        />
                        <Field
                          data-test="transaction-form-amount-field"
                          id="frmAddTrans_txtAmount"
                          className="form-control form-control-lg mb-20"
                          type="text"
                          placeholder={t('payments.enter-amount-0')}
                          name="txtAmount"
                          value={values.txtAmount}
                          maxLength={config.amountFieldLength}
                          disabled={disableAmount}
                          onChange={(e) => {
                            const { value } = e.target
                            const regex = /^(0*[0-9][0-9]*(\.[0-9]{0,2})?|0*\.[0-9]{0,2})$/
                            if (regex.test(value.toString()) || value.length === 0) {
                              setFieldValue('txtAmount', value)
                              isFormChanged = true
                            }
                          }}
                        />
                        <div className={`${this.state.serviceSelected ? 'd-block d-md-flex' : 'd-none'}`}>
                          {values.totalamount ? (
                            <div className="d-flex flex-grow-1 mb-15 mb-md-0 align-items-center">
                              <h6>TOTAL:</h6>
                              <strong className={`text-primary ml-5 ${style['highlight']}`}>
                                ${nFormatter(values.totalamount)}
                              </strong>
                            </div>
                          ) : null}
                          {values.min_amount ? (
                            <div className="d-flex flex-grow-1 align-items-center">
                              <h6>PARTIAL:</h6>
                              <strong className={`text-primary ml-5 ${style['highlight']}`}>
                                ${nFormatter(values.min_amount)}
                              </strong>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12">
                      <div className="form-group">
                        <label className="label-text ">
                          {t('payments.cardholder-name')} <i className="star">*</i>{' '}
                          <Tooltip
                            position="top"
                            message={t(
                              'payments.please-enter-the-full-name-displayed-on-the-payment-card-being-processed'
                            )}
                          />
                        </label>
                        <ErrorMessage
                          id="frmAddTrans_amountError"
                          name="txtCardHolder"
                          component="span"
                          className="form-error"
                        />

                        <Field
                          data-test="transaction-cardholder-name-field"
                          id="frmAddTrans_txtCardHolder"
                          className="form-control form-control-lg mb-20"
                          type="text"
                          placeholder={t('payments.enter-cardholder-name')}
                          name="txtCardHolder"
                          value={values.txtCardHolder}
                          onChange={(event) => {
                            setFieldValue('txtCardHolder', event.target.value)
                            isFormChanged = true
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12">
                      <div className="form-group">
                        <label className="label-text ">
                          {t('payments.card-number')} <i className="star">*</i>
                        </label>
                        {this.state.cardError && <span className="form-error">{t('payments.required')}</span>}
                        <div id="payment" className="card-iframe-group position-relative">
                          <div
                            className={`${style['card-loader']} ${
                              this.state.showIframe ? style['card-loader-hidden'] : ''
                            } `}>
                            <img
                              className={`${style['loader-img']}`}
                              src="https://dufzo4epsnvlh.cloudfront.net/image/loader-dot.svg"
                              alt="loader"
                            />
                            <input
                              data-test="transaction-card-number-field"
                              name="card"
                              placeholder={t('payments.card-number-0')}
                              disabled
                              className="form-control relative"
                            />
                          </div>
                          <iframe
                            id="tokenFrame"
                            ref={(ref) => (this.card = ref)}
                            name="tokenFrame"
                            title="tokenFrame"
                            src={config.cardConnectUrl}
                            frameBorder="0"
                            scrolling="no"
                            height="44"
                            width="100%"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label className="label-text ">
                          {' '}
                          EXP
                          <i className="star">*</i>
                          <ErrorMessage
                            id="frmAddTrans_expError"
                            name="txtExpiry"
                            component="span"
                            className="form-error"
                          />
                        </label>
                        <InputMask
                          data-test="transaction-card-expirydate-field"
                          id="frmAddTrans_txtExDate"
                          className="form-control form-control-lg"
                          // type="text"
                          // maskChar={null}
                          placeholder="MM/YY"
                          mask="99/99"
                          // maskplaceholder=""
                          name="txtExpiry"
                          value={values.txtExpiry}
                          onChange={(event) => {
                            this.expiryValidation(setFieldValue, event.target.value)
                            // setFieldValue("txtExpiry", event.target.value)
                            isFormChanged = true
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label className="label-text ">
                          CVV <i className="star">*</i>
                          <ErrorMessage
                            id="frmAddTrans_cvvError"
                            name="txtCvv"
                            component="span"
                            className="form-error"
                          />
                        </label>
                        <Field
                          data-test="transaction-card-cvv-field"
                          id="frmAddTrans_txtCvv"
                          className="form-control form-control-lg"
                          type="number"
                          name="txtCvv"
                          placeholder={'CVV'}
                          maxLength={4}
                          onChange={(e) => {
                            e.preventDefault()
                            const { value } = e.target
                            if (value.length <= 4) {
                              setFieldValue('txtCvv', value)
                              isFormChanged = true
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label className="label-text ">
                          ZIP <i className="star">*</i>
                          <ErrorMessage
                            id="frmAddTrans_postalError"
                            name="txtPostal"
                            component="span"
                            className="form-error"
                          />
                        </label>
                        <Field
                          data-test="transaction-card-zip-field"
                          placeholder={t('payments.zip')}
                          id="frmAddTrans_txtZip"
                          className="form-control form-control-lg"
                          type="text"
                          name="txtPostal"
                          onChange={(event) => {
                            setFieldValue('txtPostal', event.target.value)
                            isFormChanged = true
                          }}
                        />
                      </div>
                    </div>
                    {showReCaptcha ? (
                      <div className="col-12">
                        <ReCAPTCHA
                          sitekey={config.googleReCAPTCHAKey}
                          onChange={(val) => this.onChangeRecaptche(val)}
                        />
                      </div>
                    ) : null}
                  </div>
                  <footer className="d-block d-sm-flex mt-10">
                    <button
                      data-test="transaction-form-submit-collect-payment-btn"
                      id="btnCollectPayment"
                      onClick={this.submitBtnClick.bind(this)}
                      name="btnCollectPayment"
                      disabled={submitting || this.state.clicked}
                      type="submit"
                      className="btn btn-primary btn-xl border-0 flex-grow-1 add-btn d-block d-sm-inline-block mb-20 mb-sm-0 mr-20">
                      {t('payments.collect-payment-0')}
                      {submitting ? (
                        <i className="btn-icon top-minus-2">
                          <img
                            className="spinner"
                            src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg"
                            alt=""
                          />
                        </i>
                      ) : null}
                    </button>
                    <button
                      id="btnAddTransactionCancel"
                      name="btnAddTransactionCancel"
                      className={'btn btn-xl m-0 cancel-btn custom-btn-width'}
                      type="reset">
                      {t('payments.cancel-0')}
                    </button>
                  </footer>
                </Form>
              )
            }}
          />
        </Popup>
      </Fragment>
    )
  }
}

export default withTranslation(null, { withRef: true })(ManualTransaction)
