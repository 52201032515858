import React, { PureComponent } from "react"
import Icon from "../icons"

class LocationFilterUniversal extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            showDrodown: false,
            searchKey: null,
            locationsSet: false,
            checkedItems: [],
            openDropdown: false,
            locations: []
        }
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    setWrapperRef(node) {
        this.wrapperRef = node;
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ openDropdown: false });
            if( this.props.page === "messenger" ) {
                this.props.dropdownTogglePassParent(false); 
            }
        }
    }
    async componentDidMount() {
        window.addEventListener("mousedown", this.handleClickOutside);
        if (this.props.locations && this.props.locations.length) {
            let { products } = this.props
            let filteredSet = this.props.locations.filter(data => {
                if (products && products.length) {
                    let locProducts = data.products || []
                    return products.some(item => locProducts.includes(item))
                }
                else return true
            })
            await this.setState({ locations: filteredSet, checkedItems: this.props.initialValue || filteredSet })
            if(this.props.selectedLocations && this.props.selectedLocations.length) {
                this.setCheckedItemsFromProps()
            } else if (this.props.showInitalAllLocation) {
                // console.log("this.state.locations =====", this.state.locations)
                await this.setState({ checkedItems: this.state.locations })
            }
            // if(this.props.selectedLocations) {
            //     this.setCheckedItemsFromProps()
            // }
            this.setState({ checkedAll: this.state.checkedItems.length === this.state.locations.length })
        }
    }
    componentDidUpdate(prevProps) {
        if (!prevProps.locations && this.props.locations) {
            let { products } = this.props
            let filteredSet = this.props.locations.filter(data => {
                if (products && products.length) {
                    let locProducts = data.products || []
                    return products.some(item => locProducts.includes(item))
                }
                else return true
            })
            this.setState({ locations: filteredSet, checkedItems: filteredSet })
        }

        if(this.props.selectedLocations && this.props.selectedLocations.length) {
            if(!prevProps.selectedLocations) {
                this.setCheckedItemsFromProps()
            }
            else if(JSON.stringify(prevProps.selectedLocations) !== JSON.stringify(this.props.selectedLocations)) {
                this.setCheckedItemsFromProps()
            }
        }  else if (this.props.showInitalAllLocation) {
            this.setState({ checkedItems: this.state.locations })
        }
    }

    setCheckedItemsFromProps() {        // for controlling value of component  
        let x = [];
        (this.props.selectedLocations || []).forEach(item => {
            let location = this.state.locations.find(loc => item._id === loc._id)
            if(location) x.push(location)
        })
        this.setState({ 
            checkedItems: x ,
            checkedAll: this.state.locations.length === x.length
        })
    }

    componentWillUnmount() {
        window.removeEventListener("mousedown", this.handleClickOutside);
    }

    getSelectedNames() {
        let selectedItemNames = ""
        if(!this.state.locations || !this.state.locations.length) {
            return "No Locations"
        }
        else if (this.state.checkedItems && this.state.locations && (this.state.checkedItems.length === this.state.locations.length)) {
            return `All ${this.state.locations.length} Locations`
        } 
        else if(!this.state.checkedItems || !this.state.checkedItems.length) {
            return "No Locations"
        }
        else {
            if(this.props.page === "bulkUpdateSelectAccountModal"){
                this.state.checkedItems.length && this.state.checkedItems.forEach((loc,index) => {
                    selectedItemNames += `${loc.displayname}${(this.state.checkedItems.length > 1&&index<(this.state.checkedItems.length-1)) ? "," : ""}`
                })
            }else {
                this.state.checkedItems.length && this.state.checkedItems.forEach(loc => {
                    selectedItemNames += `${loc.name}${this.state.checkedItems.length > 1 ? "," : ""}`
                })
            }
            return selectedItemNames
        } 
    }

    async selectedItem(value, type, loc) {
        let locations = [...this.state.locations]
        if (type !== "checkAll") {
            if (value) {
                await this.setState(prevState => (
                    {
                        checkedItems: [...prevState.checkedItems, loc]
                    }
                ));
                this.setState({ 
                    checkedAll: this.state.checkedItems.length === this.state.locations.length
                })
            } else {
                if (this.state.checkedItems && this.state.checkedItems.length > 1) {
                    let arr = [...this.state.checkedItems]
                    arr = arr.filter(item => item._id !== loc._id)
                    await this.setState({ checkedItems: arr })
                    this.setState({ 
                        checkedAll: this.state.checkedItems.length === this.state.locations.length
                    })
                } else {
                    return
                }
            }
            await this.props.selectLocations(this.state.checkedItems)
        } else if (type === "checkAll") {
            
            if (value) {
                this.setState({ checkedAll: value })
                await this.setState({ checkedItems: locations })
                await this.props.selectLocations(this.state.checkedItems)
            } else {
                this.setState({ checkedAll: value })
                let arr = [];
                if(!this.props.deSelectAll){
                    arr.push(locations[0]);
                    await this.setState({ checkedItems: arr })
                }else{
                    await this.setState({ checkedItems: arr })
                }
                await this.props.selectLocations(this.state.checkedItems)
            }
        }

    }

    listitems() {
        return this.state.locations && this.state.locations.length && this.state.locations
        // eslint-disable-next-line
            .filter((data) => {
                if (this.state.searchKey === null)
                    return data
                else if (data.name.toLowerCase().includes(this.state.searchKey.toLowerCase())) {
                    return data
                }
            }).map((loc, index) => {
                return (
                    <li key={loc._id} className="px-15 py-5">
                        <div className="form-group mb-0">
                            <div className="form-check form-check--has-label">
                                <label>
                                    <input
                                        type="checkbox"
                                        className=""
                                        checked={this.isLocationSelected(loc)}
                                        onChange={(event) => { this.selectedItem(event.target.checked, index, loc) }}
                                    />
                                    <span className="checkbox-icon"></span>
                                    {this.props.page === "bulkUpdateSelectAccountModal" ? loc.displayname : loc.name}
                                </label>
                            </div>
                        </div>

                    </li>
                )
            })
    }

    isLocationSelected(item) {
        let items = this.state.checkedItems.length ? this.state.checkedItems.map(e => e._id) : []
        if (items.length && items.indexOf(item._id) > -1) {
            return true
        }
        else {
            return false
        }
    }
    handleDropdownParent = () => {
        this.setState({ openDropdown: !this.state.openDropdown })
        if( this.props.page === "messenger" ) {
            this.props.dropdownTogglePassParent(true); 
        }           
    }
    render() {
        // console.log("Locations 11111=====", this.state.checkedItems.length)
        // console.log("Locations =====", this.state.locations.length)
        return (
            <div className={`dropdown select-tag b-manage-dropdown b-location-filter ${this.props.page === "enterpriseDashboard" ? "b-placement-location-filter" : ""} ${this.props.page === "placement" ? "b-placement-location-filter" : ""} ${this.props.size ? this.props.size : ""} ${this.props.page === "managers" || this.props.page === "bulkUpdateSelectAccountModal" ? "w-100" : ""}`}>
                <div onClick={ this.handleDropdownParent } className={`dropdown-toggle text-truncate btn btn-md border dropdown-toggle-manage d-flex align-items-center justify-content-between ${this.props.page === "managers" ? "btn-lg px-15" : "btn-md"}`}>
                    <span className="font-family-base text-truncate w-100 text-left">
                        {this.getSelectedNames()}
                        {/* {this.state.checkedItems && this.state.checkedItems.length ? this.getSelectedNames() : this.state.locations && this.state.locations.length && !this.props.showInitalNoLocation ? `All ${this.state.locations.length} Locations` : "No Locations"} */}
                    </span>
                    <i className="btn-icon ml-10 manage-icon-arrow"><Icon name="arrowDown" /></i>
                </div>
                {this.state.openDropdown && this.state.locations && this.state.locations.length ? 
                    <ul ref={this.setWrapperRef} className={`dropdown-menu height-200 dropdown-menu-manage overflow-y-scrollbar show ${this.props.page === "managers" || this.props.page === "bulkUpdateSelectAccountModal" ? "w-100" : "width-200"} ${this.state.openDropdown ? "show" : ""}` } >
                        <li className=" p-0 d-flex align-items-center position-relative">
                            <button type="button" className={"btn btn-icon btn-sm position-absolute d-flex align-items-center pr-15 top-minus-1 left-0"}>
                                <Icon name="search" />
                            </button>
                            <input
                                className={"form-control border-0 left-space"}
                                type="text"
                                placeholder="Search.."
                                onChange={(e) => this.setState({ searchKey: e.target.value })}
                            />
                        </li>
                        <li className="px-15 py-5">
                            {this.state.locations && this.state.locations.length ?
                                <div className="form-group mb-0">
                                    <div className="form-check">
                                        <label>
                                            <input
                                                type="checkbox"
                                                className=""
                                                checked={this.state.checkedAll}
                                                // checked={this.state.checkedItems.length === this.state.locations.length}
                                                onChange={(event) => { this.selectedItem(event.target.checked, "checkAll", null) }}
                                            />
                                            <span className="checkbox-icon"></span>
                                            <span className="pl-10">
                                                {this.state.locations && this.state.locations.length ? `All ${this.state.locations.length} Locations` : "No Locations"}
                                                {/* {this.getSelectedNames()} */}
                                            </span>
                                        </label>
                                    </div>
                                </div> : null}
                        </li>
                        {this.state.locations && this.state.locations.length ? this.listitems() : null}
                    </ul> : null}
            </div>

        )
    }
}

export default LocationFilterUniversal