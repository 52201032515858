// props
// templates: predefined templates
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { history } from '@app/store'
import { checkPermissionWOproduct } from '@helpers/utility'
import notification from '@components/notification'

// let selected = "";
let startPosition = 0

const mapDispatchToProps = ({ auth }) => {
  return {
    ...auth
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    ...auth
  }
}
class Predefined extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedTemplate: ''
    }
  }
  createPreDefinedTemplates () {
    if (checkPermissionWOproduct('Customer.Templates.List', this.props.me)) {
      this.props.setMyAccountActiveTab('generalSettings')
      history.push(`/${this.props.user.user_unique_id}/account-settings`)
    } else {
      notification('error', 'The feature is not available in this account')
    }
  }
  // componentDidMount() {
  //     this.carouselMouseWheel();
  // }
  // componentDidUpdate(){
  //     this.carouselMouseWheel();
  // }
  carouselMouseWheel () {
    var owlStage = document.querySelector('#owlCarousel .owl-stage')
    var scrollStartNext = true
    var scrollStartPrev = true
    owlStage.addEventListener('wheel', function (e) {
      if (e.deltaY > 0) {
        var next = document.querySelector('#owlCarousel .owl-next')
        if (scrollStartNext) {
          next.click()
          scrollStartNext = false
        }
      } else {
        var prev = document.querySelector('#owlCarousel .owl-prev')
        if (scrollStartPrev) {
          prev.click()
          scrollStartPrev = false
        }
      }
      setTimeout(function () {
        scrollStartNext = true
        scrollStartPrev = true
      }, 500)
      e.preventDefault()
    })
  }
  selectPredefinedTemplate (selectedItem) {
    this.setState({ selectedTemplate: selectedItem._id }, () =>
      this.props.selectTemplateFn(selectedItem, this.props.setFieldValue)
    )
  }
  updateCarouselPosition (obj) {
    if (obj && obj.item && obj.item && obj.item.index !== startPosition) startPosition = obj.item.index
  }
  render () {
    const { templates } = this.props
    return (
      <div className="predefined-templates" id="predefinedTemplates">
        <OwlCarousel
          id="owlCarousel"
          className="owl-theme"
          data-test="predefined-message-template"
          loop={false}
          margin={0}
          nav={true}
          dots={false}
          autoWidth={true}
          responsiveClass={true}
          smartSpeed={500}
          stagePadding={0}
          startPosition={startPosition}
          onDragged={(object) => this.updateCarouselPosition(object)}>
          {templates && templates.length ? (
            templates.map((item) => (
              <li
                className={`pb-0 ${
                  this.state.selectedTemplate === item._id && !this.props.clearTemplate ? 'list-active' : ''
                }`}
                key={item._id}
                onClick={this.selectPredefinedTemplate.bind(this, item)}>
                <label className="mb-0">{item.template_title}</label>
              </li>
            ))
          ) : (
            <Fragment>
              {!this.props.fromEnterprise ? (
                <li onClick={this.createPreDefinedTemplates.bind(this)}>
                  <label className="btn-add-Predefined-template d-flex align-items-center mb-0">
                    <strong className="icon-add-predefined mr-5">+</strong>
                    CREATE PREDEFINED TEMPLATE
                  </label>
                </li>
              ) : (
                'null'
              )}
            </Fragment>
          )}
        </OwlCarousel>
      </div>
    )
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Predefined)
