import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Link } from 'react-router-dom'
import Icons from '../icons'
import style from './index.module.scss'
import Notification from './notification'
import NotificationDropDown from './notification-dropdown'
import {
  clearCookies,
  deleteAllCookies,
  checkPermissionWOproduct,
  isCognitoUser,
  getCookieByKey,
  clearPopupAlreadyOpenedInSession
} from '@helpers/utility'
import config from '@config/index'
import { history } from '@app/store'
import { segmentEventTrack } from '@helpers/segment'
import { UpgradeButton } from './upgrade-button'
import PropTypes from 'prop-types'
import { ACCOUNTS_HOST } from '@helpers/constants'
import { featureFlags, getFeatureVal } from '@helpers/configcat'
import { withTranslation } from 'react-i18next'

const mapDispatchToProps = ({ auth, accountsettings, messenger, myaccount }) => {
  return {
    ...auth,
    ...accountsettings,
    ...messenger,
    ...myaccount
  }
}

const mapStateToProps = ({ auth, accountsettings, messenger, myaccount }) => {
  return {
    ...auth,
    ...accountsettings,
    ...messenger,
    ...myaccount
  }
}

class RightHeader extends React.Component {
  t = this.props.t
  static propTypes = {
    logout: PropTypes.func.isRequired,
    clearSavedMEssages: PropTypes.func.isRequired,
    getNotificationCount: PropTypes.func.isRequired,
    messages: PropTypes.any.isRequired,
    adminOrPartner: PropTypes.array.isRequired,
    me: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    notificationCount: PropTypes.number.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      userMenuVisible: false,
      notification: false,
      errored: false,
      upgradeToProMultiLocation: false,
      admin_origin_req: false
      // backToAdmin: false
    }
  }

  async componentDidMount() {
    if (!this.props.me && this.props.user) {
      await this.props.getMe()
    }
    // if (this.props.adminUser && this.props.adminUser.length && this.props.adminUser[0].customTheme) {
    //   this.setState({
    //     backToAdmin: true
    //   })
    // }
    this.setState({ admin_origin_req: getCookieByKey('admin_origin_req') })
    const asyncMount = async () => {
      let innerMe = this.props.me
      if (!this.props.me) innerMe = await this.props.getMe()
      const flagStatus = await getFeatureVal(featureFlags.upgradeToProMultiLocation, innerMe)

      this.setState({
        upgradeToProMultiLocation: flagStatus
      })
    }
    asyncMount()
  }

  async logout() {
    const cognitoUser = await isCognitoUser()
    await this.props.logout()
    localStorage.removeItem('language')
    if (this.props.messages) {
      this.props.clearSavedMEssages()
    }
    if (this.props.adminOrPartner && this.props.adminOrPartner.length) {
      clearCookies(this.props.adminOrPartner[0].user_unique_id)
    }
    const { user_parent_site_url } = this.props.me || {}
    const isAccountsOriginReq =
      cognitoUser || user_parent_site_url.includes(ACCOUNTS_HOST) || getCookieByKey('loginProvider')
    clearPopupAlreadyOpenedInSession()
    if (isAccountsOriginReq) {
      await deleteAllCookies()
      window.open(`${config.cognitoUrl}?logout=true`, '_parent')
    } else {
      history.push('/login')
      window.location.reload()
    }
  }

  handleNotificationDropDown() {
    if (!this.state.notification) {
      const data = {
        event: 'notification_click',
        properties: {
          user_id: this.props.me && this.props.me._id,
          role:
            this.props.me &&
            this.props.me.role_id &&
            this.props.me.role_id.role_name &&
            this.props.me.role_id.role_name &&
            this.props.me.role_id.role_name === 'trial'
              ? 'free-trial'
              : this.props.me.role_id.role_name // “free_trial”, “retail”, “enterprise”
        }
      }
      segmentEventTrack(data)
    }
    this.setState({ notification: !this.state.notification })
  }

  closeNotificationDropDown() {
    this.setState({ notification: false })
  }
  async handleBackToAdmin() {
    const adminUserId = getCookieByKey('admin_uuid')
    if (adminUserId) {
      window.location.href = config.redirection_url + `/${adminUserId}`
    } else {
      window.location.href = config.redirection_url
    }
  }

  async handleBackToDashboard() {
    const data = this.props.adminOrPartner[0]
    console.log('data =======', JSON.stringify(data))
    clearPopupAlreadyOpenedInSession()
    if (data && data.token) {
      await this.props.logout()
      // todo : close websocket connection
      // clearCookies(data.user_unique_id)
      // login as admin
      let qs = '?t=' + data.token + '&s=' + data.status + '&r=' + data.role + '&uuid=' + data.user_unique_id
      if (data.isCognito === 'true') {
        qs += '&isCognito=true&isAwsCognitoUser=true'
      }
      if (data.isMultiLocation === true) {
        qs += '&isMultiLocation=true'
      }
      if (data.isMultiLocationPartner === true) {
        qs += '&isMultiLocationPartner=true'
      }
      if (data.whiteListPermission === true) {
        qs += '&whiteListPermission=true'
      }
      // this won't work for enterprise user. use platform url instead
      let v1Domain = config.redirection_url

      if (
        this.props.user &&
        this.props.user.site &&
        data.role !== 'superadmin' &&
        data.role !== 'admin' &&
        process.env.REACT_APP_ENV === 'production'
      ) {
        if (data.isMultiLocation) {
          v1Domain = window.location.origin
        } else {
          v1Domain = this.props.user.site
        }
      } else if (
        this.props.user &&
        this.props.user.site &&
        data.role !== 'superadmin' &&
        data.role !== 'admin' &&
        process.env.REACT_APP_ENV !== 'production'
      ) {
        v1Domain = window.location.origin
      }

      const url = v1Domain + '/' + data.user_unique_id + qs
      // console.log('url => ', url)
      window.open(url, '_parent')
    }
  }

  addDefaultSrc(ev) {
    if (!this.state.errored) {
      ev.target.src = 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'
      this.setState({ errored: true })
    }
  }

  openChatBox() {
    if (window && window.Intercom) {
      window.Intercom('show')
    }
  }

  getUserName(me) {
    let name
    if (me) {
      if (me.first_name) {
        name = me.first_name
        if (me.last_name) {
          name = name + ' ' + me.last_name
        }
      } else if (me.last_name) {
        name = me.last_name
      } else {
        name = me.user_email
      }
    } else {
      name = ''
    }
    return name
  }

  clickHelpLink(linkName, evt) {
    const eventName = linkName ? `Help_Button_${linkName}` : 'Help_button_clicked'
    const data = {
      event: eventName,
      properties: {
        user_id: this.props.me && this.props.me._id,
        role:
          this.props.me &&
          this.props.me.role_id &&
          this.props.me.role_id.role_name &&
          this.props.me.role_id.role_name &&
          this.props.me.role_id.role_name === 'trial'
            ? 'free-trial'
            : this.props.me.role_id.role_name // “free_trial”, “retail”, “enterprise”
      }
    }
    segmentEventTrack(data)
    switch (linkName) {
      case 'Chat':
        this.openChatBox()
        break
      case 'Email':
        window.open('mailto:help@gosite.com', '_blank')
        break
      case 'HelpCenter':
        window.open('https://help.gosite.com/en/', '_blank')
        break
      default:
        break
    }
    evt.preventDefault()
  }

  render() {
    const t = this.t
    const { me, adminOrPartner, isReadOnlyRole } = this.props
    const { admin_origin_req } = this.state
    const showBackToAdminBtn =
      me.isMultiLocation &&
      admin_origin_req &&
      me &&
      me.role_id &&
      me.role_id.role_name &&
      me.role_id.role_name === 'partner'
    return (
      <div className={`right-block d-flex align-items-center ml-auto  ${style['right-header-wrap']} `}>
        {(adminOrPartner &&
          adminOrPartner.length &&
          me &&
          me.role_id &&
          me.role_id.role_name &&
          me.role_id.role_name !== 'partner' &&
          me.role_id.role_name !== 'manager') ||
        // this.state.backToAdmin ||
        this.props.user.businessProfile ? (
          <div className="d-inline-block mr-15">
            <button
              id="backToDashboard"
              onClick={this.handleBackToDashboard.bind(this)}
              className={`btn btn-outline text-secondary d-flex align-items-center px-5 px-md-15 ${style['btn-back-to-admin']}`}
              type="button">
              <i className={'btn-icon mr-lg-5 '}>
                <Icons name="narrowLeftArrow" />
              </i>
              <span className="d-none d-lg-inline-block">DASHBOARD</span>
            </button>
          </div>
        ) : null}
        {showBackToAdminBtn ? (
          <div className="d-inline-block mr-15">
            <button
              id="backToAdmin"
              onClick={this.handleBackToAdmin.bind(this)}
              className={`btn btn-outline text-secondary d-flex align-items-center px-5 px-md-15 ${style['btn-back-to-admin']}`}
              type="button">
              <i className={'btn-icon mr-lg-5 '}>
                <Icons name="narrowLeftArrow" />
              </i>
              <span className="d-none d-lg-inline-block">{t('common.back-to-admin')}</span>
            </button>
          </div>
        ) : null}
        <div className="d-flex">
          <div className="d-none d-sm-flex">
            {this.props.subscriptionWarningFunc(this.state.upgradeToProMultiLocation)}
          </div>
          {this.props.showUpgradeTrial && (
            <UpgradeButton
              className="ml-5"
              uuid={this.props.user && this.props.user.user_unique_id}
              isMultiLocation={this.props.user.isMultiLocation}
              me={this.props.me}
            />
          )}
        </div>
        <div className="b-notification d-inline-block mx-10">
          <div className="dropdown select-tag b-manage-dropdown">
            <button
              data-test="btn_need_help"
              id="needHelpButton"
              className="font-weight-bold btn btn-sm border btn-outline dropdown-toggle-manage pl-10 pr-10 d-flex align-items-center"
              data-toggle="dropdown"
              onClick={(evt) => this.clickHelpLink(null, evt)}>
              {t('common.need-help')}
            </button>
            <ul id="manageList" className="dropdown-menu dropdown-menu-manage dropdown-menu-right">
              <li
                className="px-15 py-5 manage-list-item"
                id="btn_chat_with_us"
                onClick={(evt) => this.clickHelpLink('Chat', evt)}>
                {t('common.chat-with-us')}
              </li>
              <li
                className="px-15 py-5 manage-list-item"
                id="btn_email_support"
                onClick={(evt) => this.clickHelpLink('Email', evt)}>
                {t('common.email-support')}
              </li>
              <li
                className="px-15 py-5 manage-list-item"
                id="btn_help_center"
                onClick={(evt) => this.clickHelpLink('HelpCenter', evt)}>
                {t('common.knowledge-base')}
              </li>
            </ul>
          </div>
        </div>
        {/* {this.props.user.role !== "trial" &&  */}
        <div className={`dropdown b-notification d-inline-block ${style['b-custom-notification']}`}>
          <NotificationDropDown handleClickOutside={this.closeNotificationDropDown.bind(this)}>
            <button
              id="notificationDropdown"
              onClick={this.handleNotificationDropDown.bind(this)}
              className="btn dropdown-toggle button p-0 border-0"
              type="button">
              <i className={`btn-icon position-relative ${style['icon-notification']}`}>
                <Icons name="bell" />
                {this.props.notificationCount && this.props.notificationCount > 0 ? (
                  <span className={`position-absolute rounded-circle bounce ${style['animation-ball']}`}></span>
                ) : null}
              </i>
            </button>
            {this.state.notification ? (
              <Notification
                show={this.state.notification}
                close={this.closeNotificationDropDown.bind(this)}
                getNotificationCount={this.props.getNotificationCount}
              />
            ) : null}
          </NotificationDropDown>
        </div>
        {/* } */}
        <div className={`dropdown open b-profile d-inline-block ml-20 ${style['b-dropdown']}`}>
          <button
            id="profileDropdown"
            className="btn dropdown-toggle button p-0 border-0"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <img
              className={`image rounded-circle ${style['profile-img-tag']}`}
              onError={this.addDefaultSrc.bind(this)}
              src={
                me && me.profile_picture
                  ? me.profile_picture
                  : 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'
              }
              alt=""
            />
          </button>
          <div className="dropdown-menu dropdown dropdown-menu-right border-0 shadow-sm rounded-lg">
            <h4 className="dropdown-header dropdown-menu-item text-secondary" href="#!">
              {me && this.getUserName(me)}
            </h4>
            <div className="dropdown-divider"></div>

            {/** For Multilocation */}
            {this.props.user && this.props.user.isMultiLocation ? (
              <>
                {/** My Account when total account equal to 1, show SMB 'My Account' */}
                {this.props.isSMBDashboardForMultiLocation ? (
                  <Link
                    id="navMyAccount"
                    className={`dropdown-item dropdown-menu-item py-0 px-15 ${style['dropdown-item']}`}
                    to={`/${this.props.user.user_unique_id}/account-settings`}>
                    <i className={`btn-icon mr-10 ${style['icon-dropdown-item']}`}>
                      <Icons name="user" />
                    </i>
                    {t('common.my-account')}
                  </Link>
                ) : this.props.isEnterpriseDashboardForMultiLocation ? (
                  /** My Account when total account is more than 1, show Enterprise 'My Account' */
                  <Link
                    id="navMyAccount"
                    className={`dropdown-item dropdown-menu-item py-0 px-15 ${style['dropdown-item']}`}
                    to={`/${this.props.user.user_unique_id}/myaccount`}>
                    <i className={`btn-icon mr-10 ${style['icon-dropdown-item']}`}>
                      <Icons name="user" />
                    </i>
                    {t('common.my-account')}
                  </Link>
                ) : null}
              </>
            ) : (
              <>
                {/** My Account for role not equal to partner or manager */}
                {this.props.user &&
                this.props.user.user_unique_id &&
                this.props.user.role !== 'partner' &&
                this.props.user.role !== 'manager' &&
                !this.props.user.businessProfile ? (
                  <Link
                    id="navMyAccount"
                    className={`dropdown-item dropdown-menu-item py-0 px-15 ${style['dropdown-item']}`}
                    to={`/${this.props.user.user_unique_id}/account-settings`}>
                    <i className={`btn-icon mr-10 ${style['icon-dropdown-item']}`}>
                      <Icons name="user" />
                    </i>
                    {t('common.my-account')}
                  </Link>
                ) : null}

                {/** My Account for role equal to partner or manager */}
                {this.props.user &&
                this.props.user.user_unique_id &&
                (this.props.user.role === 'partner' || this.props.user.role === 'manager') ? (
                  <Link
                    id="navMyAccount"
                    className={`dropdown-item dropdown-menu-item py-0 px-15 ${style['dropdown-item']}`}
                    to={`/${this.props.user.user_unique_id}/myaccount`}>
                    <i className={`btn-icon mr-10 ${style['icon-dropdown-item']}`}>
                      <Icons name="user" />
                    </i>
                    {t('common.my-account')}
                  </Link>
                ) : null}
              </>
            )}

            {this.props.user && this.props.user.isMultiLocation ? (
              <>
                {this.props.isSMBDashboardForMultiLocation ? (
                  <>
                    <Link
                      id="navBusinessSettings"
                      className={`dropdown-item dropdown-menu-item py-0 px-15 ${style['dropdown-item']}`}
                      to={`/${this.props.user.user_unique_id}/business-settings`}>
                      <i className={`btn-icon mr-10 ${style['icon-dropdown-item']}`}>
                        <Icons name="shop" />
                      </i>{' '}
                      {t('common.manage-my-business')}
                    </Link>
                    <Link
                      className={`dropdown-item dropdown-menu-item py-0 px-15 ${style['dropdown-item']}`}
                      id="services"
                      to={`/${this.props.user.user_unique_id}/services`}>
                      <i className={`btn-icon mr-10 ${style['icon-dropdown-item']}`}>
                        <Icons name="suite" />
                      </i>{' '}
                      {t('common.services')}
                    </Link>
                  </>
                ) : null}
              </>
            ) : (
              <>
                {this.props.user &&
                this.props.user.user_unique_id &&
                this.props.user.role &&
                (this.props.user.role === 'customer' ||
                  this.props.user.role === 'trial' ||
                  this.props.user.role === 'staff' ||
                  isReadOnlyRole) &&
                checkPermissionWOproduct('Custom.Business.Hide', this.props.me) === false ? (
                  <Link
                    id="navBusinessSettings"
                    className={`dropdown-item dropdown-menu-item py-0 px-15 ${style['dropdown-item']}`}
                    to={`/${this.props.user.user_unique_id}/business-settings`}>
                    <i className={`btn-icon mr-10 ${style['icon-dropdown-item']}`}>
                      <Icons name="shop" />
                    </i>{' '}
                    {t('common.manage-my-business')}
                  </Link>
                ) : null}

                {this.props.user &&
                this.props.user.user_unique_id &&
                this.props.user.role &&
                (this.props.user.role === 'customer' ||
                  this.props.user.role === 'trial' ||
                  this.props.user.role === 'staff' ||
                  isReadOnlyRole) ? (
                  <Link
                    className={`dropdown-item dropdown-menu-item py-0 px-15 ${style['dropdown-item']}`}
                    id="services"
                    to={`/${this.props.user.user_unique_id}/services`}>
                    <i className={`btn-icon mr-10 ${style['icon-dropdown-item']}`}>
                      <Icons name="suite" />
                    </i>{' '}
                    {t('common.services')}
                  </Link>
                ) : null}
              </>
            )}

            <button
              onClick={this.logout.bind(this)}
              className={`dropdown-item dropdown-menu-item py-0 px-15 ${style['dropdown-item']}`}
              id="logout">
              <i className={`btn-icon mr-10 ${style['icon-dropdown-item']} ${style['icon-logout']}`}>
                <Icons name="export" />
              </i>
              {t('common.log-out')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(compose(connect(mapStateToProps, mapDispatchToProps))(RightHeader))
