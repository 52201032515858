import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import { unregister } from './registerServiceWorker'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { GoogleOAuthProvider } from '@react-oauth/google'
import config from '@config/index'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import ReactHighcharts from 'react-highcharts/ReactHighcharts.src'
import { graphLangEn, graphLangEs } from './app/helpers/graph'

const localLang = localStorage.getItem('language') || 'en-us'
if (localLang) {
  i18n.changeLanguage(localLang.split('-')[0])
  if (localLang.includes('en')) {
    ReactHighcharts.Highcharts.setOptions({
      lang: {
        ...graphLangEn.lang
      }
    })
  } else {
    ReactHighcharts.Highcharts.setOptions({
      lang: {
        ...graphLangEs.lang
      }
    })
  }
}

process.env.REACT_APP_ENV === 'production' &&
  Sentry.init({
    dsn: 'https://61d143ffafdd489491bbeb658968287b@o229405.ingest.sentry.io/4504340924792832',
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    environment: window.location.hostname,
    tracesSampleRate: 1.0,
    sampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.3
  })

ReactDOM.render(
  <GoogleOAuthProvider clientId={config.google_login_key}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </GoogleOAuthProvider>,
  document.getElementById('root')
)
unregister()
