import { formatUSDFromCent } from '@helpers/utility'
import { titleCaseEachWord } from '@helpers/index'

export const formatWisetackStatus = ({ status, amountInCents }) => {
  const formatAmount = amountInCents ? ` for ${formatUSDFromCent(amountInCents)}` : ''

  const statusMap = {
    INITIATED: 'Pending',
    PENDING: 'Pending',
    'ACTIONS REQUIRED': 'Action Required',
    AUTHORIZED: `Approved${formatAmount}`,
    'LOAN TERMS ACCEPTED': `Offer Locked${formatAmount}`,
    'LOAN CONFIRMED': `Loan Confirmed${formatAmount}`,
    SETTLED: `Paid${formatAmount}`,
    DECLINED: 'Declined',
    EXPIRED: 'Expired',
    CANCELED: 'Canceled',
    REFUNDED: 'Refunded'
  }

  return statusMap[status] || titleCaseEachWord(status)
}

export const formatWisetackSignUpStatus = ({ status, variant }) => {
  if (status === 'NOT_STARTED') {
    const variantStatusTextMap = {
      WisetackBaseSignUpButton: 'SIGN UP NOW',
      IntegrationCTAButton: 'SIGN UP FOR FINANCING'
    }

    return variantStatusTextMap[variant] || 'Sign Up Now'
  }

  const statusMap = {
    APPLICATION_SUBMITTED: 'Application Submitted',
    APPLICATION_APPROVED: 'Approved',
    APPLICATION_DECLINED: 'Declined'
  }

  return statusMap[status] || 'Continue Application'
}

export const WISETACK_RAW_STATUS = {
  APPLICATION_APPROVED: 'APPLICATION_APPROVED',
  APPLICATION_SUBMITTED: 'APPLICATION_SUBMITTED',
  APPLICATION_DECLINED: 'APPLICATION_DECLINED'
}
