import * as React from 'react'
import { Field } from 'formik'
import './contactInfoWidget.scss'
import Icon from '../icons'
import Tooltip from '../tooltip'

interface ContactInfoWidgetProps {
  name: string
  email: string
  mobile: string
  avatar?: string
  onDataChange: (data: { field: string; value: string }) => void
  clear: (field: string) => void
  resetEmail?: boolean
  resetMobile?: boolean
  isEditable?: boolean
}

export default function ContactInfoWidget({
  name,
  email,
  mobile,
  avatar,
  resetEmail,
  resetMobile,
  isEditable,
  onDataChange,
  clear
}: ContactInfoWidgetProps) {
  return (
    <div className="user-contact-info border rounded-sm">
      {/* IF DEATILS EDITABLE */}
      {isEditable ? (
        <>
          <div className="row-user-contact border-bottom d-flex align-items-center position-relative">
            <div className="user-contact pl-15">Contact</div>
            <figure className={`mb-0 ml-30 contact-avatar-wrapper`}>
              <img
                className={`w-100 h-100 rounded-circle contact-avatar-image`}
                src={avatar ? avatar : 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'}
                alt=""
              />
            </figure>
            <div className="user-email-value pr-20 d-flex justify-content-between">
              <Field
                type="text"
                name="name"
                className="form-control form-control-lg"
                placeholder={'Name'}
                value={name}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onDataChange({ field: 'name', value: e.target.value })
                }
              />
            </div>
            {name ? (
              <div className="position-absolute cursor-pointer right-0 mr-15 icon-block">
                <Icon name="closeThin" onClick={() => clear('name')} className="stroke-icon d-block" />
              </div>
            ) : null}
          </div>
          <div className="row-user-email border-bottom d-flex align-items-center position-relative">
            <div className="user-email pl-15">Email</div>
            <div className="user-email-value pr-20">
              <Field
                type="text"
                name="email"
                className="form-control form-control-lg"
                placeholder={mobile ? '(Optional)' : 'Enter email'}
                value={email}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onDataChange({ field: 'email', value: e.target.value })
                }
              />
            </div>
            {resetEmail ? (
              <div className=" position-absolute cursor-pointer right-0 mr-15" onClick={() => clear('email')}>
                <Tooltip type="button" position="left" message="Revert to email on file">
                  <Icon name="undoThin" className={'undo'} />
                </Tooltip>
              </div>
            ) : null}
          </div>
          <div className="row-user-phone  d-flex align-items-center position-relative">
            <div className="user-phone pl-15">Phone</div>
            <div className="user-phone-value pr-20">
              <Field
                type="text"
                name="mobile"
                className="form-control form-control-lg"
                placeholder={email ? '(Optional)' : 'Enter mobile'}
                value={mobile}
                maxLength={14}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onDataChange({ field: 'mobile', value: e.target.value })
                }
              />
            </div>
            {resetMobile ? (
              <div className="position-absolute cursor-pointer right-0 mr-15" onClick={() => clear('mobile')}>
                <Tooltip type="button" position="left" message="Revert to phone on file">
                  <Icon name="undoThin" className={'undo'} />
                </Tooltip>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      {/* IF NOT EDITABLE */}
      {!isEditable ? (
        <>
          {name ? (
            <div className="row-user-contact border-bottom d-flex align-items-center position-relative">
              <div className="user-contact pl-15">Contact</div>
              <figure className={`mb-0 mr-10 ml-30 contact-avatar-wrapper`}>
                <img
                  className={`w-100 h-100 rounded-circle contact-avatar-image`}
                  src={avatar ? avatar : 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'}
                  alt=""
                />
              </figure>
              <div className="user-email-value pr-20">{name && <span>{name}</span>}</div>
            </div>
          ) : null}
          {email ? (
            <div className="row-user-email border-bottom d-flex align-items-center position-relative">
              <div className="user-email pl-15">Email</div>
              <div className="user-email-value pr-20">
                <div className="user-email-value pr-20">{email && <span>{email}</span>}</div>
              </div>
            </div>
          ) : null}
          {mobile ? (
            <div className="row-user-phone  d-flex align-items-center position-relative">
              <div className="user-phone pl-15">Phone</div>
              <div className="user-phone-value pr-20">
                <div className="user-email-value pr-20">{mobile && <span>{mobile}</span>}</div>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  )
}
