import notification from '@components/notification'
import * as service from './service'
import { capitalize } from '@helpers/index'
import { onImageUpload } from '@containers/media-library/service'

function mappingArrayFunction (obj1, obj2) {
  const temp1 = obj1.docs
  const temp2 = obj2.docs
  temp2.forEach((item, index) => {
    temp2[index] = temp1[index] ? { ...temp1[index], ...item } : { ...item }
  })
  const newObj = JSON.parse(JSON.stringify(obj2))
  newObj.docs = temp2
  return newObj
}

export default {
  state: {
    messageCount: 0,
    ws: '',
    messages: ''
  },
  reducers: {
    onRequest (state) {
      return {
        ...state,
        loading: true,
        message: {},
        msgApiError: false
      }
    },
    onError (state, data) {
      const err =
        data && data.data && data.data.status && data.data.status.messageDescription
          ? data.data.status.messageDescription
          : data && data.status && data.status.messageDescription
            ? data.status.messageDescription
            : data && data.message
              ? data.message
              : data && data.data && data.data.message
                ? data.data.message
                : data && data.error
                  ? data.error
                  : data && data.data && data.data.error
                    ? data.data.error
                    : 'Please try again later'
      notification('error', capitalize(err))
      return {
        ...state,
        loading: false,
        msgApiError: true
      }
    },
    onListMessengersSuccess (state, data) {
      const leads = data && data.data
      let { docs = [] } = leads
      docs = docs.filter((item) => !(item.includeDemo && !item.last_message))
      leads.docs = [...docs]
      return {
        ...state,
        loading: false,
        messages: leads && state.messages ? mappingArrayFunction(state.messages, leads) : leads
      }
    },
    onListUnreadMessagesSuccess (state, data) {
      return {
        ...state,
        loading: false,
        unreadMessages: data
      }
    },
    onGetAssignedMessagesSuccess (state, data) {
      return {
        ...state,
        loading: false,
        assignedMessages: data
      }
    },
    onGetLeadMessagesSuccess (state, data) {
      return {
        ...state,
        loading: false,
        leadMessages: data
      }
    },
    onListCompanyUsersSuccess (state, data) {
      console.log('data from api===>', data)
      return {
        ...state,
        loading: false,
        companyUsers: data
      }
    },
    onLeadsReadAtSuccess (state, data) {
      return {
        ...state,
        loading: false,
        leadReadAt: data
      }
    },
    onSegmentTrackSuccess (state, data) {
      return {
        ...state,
        loading: false,
        trackSegment: data
      }
    },
    onLastAttendedSuccess (state, data) {
      return {
        ...state,
        loading: false,
        lastAttended: data
      }
    },
    onCreateMessageSuccess (state, data) {
      return {
        ...state,
        loading: false,
        message: data && data.data
      }
    },
    onCreateNewChatMessage (state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onCreateNoteSuccess (state, data) {
      return {
        ...state,
        loading: false,
        note: data
      }
    },
    onAssignStaffSuccess (state, data) {
      notification('success', 'Conversation assigned successfully')
      return {
        ...state,
        loading: false,
        assignStaff: data
      }
    },
    onListNoteSuccess (state, data) {
      return {
        ...state,
        loading: false,
        notes: data
      }
    },
    onListSegmentTrackSuccess (state, data) {
      return {
        ...state,
        loading: false,
        trackSegments: data
      }
    },
    onChatBulkSearchSuccess (state, data) {
      return {
        ...state,
        loading: false,
        messengerSearchResults: data
      }
    },
    onReadAllMessagesSuccess (state, data) {
      return {
        ...state,
        loading: false,
        readAll: data
      }
    },
    onMessengerMessagesSuccess (state, data) {
      return {
        ...state,
        loading: false,
        messegerMesages: data
      }
    },
    ononFavChangeSuccess (state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onUploadMediaSuccess (state, data) {
      let mediaName = ''
      if (data && data.length && data[0].fileUrl) {
        mediaName = data[0].fileUrl
      }
      return {
        ...state,
        loading: false,
        media_name: mediaName
      }
    },
    onStatusChangeSuccess (state, data) {
      notification('success', 'Conversation deleted successfully')
      return {
        ...state,
        loading: false
      }
    },
    onGetAssignedDetailsSuccess (state, data) {
      return {
        ...state,
        loading: false,
        threadAssigned: data && data.data
      }
    },
    onBlockUnblockContactSuccess (state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onUpdateMessageLayoutCountSuccess (state, data) {
      let Count = ''
      if (data) {
        Count = state.messageCount + 1
      } else {
        Count = 0
      }
      return {
        ...state,
        messageCount: Count,
        loading: false
      }
    },
    onUpdateWebsocketSuccess (state, data) {
      return {
        ...state,
        ws: data,
        loading: false
      }
    },
    onUpdateLastAttendedSuccess (state, data) {
      return {
        ...state,
        loading: false
      }
    },
    updateThreadListsManualUpdate (state, data) {
      return {
        ...state,
        messages: data,
        loading: false
      }
    },
    updateOnlineUsersSuccess (state, data) {
      return {
        ...state,
        onlineUsers: data,
        loading: false
      }
    },
    fetchNewUserDetailSuccess (state, data) {
      return {
        ...state,
        loading: false,
        newLeadDetails: data.data
      }
    },
    assginStatusToMessageSuccess (state, data) {
      const oldmessages = { ...state.messegerMesages }
      oldmessages.docs.unshift(data)
      return {
        ...state,
        loading: false,
        messegerMesages: oldmessages
      }
    },
    updateWebSocketMessagesSuccess (state, data) {
      return {
        ...state,
        loading: false,
        webSocketMessages: data
      }
    },
    updateMessageTypeChatSuccess (state, data) {
      return {
        ...state,
        loading: false,
        isMessageTypeChat: data
      }
    },
    addMessagesGlobalHistory (state, data) {
      const messages = []
      data.Items &&
        data.Items.length &&
        data.Items.forEach((item) => {
          messages.push(item)
        })
      return {
        ...state,
        loading: false,
        historyMessages: messages
      }
    },
    sendEmailMessageSuccess (state, data) {
      return {
        ...state,
        loading: false
      }
    },
    updateUserTypingStatus (state, data) {
      let typingStatus = false
      let typingUserId = ''
      if (data.type === 'typingStarted') {
        typingStatus = true
        typingUserId = data.data && data.data.payload && data.data.payload.userId
      } else {
        typingStatus = false
        typingUserId = ''
      }
      return {
        ...state,
        loading: false,
        typingUserId: typingUserId,
        typingStatus: typingStatus
      }
    },
    messengerContactSuccess (state, data) {
      return {
        ...state,
        loading: false,
        messengerContactsData: data.data
      }
    },
    clearSavedMEssagesStatus (state, data) {
      return {
        ...state,
        messages: ''
      }
    },
    onGetContactList (state, data) {
      return {
        ...state,
        loading: false,
        contactsReceived: data
      }
    },
    onComposeSmsSuccess (state, data) {
      console.log(data, 'data consoleee')
      // SegmentTrack("message-sent",1,false,true,data && data.data && data.data.messenger)
      return {
        ...state,
        loading: false,
        composeResult: data && data.data
      }
    },
    handleWSPayloadSuccess (state, data) {
      return {
        ...state,
        loading: false,
        handlePayload: data
      }
    },
    whitelistHostnames (state, data) {
      return {
        ...state,
        loading: false,
        allWhitelistHostnames: data
      }
    }
  },
  effects: {
    async listMessengers (payload) {
      this.onRequest()
      try {
        const res = await service.listMessengers(payload)
        this.onListMessengersSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getUnreadMessages (payload) {
      this.onRequest()
      try {
        const res = await service.getUnreadMessages(payload)
        this.onListUnreadMessagesSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getAssignedMessages (payload) {
      this.onRequest()
      try {
        const res = await service.getAssignedMessages(payload)
        this.onGetAssignedMessagesSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getLeadMessages (payload) {
      this.onRequest()
      try {
        const res = await service.getLeadMessages(payload)
        this.onGetLeadMessagesSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async messengerMessages (payload) {
      this.onRequest()
      try {
        const res = await service.messengerMessages(payload)
        this.onMessengerMessagesSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async listCompanyUsers (payload) {
      this.onRequest()
      try {
        const res = await service.listCompanyUsers(payload)
        this.onListCompanyUsersSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async leadsReadAt (payload) {
      this.onRequest()
      try {
        const res = await service.leadsReadAt(payload)
        this.onLeadsReadAtSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async segmentTrack (payload) {
      this.onRequest()
      try {
        const res = await service.segmentTrack(payload)
        this.onSegmentTrackSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async lastAttended (payload) {
      this.onRequest()
      try {
        const res = await service.lastAttended(payload)
        this.onLastAttendedSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async createMessage (payload) {
      this.onRequest()
      try {
        const res = await service.createMessage(payload)
        this.onCreateMessageSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async createMessageThumbtack (payload) {
      this.onRequest()
      try {
        const res = await service.createMessageThumbtack(payload)
        this.onCreateMessageSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async isThumbtackOauthConnected (payload) {
      this.onRequest()
      try {
        const res = await service.isThumbtackOauthConnected(payload)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async createNewChatMessage (payload) {
      this.onRequest()
      try {
        const res = await service.createNewChatMessage(payload)
        this.onCreateNewChatMessage(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async createNote (payload) {
      this.onRequest()
      try {
        const res = await service.createNote(payload)
        this.onCreateNoteSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async assignStaffs (payload) {
      this.onRequest()
      try {
        const res = await service.assignStaffs(payload)
        this.onAssignStaffSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async listNote (payload) {
      this.onRequest()
      try {
        const res = await service.listNote(payload)
        this.onListNoteSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async listSegmentTrack (payload) {
      this.onRequest()
      try {
        const res = await service.listSegmentTrack(payload)
        this.onListSegmentTrackSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async chatBulkSearch (payload) {
      this.onRequest()
      try {
        const res = await service.chatBulkSearch(payload)
        this.onChatBulkSearchSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async readAllMessages (payload) {
      this.onRequest()
      try {
        const res = await service.readAllMessages(payload)
        this.onReadAllMessagesSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async onFavChange (payload) {
      this.onRequest()
      try {
        const res = await service.onFavChange(payload)
        this.ononFavChangeSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async uploadMedia (payload, rootState, path) {
      this.onRequest()
      try {
        const res = await onImageUpload(payload, path)
        this.onUploadMediaSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async onStatusChange (payload) {
      this.onRequest()
      try {
        const res = await service.onStatusChange(payload)
        this.onStatusChangeSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getAssignedDetails (payload) {
      this.onRequest()
      try {
        const res = await service.getAssignedDetails(payload)
        this.onGetAssignedDetailsSuccess(res)
        return res
      } catch (e) {
        console.log('e', e)
      }
    },
    async blockUnblockContact (payload) {
      this.onRequest()
      try {
        const res = await service.blockUnblockContact(payload)
        this.onBlockUnblockContactSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateMessageLayoutCount (payload) {
      try {
        this.onUpdateMessageLayoutCountSuccess(payload)
      } catch (e) {
        this.onError(e)
      }
    },
    async updateWebsocket (payload) {
      try {
        this.onUpdateWebsocketSuccess(payload)
      } catch (e) {
        this.onError(e)
      }
    },
    async updateLastAttended (payload) {
      this.onRequest()
      try {
        const res = await service.updateLastAttended(payload)
        this.onUpdateLastAttendedSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateThreadListsManual (payload) {
      try {
        this.updateThreadListsManualUpdate(payload)
      } catch (e) {
        this.onError(e)
      }
    },
    async updateOnlineUsers (payload) {
      try {
        this.updateOnlineUsersSuccess(payload)
      } catch (e) {
        this.onError(e)
      }
    },
    async assginStatusToMessage (payload) {
      try {
        this.assginStatusToMessageSuccess(payload)
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchNewUserDetail (payload) {
      this.onRequest()
      try {
        const res = await service.getLeadMessages(payload)
        this.fetchNewUserDetailSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateWebSocketMessages (payload) {
      try {
        this.updateWebSocketMessagesSuccess(payload)
      } catch (e) {
        this.onError(e)
      }
    },
    async updateMessageTypeChat (payload) {
      try {
        this.updateMessageTypeChatSuccess(payload)
      } catch (e) {
        this.onError(e)
      }
    },
    async updateMessagelistGlobalHistory (payload) {
      try {
        this.addMessagesGlobalHistory(payload)
      } catch (e) {
        this.onError(e)
      }
    },
    async sendEmailMessage (payload) {
      this.onRequest()
      try {
        const res = await service.sendEmailMessage(payload)
        this.sendEmailMessageSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async messengerContactDetails (payload) {
      this.onRequest()
      try {
        const res = await service.messengerContact(payload)
        this.messengerContactSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateTypingStatus (payload) {
      this.onRequest()
      try {
        this.updateUserTypingStatus(payload)
      } catch (e) {
        this.onError(e)
      }
    },
    async clearSavedMEssages (payload) {
      this.onRequest()
      try {
        this.clearSavedMEssagesStatus(payload)
      } catch (e) {
        this.onError(e)
      }
    },
    async searchContactList (payload) {
      this.onRequest()
      try {
        const res = await service.getContactList(payload)
        this.onGetContactList(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async composeSms (payload) {
      this.onRequest()
      try {
        const res = await service.composeSms(payload)
        this.onComposeSmsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async setMessengerViewTime () {
      try {
        const res = await service.setMessengerViewTime()
        return res
      } catch (e) {
        // this.onError(e)
      }
    },
    async handleWSPayload (payload) {
      try {
        this.handleWSPayloadSuccess(payload)
        return payload
      } catch (e) {
        console.log(e)
      }
    },
    async messageReceivedTrack (payload) {
      this.onRequest()
      try {
        const res = await service.messageReceivedTrack(payload)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async sendBookingLink (payload, customers) {
      this.onRequest()
      try {
        const res = await service.sendBookingLink(payload)
        if (res.message) {
          notification('success', res.message)
        }
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getWhitelistHostnames () {
      this.onRequest()
      try {
        const res = await service.getWhitelistHostnames()
        this.whitelistHostnames(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    }
  }
}
