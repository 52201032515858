import React from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import Popup from './popups/basic-modal'
class ImageCropModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      url: '',
      loading: true
    }
    this.handleOk = this.handleOk.bind(this)
  }
  showPopup (Url) {
    this.setState({ open: true, url: 'https://assets.gosite.com/api/medias-get?url=' + Url })
  }
  closeModal () {
    this.setState({ open: false })
    this.props.close()
  }
  async handleOk (event, type) {
    event.preventDefault()
    const dataUrl = this.cropper.getCroppedCanvas().toDataURL()
    if (type === 'crop') {
      const blob = await this.dataURLtoBlob(dataUrl)
      var fd = new FormData()
      fd.append('file', blob)
      this.setState({ loading: true })
      await this.props.cropImage(fd, type)
      this.setState({ loading: false })
    } else {
      this.props.cropImage(this.state.url, type)
    }
    this.setState({ open: false })
  }
  async _crop () {}
  dataURLtoBlob = (dataurl) => {
    var arr = dataurl.split(',')

    var mime = arr[0].match(/:(.*?);/)[1]

    var bstr = atob(arr[1])

    var n = bstr.length

    var u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }
  waitForImageToLoad () {
    this.setState({ loading: false })
  }
  componentDidMount () {
    window.addEventListener('ready', this.waitForImageToLoad.bind(this))
  }
  componentWillUnmount () {
    window.removeEventListener('ready', this.waitForImageToLoad.bind(this))
  }

  render () {
    return (
      <Popup
        open={this.state.open}
        title={'Edit Image'}
        okText="SEND"
        onCancel={() => this.setState({ open: false })}
        type="large"
        overrideForm={true}
        id={this.props.id}
        modalBackdropClicked={this.closeModal.bind(this)}>
        {this.state.loading ? (
          <div>
            <img className="image-loader-dot" src="https://dufzo4epsnvlh.cloudfront.net/image/loader-dot.svg" alt="" />
          </div>
        ) : null}
        <div className="image-crop image-crop-wrapper">
          <button
            type="reset"
            onClick={this.closeModal.bind(this)}
            className="close custom-modal-close-button remove-modal-close">
            <span aria-hidden="true">×</span>
          </button>
          <div className={'pb-25 b-image-cropmodal'}>
            <Cropper
              ref={(ref) => (this.cropper = ref)}
              src={this.state.url}
              style={{ height: 350, width: '100%' }}
              guides={false}
              crop={this._crop.bind(this)}
            />
          </div>
          <footer className="d-block mt-10 d-sm-flex">
            <button
              type="submit"
              disabled={this.state.loading}
              className="btn btn-primary btn-xl btn-block mb-10 mb-sm-0 mr-20 btn-edit-image"
              id="btnUseOriginal"
              onClick={(e) => this.handleOk(e, 'original')}>
              {' '}
              USE ORIGINAL
            </button>
            <button
              type="submit"
              disabled={this.state.loading}
              className="btn btn-primary btn-xl btn-block mt-0 mb-10 mb-sm-0 mr-20 btn-edit-image"
              id="btnCropAndSave"
              onClick={(e) => this.handleOk(e, 'crop')}>
              {' '}
              CROP & SAVE{' '}
            </button>
            <button
              type="button"
              className="btn btn-xl cancel-btn btn-block mt-0 btn-edit-image"
              onClick={() => this.closeModal()}>
              {'CANCEL'}
            </button>
          </footer>
        </div>
      </Popup>
    )
  }
}
export default ImageCropModal
