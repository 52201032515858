// "main" environment deploys to cloud.gosite.run - used to be called "develop"
// NOTE - the "main" env is currently configured to use webapi.gosite.CLOUD for back-end requests
// this is because the main environment is meant for testing small changes which will go to production soon
// and therefore the changes need to work with a production-like back-end

// "sprint" environment deploys to cloud.gosite.build and all back-end requests route to the .build domain
// so that sprint work can be tested together - front-end and back-end changes will need to be deployed together
// during off-hours

const serverPath = {
  development: 'https://webapi.gosite.run',
  local: 'http://localhost:3500',
  main: 'https://webapi.gosite.run',
  sprint: 'https://webapi.gosite.run',
  staging: 'https://webapi.gosite.cloud',
  production: 'https://webapi.gosite.com',

  developmentV2: 'https://api.gosite.run',
  localV2: 'http://localhost:3000',
  mainV2: 'https://api.gosite.run',
  sprintV2: 'https://api.gosite.run',
  stagingV2: 'https://api.gosite.run',
  productionV2: 'https://api.gosite.com'
}

const mediaServerPath = {
  development: 'https://assets.gosite.run',
  main: 'https://assets.gosite.cloud',
  sprint: 'https://assets.gosite.cloud',
  staging: 'https://assets.gosite.cloud',
  production: 'https://assets.gosite.com'
}
// START ====> domains and builderServerPath are dependent
const domains = {
  development: '.gosite.run',
  main: '.gosite.run',
  sprint: '.gosite.build',
  staging: 'gosite.cloud',
  production: '.gosite.com'
}

const builderServerPath = {
  development: 'https://builder.gosite.build',
  // development:"http://lvh.me:4500",
  main: 'https://builder.gosite.run',
  sprint: 'https://builder.gosite.build',
  staging: 'https://builder.gosite.cloud',
  production: 'https://builder.gosite.com'
}
// END <==== domains and builderServerPath are dependent

const invoiceServerPath = {
  development: 'https://invoice.gosite.run/v1',
  // development: "http://webapi-2086828578.us-west-1.elb.amazonaws.com/v1",
  // development: "http://localhost:4000/v1",
  main: 'https://invoice.gosite.run/v1',
  sprint: 'https://invoice.gosite.run/v1',
  // sprint: "http://webapi-2086828578.us-west-1.elb.amazonaws.com/v1",
  staging: 'https://invoice.gosite.run/v1',
  production: 'https://invoices.gosite.com/v1'
}

const platformIdS = {
  development: '58c95a0fab50d231708802c9',
  main: '5fffe518d14b1c0055bda109',
  sprint: '5fffe518d14b1c0055bda109',
  staging: '58cb9d23af8be3103d7c1938',
  production: '58cb9d23af8be3103d7c1938'
}

const cognitoServerPath = {
  development: 'https://accounts.gosite.run',
  main: 'https://accounts.gosite.run',
  sprint: 'https://webapi.gosite.run',
  production: 'https://accounts.gosite.com'
}
const cognitoApiServerPath = {
  development: 'https://accountsapi.gosite.run',
  main: 'https://accountsapi.gosite.run',
  sprint: 'https://accountsapi.gosite.run',
  production: 'https://accountsapi.gosite.com'
}

const paymentServerPath = {
  development: 'https://webapi.pay.gosite.run',
  main: 'https://webapi.pay.gosite.run',
  sprint: 'https://webapi.pay.gosite.run',
  staging: 'https://webapi.pay.gosite.run',
  production: 'https://webapi.pay.gosite.com'
}

const onboardingServerPath = {
  development: 'https://webapi.onboard.gosite.run',
  main: 'https://webapi.onboard.gosite.run',
  sprint: 'https://webapi.onboard.gosite.run',
  staging: 'https://webapi.onboard.gosite.run',
  production: 'https://webapi.onboard.gosite.com'
}

const env = {
  development: {
    debug: true,
    title: 'GoSite',
    cookies: {
      name: 'gosite',
      expiry: 1,
      domain: '',
      parentDomain: '.gosite.run'
    },
    site_cutoff_date: '2022-11-02',
    google_redirect_uri: 'http://localhost:3000/login',
    incognito_redirect_url: 'http://localhost:3000/',
    google_contacts_key: '441363085080-r52j0bisnbq2q655mnh9jsi1304t5ac8.apps.googleusercontent.com',
    google_login_key: '724721037206-vj9qqt51l5r1stcpeu4nljsv6d074iu3.apps.googleusercontent.com',
    facebook_client_id: '1957056017949606',
    facebook_graph_url: 'https://graph.facebook.com/v4.0/me/',
    outlook_key: '3434be5e-0de1-4ef8-a8f0-91eda4ccd5ee',
    tinyMCEKey: 'bz8ou1t5ip0xejmvohefk7g3ptklj9vmge10ebq2trv244xg',
    cardConnectPort: '6443',
    cardConnectUrl:
      'https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html?sendcssloadedevent=true&formatinput=true&tokenizewheninactive=true&inactivityto=300&invalidinputevent=true&placeholder=Card Number&cardnumbernumericonly=true&cardinputmaxlength=19&css=.error%7Bcolor%3Ared%3B%7Dinput%7Bwidth%3A100%25%3Bheight%3A40px%3Bpadding%3A%200%2010px%3Bborder-radius%3A%204px%3Bbox-sizing%3Aborder-box%3Bcolor%3Argba%2887%2C%20108%2C%20119%2C%201%29%3Bborder%3Anone%3Bborder%3A%20solid%201px%20rgb%28224%2C%20228%2C%20232%29%3Bfont-size%3A%2013px%3Boutline%3A0%3B%7D%3A%3Aplaceholder%7Bcolor%3Argba%280%2C0%2C0%2C0.5%29%3Bfont-weight%3A400%3B%7Dbody%7Bmargin%3A0%3B%7D',
    paymentCardConnectUrl:
      'https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html?sendcssloadedevent=true&formatinput=true&tokenizewheninactive=true&inactivityto=300&invalidinputevent=true&placeholder=Enter Card Number&cardnumbernumericonly=true&cardinputmaxlength=19',
    //  redirection_url:"http://localhost:3000",
    redirection_url: 'http://lvh.me:3500',
    webApiUrl: 'http://localhost:3000',
    builderUrl: 'https://builder.gosite.run/',
    sitePreviewUrl: 'https://preview.gosite.run/',
    hbId: '4522867',
    domain: 'gosite.run',
    paymentAppUrl: 'https://digitalpayments.gosite.run',
    // paymentAppUrl: "http://localhost:3002",
    googleReCAPTCHAKey: '6LdCTC0UAAAAAJqltNid5LYtJyjLOydnkWB5ecxM',
    mediaServerURl: 'https://media.gosite.run/',
    quickbooksClientId: 'Q0eHEMf7oxyRk3L0YiyuRbRB8v5hDGxFcEZowszeWIpsgS7nFU',
    thumbtackClientId: 'KYza5pSHJUk3cNOrgRWR+bXWKKIQrhrAeGcqFCuaSeE=',
    websocket_url: 'wss://omvxh1y0c8.execute-api.us-west-1.amazonaws.com/dev',
    chargeBeeId: 'gosite-test',
    captchaCount: 3,
    referralrock: 'ec2e56de-22f3-4237-b121-d2e9d43f9947',
    engagementDomain: 'https://engagement.gosite.run',
    yearlySubscriptonDiscountPercentage: 25,
    googlePlacesKey: 'AIzaSyAt8oDCZuLzE-Ct1MciSC5P1uG4LslImnA',
    prospectID: '5fb3b7e054675587fe9723f9', // Since it is a default non editable role, hard coded here to save database propogations,
    trailRoleId: '5aacfd1a94c8bc0dc46435a2', //id for role trail
    customerRoleID: '58c240ee12bc2a7ba73f26b3', //id for customer role
    vitallyProductName: 'GoSite Staging',
    amazonGatewayUrl: 'https://jt5rfh4pic.execute-api.us-west-1.amazonaws.com/staging/api',
    // amazonGatewayUrl: "http://lvh.me:3500/api",
    previewSite: 'https://site-preview.gosite.run/preview.html',
    publishSite: 'https://my.gosite.run',
    v3WebsiteRootDomain: 'staging.gosite.app',
    paymentSite: 'https://payments.gosite.run',
    configCat: 'YfjYCOyC5UWMTa9YP2YugQ/c8ULhLEcVEeLD-YK3-PfFg',
    multiLocationDomain: 'partner.lvh.me:3000',
    strapiUrl: 'https://cms.gosite.run',
    cognitoUrl: 'http://localhost:3001/login',
    stripe_pk: 'pk_test_ZbnU0vpCV6NGTLPOFMEm138200rBAvMr20',
    contactSalesUrl: 'https://www.gosite.com/contact-sales-form',
    bento: {
      appId: 'e3841cb4-ef37-434a-a435-5f77b87f08ea',
      envName: 'Development'
    },
    coverdash: {
      env: 'staging',
      license: '3d7b88c4-f4ee-4b96-8a28-e68b42c20046'
    }
  },
  main: {
    debug: false,
    title: 'GoSite',
    cookies: {
      name: 'gosite',
      expiry: 1,
      domain: '',
      parentDomain: '.gosite.run'
    },
    site_cutoff_date: '2022-11-02',
    google_redirect_uri: 'https://cloud.gosite.run/login',
    incognito_redirect_url: 'https://accounts.gosite.run/',
    google_contacts_key: '441363085080-r52j0bisnbq2q655mnh9jsi1304t5ac8.apps.googleusercontent.com',
    google_login_key: '724721037206-vj9qqt51l5r1stcpeu4nljsv6d074iu3.apps.googleusercontent.com',
    facebook_client_id: '1957056017949606',
    facebook_graph_url: 'https://graph.facebook.com/v4.0/me/',
    outlook_key: '3434be5e-0de1-4ef8-a8f0-91eda4ccd5ee',
    tinyMCEKey: 'bz8ou1t5ip0xejmvohefk7g3ptklj9vmge10ebq2trv244xg',
    cardConnectPort: '6443',
    cardConnectUrl:
      'https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html?sendcssloadedevent=true&formatinput=true&tokenizewheninactive=true&inactivityto=300&invalidinputevent=true&placeholder=Card Number&cardnumbernumericonly=true&cardinputmaxlength=19&css=.error%7Bcolor%3Ared%3B%7Dinput%7Bwidth%3A100%25%3Bheight%3A40px%3Bpadding%3A%200%2010px%3Bborder-radius%3A%204px%3Bbox-sizing%3Aborder-box%3Bcolor%3Argba%2887%2C%20108%2C%20119%2C%201%29%3Bborder%3Anone%3Bborder%3A%20solid%201px%20rgb%28224%2C%20228%2C%20232%29%3Bfont-size%3A%2013px%3Boutline%3A0%3B%7D%3A%3Aplaceholder%7Bcolor%3Argba%280%2C0%2C0%2C0.5%29%3Bfont-weight%3A400%3B%7Dbody%7Bmargin%3A0%3B%7D',
    paymentCardConnectUrl:
      'https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html?sendcssloadedevent=true&formatinput=true&tokenizewheninactive=true&inactivityto=300&invalidinputevent=true&placeholder=Enter Card Number&cardnumbernumericonly=true&cardinputmaxlength=19',
    redirection_url: 'https://admin.gosite.run',
    webApiUrl: 'https://webapi.gosite.run',
    builderUrl: 'https://builder.gosite.run/',
    sitePreviewUrl: 'https://preview.gosite.run/',
    hbId: '4522867',
    domain: 'gosite.run',
    paymentAppUrl: 'https://digitalpayments.gosite.run',
    googleReCAPTCHAKey: '6LdCTC0UAAAAAJqltNid5LYtJyjLOydnkWB5ecxM',
    mediaServerURl: 'https://media.gosite.cloud/',
    quickbooksClientId: 'Q0eHEMf7oxyRk3L0YiyuRbRB8v5hDGxFcEZowszeWIpsgS7nFU',
    thumbtackClientId: 'KYza5pSHJUk3cNOrgRWR+bXWKKIQrhrAeGcqFCuaSeE=',
    websocket_url: 'wss://omvxh1y0c8.execute-api.us-west-1.amazonaws.com/dev',
    chargeBeeId: 'gosite-test',
    captchaCount: 3,
    referralrock: 'ec2e56de-22f3-4237-b121-d2e9d43f9947',
    engagementDomain: 'https://engagement.gosite.run',
    yearlySubscriptonDiscountPercentage: 25,
    googlePlacesKey: 'AIzaSyAt8oDCZuLzE-Ct1MciSC5P1uG4LslImnA',
    prospectID: '5fb3b7e054675587fe9723f9', // Since it is a default non editable role, hard coded here to save database propogations
    trailRoleId: '5aacfd1a94c8bc0dc46435a2', //id for role trail
    customerRoleID: '58c240ee12bc2a7ba73f26b3', //id for customer role
    vitallyProductName: 'GoSite Staging',
    amazonGatewayUrl: 'https://jt5rfh4pic.execute-api.us-west-1.amazonaws.com/staging/api',
    previewSite: 'https://site-preview.gosite.build/preview.html',
    publishSite: 'https://my.gosite.run',
    v3WebsiteRootDomain: 'staging.gosite.app',
    paymentSite: 'https://payments.gosite.run',
    configCat: 'YfjYCOyC5UWMTa9YP2YugQ/c8ULhLEcVEeLD-YK3-PfFg',
    multiLocationDomain: 'partner.gosite.run',
    strapiUrl: 'https://cms.gosite.run',
    cognitoUrl: 'https://accounts.gosite.run/login',
    stripe_pk: 'pk_test_ZbnU0vpCV6NGTLPOFMEm138200rBAvMr20',
    contactSalesUrl: 'https://www.gosite.com/contact-sales-form',
    bento: {
      appId: 'e3841cb4-ef37-434a-a435-5f77b87f08ea',
      envName: 'Staging (.run)'
    },
    coverdash: {
      env: 'staging',
      license: '3d7b88c4-f4ee-4b96-8a28-e68b42c20046'
    }
  },
  sprint: {
    debug: false,
    title: 'GoSite',
    cookies: {
      name: 'gosite',
      expiry: 1,
      domain: '',
      parentDomain: '.gosite.run'
    },
    site_cutoff_date: '2022-11-02',
    google_redirect_uri: 'https://cloud.gosite.build/login',
    incognito_redirect_url: 'https://accounts.gosite.run/',
    google_contacts_key: '441363085080-r52j0bisnbq2q655mnh9jsi1304t5ac8.apps.googleusercontent.com',
    google_login_key: '724721037206-vj9qqt51l5r1stcpeu4nljsv6d074iu3.apps.googleusercontent.com',
    facebook_client_id: '1957056017949606',
    facebook_graph_url: 'https://graph.facebook.com/v4.0/me/',
    outlook_key: '3434be5e-0de1-4ef8-a8f0-91eda4ccd5ee',
    tinyMCEKey: 'bz8ou1t5ip0xejmvohefk7g3ptklj9vmge10ebq2trv244xg',
    cardConnectPort: '6443',
    cardConnectUrl:
      'https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html?sendcssloadedevent=true&formatinput=true&tokenizewheninactive=true&inactivityto=300&invalidinputevent=true&placeholder=Card Number&cardnumbernumericonly=true&cardinputmaxlength=19&css=.error%7Bcolor%3Ared%3B%7Dinput%7Bwidth%3A100%25%3Bheight%3A40px%3Bpadding%3A%200%2010px%3Bborder-radius%3A%204px%3Bbox-sizing%3Aborder-box%3Bcolor%3Argba%2887%2C%20108%2C%20119%2C%201%29%3Bborder%3Anone%3Bborder%3A%20solid%201px%20rgb%28224%2C%20228%2C%20232%29%3Bfont-size%3A%2013px%3Boutline%3A0%3B%7D%3A%3Aplaceholder%7Bcolor%3Argba%280%2C0%2C0%2C0.5%29%3Bfont-weight%3A400%3B%7Dbody%7Bmargin%3A0%3B%7D',
    paymentCardConnectUrl:
      'https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html?sendcssloadedevent=true&formatinput=true&tokenizewheninactive=true&inactivityto=300&invalidinputevent=true&placeholder=Enter Card Number&cardnumbernumericonly=true&cardinputmaxlength=19',
    redirection_url: 'https://admin.gosite.build',
    // webApiUrl:"https://webapi.gosite.build",
    webApiUrl: 'http://webapi-lb-71862832.us-west-1.elb.amazonaws.com',
    builderUrl: 'https://builder.gosite.build/',
    sitePreviewUrl: 'https://preview.gosite.build/',
    hbId: '4522867',
    domain: 'gosite.build',
    paymentAppUrl: 'https://digitalpayments.gosite.build',
    //paymentAppUrl :"https://digitalpayments.gosite.cloud",
    googleReCAPTCHAKey: '6LdCTC0UAAAAAJqltNid5LYtJyjLOydnkWB5ecxM',
    mediaServerURl: 'https://media.gosite.cloud/',
    quickbooksClientId: 'Q0eHEMf7oxyRk3L0YiyuRbRB8v5hDGxFcEZowszeWIpsgS7nFU',
    thumbtackClientId: 'KYza5pSHJUk3cNOrgRWR+bXWKKIQrhrAeGcqFCuaSeE=',
    websocket_url: 'wss://omvxh1y0c8.execute-api.us-west-1.amazonaws.com/dev',
    chargeBeeId: 'gosite-test',
    captchaCount: 3,
    referralrock: 'ec2e56de-22f3-4237-b121-d2e9d43f9947',
    engagementDomain: 'https://engagement.gosite.build',
    yearlySubscriptonDiscountPercentage: 25,
    googlePlacesKey: 'AIzaSyAt8oDCZuLzE-Ct1MciSC5P1uG4LslImnA',
    prospectID: '5fb3b7e054675587fe9723f9', // Since it is a default non editable role, hard coded here to save database propogations
    trailRoleId: '5aacfd1a94c8bc0dc46435a2', //id for role trail,
    customerRoleID: '58c240ee12bc2a7ba73f26b3', //id for customer role
    vitallyProductName: 'GoSite Staging',
    amazonGatewayUrl: 'https://jt5rfh4pic.execute-api.us-west-1.amazonaws.com/staging/api',
    previewSite: 'https://site-preview.gosite.build/preview.html',
    publishSite: 'https://my.gosite.build',
    v3WebsiteRootDomain: 'staging.gosite.app',
    paymentSite: 'https://payments.gosite.run',
    configCat: 'YfjYCOyC5UWMTa9YP2YugQ/c8ULhLEcVEeLD-YK3-PfFg',
    multiLocationDomain: 'partner.gosite.build',
    strapiUrl: 'https://cms.gosite.run',
    cognitoUrl: 'https://accounts.gosite.run/login',
    stripe_pk: 'pk_test_ZbnU0vpCV6NGTLPOFMEm138200rBAvMr20',
    contactSalesUrl: 'https://www.gosite.com/contact-sales-form'
  },
  staging: {
    debug: false,
    title: 'GoSite',
    cookies: {
      name: 'gosite',
      expiry: 1,
      domain: '',
      parentDomain: '.gosite.cloud'
    },
    site_cutoff_date: '2022-11-02',
    google_redirect_uri: 'https://cloud.gosite.cloud/login',
    incognito_redirect_url: 'https://accounts.gosite.cloud/',
    google_contacts_key: '441363085080-r52j0bisnbq2q655mnh9jsi1304t5ac8.apps.googleusercontent.com',
    google_login_key: '724721037206-vj9qqt51l5r1stcpeu4nljsv6d074iu3.apps.googleusercontent.com',
    facebook_client_id: '1957056017949606',
    facebook_graph_url: 'https://graph.facebook.com/v4.0/me/',
    outlook_key: '3434be5e-0de1-4ef8-a8f0-91eda4ccd5ee',
    tinyMCEKey: 'bz8ou1t5ip0xejmvohefk7g3ptklj9vmge10ebq2trv244xg',
    cardConnectPort: '6443',
    cardConnectUrl:
      'https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html?sendcssloadedevent=true&formatinput=true&tokenizewheninactive=true&inactivityto=300&invalidinputevent=true&placeholder=Card Number&cardnumbernumericonly=true&cardinputmaxlength=19&css=.error%7Bcolor%3Ared%3B%7Dinput%7Bwidth%3A100%25%3Bheight%3A40px%3Bpadding%3A%200%2010px%3Bborder-radius%3A%204px%3Bbox-sizing%3Aborder-box%3Bcolor%3Argba%2887%2C%20108%2C%20119%2C%201%29%3Bborder%3Anone%3Bborder%3A%20solid%201px%20rgb%28224%2C%20228%2C%20232%29%3Bfont-size%3A%2013px%3Boutline%3A0%3B%7D%3A%3Aplaceholder%7Bcolor%3Argba%280%2C0%2C0%2C0.5%29%3Bfont-weight%3A400%3B%7Dbody%7Bmargin%3A0%3B%7D',
    paymentCardConnectUrl:
      'https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html?sendcssloadedevent=true&formatinput=true&tokenizewheninactive=true&inactivityto=300&invalidinputevent=true&placeholder=Enter Card Number&cardnumbernumericonly=true&cardinputmaxlength=19',
    redirection_url: 'https://admin.gosite.cloud',
    webApiUrl: 'https://webapi.gosite.cloud',
    builderUrl: 'https://builder.gosite.cloud/',
    sitePreviewUrl: 'https://preview.gosite.cloud/',
    hbId: '4522867',
    domain: 'gosite.cloud',
    paymentAppUrl: 'https://digitalpayments.gosite.run',
    googleReCAPTCHAKey: '6LdCTC0UAAAAAJqltNid5LYtJyjLOydnkWB5ecxM',
    mediaServerURl: 'https://media.gosite.cloud/',
    quickbooksClientId: 'Q0eHEMf7oxyRk3L0YiyuRbRB8v5hDGxFcEZowszeWIpsgS7nFU',
    thumbtackClientId: 'KYza5pSHJUk3cNOrgRWR+bXWKKIQrhrAeGcqFCuaSeE=',
    websocket_url: 'wss://omvxh1y0c8.execute-api.us-west-1.amazonaws.com/dev',
    chargeBeeId: 'gosite-test',
    captchaCount: 3,
    referralrock: 'ec2e56de-22f3-4237-b121-d2e9d43f9947',
    engagementDomain: 'https://engagement.gosite.cloud',
    yearlySubscriptonDiscountPercentage: 25,
    googlePlacesKey: 'AIzaSyAt8oDCZuLzE-Ct1MciSC5P1uG4LslImnA',
    prospectID: '5fb3b7e054675587fe9723f9', // Since it is a default non editable role, hard coded here to save database propogations
    trailRoleId: '5aacfd1a94c8bc0dc46435a2', //id for role trail,
    customerRoleID: '58c240ee12bc2a7ba73f26b3', //id for customer role
    vitallyProductName: 'GoSite Staging',
    amazonGatewayUrl: 'https://jt5rfh4pic.execute-api.us-west-1.amazonaws.com/staging/api',
    previewSite: 'https://site-preview.gosite.build/preview.html',
    publishSite: 'https://my.gosite.build',
    v3WebsiteRootDomain: 'staging.gosite.app',
    paymentSite: 'https://payments.gosite.run',
    configCat: 'YfjYCOyC5UWMTa9YP2YugQ/c8ULhLEcVEeLD-YK3-PfFg',
    multiLocationDomain: 'partner.gosite.build',
    strapiUrl: 'https://cms.gosite.run',
    cognitoUrl: 'https://accounts.gosite.run/login',
    stripe_pk: 'pk_test_ZbnU0vpCV6NGTLPOFMEm138200rBAvMr20',
    contactSalesUrl: 'https://www.gosite.com/contact-sales-form',
    bento: {
      appId: 'e3841cb4-ef37-434a-a435-5f77b87f08ea',
      envName: 'Staging (.cloud)'
    },
    coverdash: {
      env: 'staging',
      license: '3d7b88c4-f4ee-4b96-8a28-e68b42c20046'
    }
  },
  production: {
    debug: false,
    title: 'GoSite',
    cookies: {
      name: 'gosite',
      expiry: 1,
      domain: '',
      parentDomain: '.gosite.com'
    },
    site_cutoff_date: '2022-11-03',
    google_redirect_uri: 'https://cloud.gosite.com/login',
    incognito_redirect_url: 'https://accounts.gosite.com/',
    google_contacts_key: '441363085080-r52j0bisnbq2q655mnh9jsi1304t5ac8.apps.googleusercontent.com',
    google_login_key: '724721037206-vj9qqt51l5r1stcpeu4nljsv6d074iu3.apps.googleusercontent.com',
    facebook_client_id: '1200914646667545',
    facebook_graph_url: 'https://graph.facebook.com/v4.0/me/',
    outlook_key: 'fe1e08c1-f0d2-48ed-acc0-e872ef76adc3',
    tinyMCEKey: 'h8izhwjghh8wilgzlk801bxoc9ia6b9p1cq9pw8pz20j89uo',
    cardConnectPort: '6443',
    cardConnectUrl:
      'https://fts.cardconnect.com/itoke/ajax-tokenizer.html?sendcssloadedevent=true&formatinput=true&tokenizewheninactive=true&inactivityto=300&invalidinputevent=true&placeholder=Card Number&cardnumbernumericonly=true&cardinputmaxlength=19&css=.error%7Bcolor%3Ared%3B%7Dinput%7Bwidth%3A100%25%3Bheight%3A40px%3Bpadding%3A%200%2010px%3Bborder-radius%3A%204px%3Bbox-sizing%3Aborder-box%3Bcolor%3Argba%2887%2C%20108%2C%20119%2C%201%29%3Bborder%3Anone%3Bborder%3A%20solid%201px%20rgb%28224%2C%20228%2C%20232%29%3Bfont-size%3A%2013px%3Boutline%3A0%3B%7D%3A%3Aplaceholder%7Bcolor%3Argba%280%2C0%2C0%2C0.5%29%3Bfont-weight%3A400%3B%7Dbody%7Bmargin%3A0%3B%7D',
    paymentCardConnectUrl:
      'https://fts.cardconnect.com/itoke/ajax-tokenizer.html?sendcssloadedevent=true&formatinput=true&tokenizewheninactive=true&inactivityto=300&invalidinputevent=true&placeholder=Enter Card Number&cardnumbernumericonly=true&cardinputmaxlength=19',
    redirection_url: 'https://admin.gosite.com',
    webApiUrl: 'https://webapi.gosite.com',
    builderUrl: 'https://builder.gosite.com/',
    sitePreviewUrl: 'https://preview.gosite.com/',
    hbId: '3938013',
    domain: 'gosite.com',
    paymentAppUrl: 'https://digitalpayments.gosite.com',
    googleReCAPTCHAKey: '6LdCTC0UAAAAAJqltNid5LYtJyjLOydnkWB5ecxM',
    mediaServerURl: 'https://media.gosite.com/',
    quickbooksClientId: 'Q0gm4kpewZQnnwNYVXMx2NC52QkLI6fktQZ22nYn3vZkorpcUp',
    thumbtackClientId: 'Om6R84NRaYF4Xz+8YdyZaMmbThAe96nNd/P+FkZd4ck=',
    websocket_url: 'wss://dxxrjeuqmg.execute-api.us-west-1.amazonaws.com/production',
    chargeBeeId: 'gosite',
    captchaCount: 3,
    referralrock: 'ec2e56de-22f3-4237-b121-d2e9d43f9947',
    engagementDomain: 'https://engagement.gosite.com',
    yearlySubscriptonDiscountPercentage: 25,
    googlePlacesKey: 'AIzaSyAt8oDCZuLzE-Ct1MciSC5P1uG4LslImnA',
    prospectID: '5fb3b7e054675587fe9723f9', // Since it is a default non editable role, hard coded here to save database propogations
    trailRoleId: '5aacfd1a94c8bc0dc46435a2', //id for role trail
    customerRoleID: '58c240ee12bc2a7ba73f26b3', //id for customer role
    vitallyProductName: 'GoSite',
    amazonGatewayUrl: 'https://7dbjstpjy1.execute-api.us-west-1.amazonaws.com/gosite/api',
    previewSite: 'https://site-preview.gosite.com/preview.html',
    publishSite: 'https://my.gosite.app',
    v3WebsiteRootDomain: 'gosite.app',
    paymentSite: 'https://payments.gosite.com',
    configCat: 'YfjYCOyC5UWMTa9YP2YugQ/MIO8okzEFU6US91rox3_bQ',
    multiLocationDomain: 'partner.gosite.com',
    strapiUrl: 'https://cms.gosite.com',
    cognitoUrl: 'https://accounts.gosite.com/login',
    stripe_pk: 'pk_live_TUIpbIpr2T7FRGvg4kdeKURJ00P4EqCyf2',
    contactSalesUrl: 'https://www.gosite.com/contact-sales-form',
    bento: {
      appId: 'e3841cb4-ef37-434a-a435-5f77b87f08ea',
      envName: 'Production'
    },
    coverdash: {
      env: 'production',
      license: '3d7b88c4-f4ee-4b96-8a28-e68b42c20046'
    }
  }
}

type AppEnvironment = 'development' | 'main' | 'sprint' | 'production'

let appEnvironment = (process.env.REACT_APP_ENV as AppEnvironment) || 'development'

// configure env for local host
if (process.env.REACT_APP_ENV === 'local') {
  appEnvironment = 'development'
  serverPath.development = 'http://lvh.me:3500'
  builderServerPath.development = 'http://lvh.me:4500'
  invoiceServerPath.development = 'http://localhost:4000/v1'
  env.development = {
    ...env.development,
    redirection_url: 'http://lvh.me:3500',
    builderUrl: 'http://lvh.me:4500/',
    amazonGatewayUrl: ''
  }
}

let server = serverPath[appEnvironment]
const apiOverride = localStorage.getItem('api_override')

type AppEnvironmentV2 = 'developmentV2' | 'mainV2' | 'sprintV2' | 'productionV2'
const appEnvironmentV2 = (appEnvironment.concat('V2') as AppEnvironmentV2) || 'developmentV2'
const serverV2 = serverPath[appEnvironmentV2]

if (apiOverride) {
  server = apiOverride
  console.log('SERVER API DOMAIN HAS BEEN OVERRIDDEN TO:', server)
}
console.log('server', server, appEnvironment)

const mediaServer = mediaServerPath[appEnvironment]
const builderServer = builderServerPath[appEnvironment]
const invoiceServer = invoiceServerPath[appEnvironment]
const cognitoServer = cognitoServerPath[appEnvironment]
const cognitoApiServer = cognitoApiServerPath[appEnvironment]
const version = '/api/'
const versionV2 = '/v2/integration/'
const versionV2UberAll = '/v2/uberall/'
const versionV2Gbm = '/v2/messenger/'

const common = {
  server,
  serverV2,
  mediaServer,
  cognitoServer,
  api: `${server}${version}`,
  apiV2: `${serverV2}${versionV2}`,
  apiV2Uberall: `${serverV2}${versionV2UberAll}`,
  apiV2Gbm: `${serverV2}${versionV2Gbm}`,
  mediaApi: `${mediaServer}${version}`,
  builderApi: `${builderServer}${version}`,
  invoiceApi: `${invoiceServer}${version}`,
  cognitoApi: `${cognitoApiServer}`,
  exportUrl: `${server}${version}product-payments`,
  export: `${server}${version}`,
  exportBooking: `${server}${version}leads/appointment/list`,
  exportReviewUrl: `${server}${version}reviews`,
  colorPaletteUrl: 'https://d22q21gwyle376.cloudfront.net/theme-color/palettes.json',
  platformID: platformIdS[appEnvironment],
  paymentApi: paymentServerPath[appEnvironment],
  onboardingApi: onboardingServerPath[appEnvironment],
  domainName: domains[appEnvironment]
}

export function getEnviornmentData() {
  return {
    ...env[appEnvironment],
    ...common
  }
}

export const days = [
  {
    label: 'MONDAY',
    value: 2
  },
  {
    label: 'TUESDAY',
    value: 3
  },
  {
    label: 'WEDNESDAY',
    value: 4
  },
  {
    label: 'THURSDAY',
    value: 5
  },
  {
    label: 'FRIDAY',
    value: 6
  },
  {
    label: 'SATURDAY',
    value: 7
  },
  {
    label: 'SUNDAY',
    value: 1
  }
]

export const publicKeysForEncryption = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDJD1wlEI1S0vJwVcafRLwmVAnm
39FSv8SL5wy10h2yfWNO9XHduJClsrhUQ0i1z7na6ORo969geel3Jet2kGSZuyXZ
c4z8fT/bzt7UNedr9IZsa9BUI3fvGzr/R6iHMHhoIk4R6BMBcQkqsrQKHklD7EO1
hA/q6C/uPGBpzU45kQIDAQAB
-----END PUBLIC KEY-----`
