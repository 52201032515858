import React from 'react'
import { Formik, Form, Field } from 'formik'
import moment from 'moment'
import Icon from '@components/icons'
import style from '../index.module.scss'
import { POPUP_TYPES, getContactAddText } from './invoice-popup-helpers'
import { useTranslation } from 'react-i18next'

const ConfirmInvoice = ({
  invoiceData,
  onReset,
  onSubmit,
  invoiceSaveLoader,
  typeOfInvoice,
  setIsFormChanged,
  popupType,
  me,
  paymentFeedbackStatus
}) => {
  const { t } = useTranslation()
  const IS_UPDATING_EXISTING_INVOICE = popupType === POPUP_TYPES.EDIT_INVOICE || popupType === POPUP_TYPES.EDIT_SERIES
  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={true}
      enableReinitialize={true}
      initialValues={{
        invoice_title:
          invoiceData && invoiceData.invoice_title
            ? invoiceData.invoice_title
            : me.bussiness_name
            ? me.bussiness_name
            : me.account_settings.bussiness_name + ': ' + moment().format('MMMM YYYY'),
        invoice_description:
          invoiceData && invoiceData.invoice_description
            ? invoiceData.invoice_description
            : t('payments.thanks-for-your-interest-in-our-services'),
        saveContact: '',
        review_feedback: paymentFeedbackStatus || false
      }}
      onReset={onReset}
      onSubmit={onSubmit}
      render={({ values, setFieldValue }) => {
        return (
          <Form id="frmSendInvoice" name="frmSentInvoice" className={`custom-get-paid-form ${style['get-paid-form']}`}>
            <button
              name="btninvoiceCloseModal"
              type="reset"
              className="close custom-modal-close-button remove-modal-close">
              <span aria-hidden="true">×</span>
            </button>
            <div className="row">
              <div className="col-12">
                <div className="form-group custom-form-group-dropdown mb-10 custom-cursor-hide">
                  <label className="label-text">{t('payments.sending-to')}</label>
                  <div className="d-flex align-items-center border rounded-sm p-15">
                    <figure className={`mb-0 mr-15 ${style['b-image-sending']}`}>
                      <img
                        className={`w-100 rounded-circle ${style['image']}`}
                        src={
                          invoiceData && invoiceData.image
                            ? invoiceData.image
                            : 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'
                        }
                        alt=""
                      />
                    </figure>
                    <div>
                      <div className="d-flex mb-5">
                        <h4>
                          {invoiceData && invoiceData.customer_first_name && invoiceData.customer_last_name
                            ? `${invoiceData.customer_first_name} ${invoiceData.customer_last_name}`
                            : ''}
                        </h4>
                      </div>
                      <div className="d-flex">
                        {invoiceData && invoiceData.customer_phone && <div>{invoiceData.customer_phone}</div>}

                        {invoiceData && invoiceData.customer_phone && invoiceData && invoiceData.customer_email ? (
                          <span className={`pl-5 pr-5 ${style['dot-separate']} `}></span>
                        ) : null}

                        {invoiceData && invoiceData.customer_email && <div>{invoiceData.customer_email}</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {invoiceData &&
              (invoiceData.isEmailChanged || invoiceData.isMobileChanged || invoiceData.isNameChanged) ? (
                <div className="col-12">
                  <div className="form-group form-check mb-30">
                    <label>
                      <Field
                        type="checkbox"
                        className="h-cb"
                        checked={values.saveContact}
                        onChange={(event) => {
                          setFieldValue('saveContact', !values.saveContact)
                          setIsFormChanged(true)
                        }}
                      />
                      <span id="frm_addService_chkBookingHours" className="checkbox-icon mr-10"></span>
                      {getContactAddText(invoiceData)}
                    </label>
                  </div>
                </div>
              ) : null}
              {!IS_UPDATING_EXISTING_INVOICE && (
                <>
                  {Boolean(typeOfInvoice === 'email' || typeOfInvoice === 'email_sms') && (
                    <>
                      <div className="col-12">
                        <div className="form-group custom-form-group-dropdown mb-15 custom-cursor-hide">
                          <label className="label-text">{t('payments.email-subject')}</label>
                          <Field
                            type="text"
                            name="invoice_title"
                            className="form-control form-control-lg"
                            placeholder={t('payments.enter-subject')}
                            onChange={(event) => {
                              setFieldValue('invoice_title', event.target.value)
                              setIsFormChanged(true)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group custom-form-group-dropdown mb-15 custom-cursor-hide">
                          <label className="label-text">{t('payments.customer-message')}</label>
                          <div className="d-flex flex-column">
                            <span className="mb-10">{t('payments.add-customer-message-shown-in-invoice')}</span>
                            <Field
                              type="text"
                              name="invoice_description"
                              className="form-control form-control-lg"
                              placeholder={t('payments.enter-message')}
                              maxLength={512}
                              onChange={(event) => {
                                setFieldValue('invoice_description', event.target.value)
                                setIsFormChanged(true)
                              }}
                            />
                          </div>
                          <div className={`${style['character-limit']} mt-15`}>
                            <span
                              className="d-block"
                              style={{ width: (values.invoice_description.length / 512) * 100 + '%' }}></span>
                          </div>
                          <span id="businessTextLimit" className={`${style['character-left']} mb-10`}>
                            {512 - values.invoice_description.length} {t('payments.characters-remaining')}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              <div className="col-12">
                <div className="form-group form-check">
                  <label className="label-text">REVIEW</label>
                  <div className="d-flex align-items-center justify-content-between border rounded-sm p-15">
                    <span>{t('payments.ask-customer-to-write-a-review')}</span>
                    <label>
                      <Field
                        type="checkbox"
                        name="review_feedback"
                        className="h-cb"
                        checked={values.review_feedback}
                        onChange={(event) => {
                          setFieldValue('review_feedback', !values.review_feedback)
                          setIsFormChanged(true)
                        }}
                      />
                      <span id="frm_addService_chkBookingHours" className="checkbox-icon"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <footer className="d-block d-flex justify-content-around mt-10">
              <button
                className={'btn btn-primary border-0 btn-xl mr-10 flex-fill'}
                data-test="send-invoice-form-btn"
                id="frmBtnSendInvoice"
                disabled={invoiceSaveLoader}
                type="submit">
                {popupType === POPUP_TYPES.EDIT_SERIES
                  ? t('payments.update-recurring-series')
                  : popupType === POPUP_TYPES.EDIT_INVOICE || (invoiceData && invoiceData.invoice_id)
                  ? t('payments.update-invoice-0')
                  : t('payments.send-invoice-0')}
                {invoiceSaveLoader && (
                  <i className={'btn-icon top-minus-2 mr-5 btn-loader'}>
                    <Icon name="spinner" />
                  </i>
                )}
              </button>
              <button type="reset" id="frmBtnSendInvoice_btnCancel" className={'btn btn-xl ml-10 cancel-btn flex-fill'}>
                {t('payments.cancel-0')}
              </button>
            </footer>
          </Form>
        )
      }}
    />
  )
}

export default ConfirmInvoice
