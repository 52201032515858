import * as service from './service'
import { autosuggestContacts } from '../contacts-hub/service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'
import { segmentEventTrack } from '@helpers/segment'

const siteNameConvert = (name) => {
  const siteName = name.toLowerCase()
  return siteName.substring(0, 1).toUpperCase() + siteName.substring(1)
}

export default {
  state: {
  },
  reducers: {
    onRequest (state) {
      return {
        ...state,
        reviewloading: true,
        apiError: false
      }
    },
    onReviewRequest (state) {
      return {
        ...state,
        reviewloading: true,
        apiError: false,
        reviewList: {}
      }
    },
    onError (state, data) {
      const err =
            data && data.data && data.data.status && data.data.status.messageDescription
              ? data.data.status.messageDescription
              : data && data.status && data.status.messageDescription
                ? data.status.messageDescription
                : data && data.message
                  ? data.message
                  : data && data.data && data.data.message
                    ? data.data.message
                    : data && data.error
                      ? data.error
                      : data && data.data && data.data.error
                        ? data.data.error
                        : 'Please try again later'
      notification('error', capitalize(err))
      return {
        ...state,
        reviewloading: false,
        apiError: true
      }
    },
    handleCustomHandle (state, msg) {
      notification('error', msg)
      return {
        ...state,
        reviewloading: false
      }
    },
    onGetReviewRequestSuccess (state, data) {
      return {
        ...state,
        reviewloading: false,
        reviewRequests: data.data || data
      }
    },
    onReviewListSuccess (state, data) {
      return {
        ...state,
        reviewloading: false,
        reviewList: data
      }
    },
    onreviewsAnalyticsSuccess (state, data) {
      const chart_data = data.data.reviews.map((item, index) => (
        { 'name': item.site === 'DIRECT' ? 'GoSite'
          : item.site === 'GOOGLEMYBUSINESS' ? 'Google'
            : item.site === 'FACEBOOK' ? 'Facebook' : siteNameConvert(item.site),
        'y': item.total }
      ))
      const bar_data = data.data.reviews.map((item, index) => ({ 'name': item.site === 'DIRECT' ? 'GoSite' : item.site === 'GOOGLEMYBUSINESS' ? 'Google' : item.site === 'FACEBOOK' ? 'Facebook' : siteNameConvert(item.site), 'y': item.avg_rating }))
      const rating_star_width = (data.data.avg_rating / 5) * 100
      data.data.star_width = rating_star_width + '%'
      return {
        ...state,
        reviewloading: false,
        reviewsanalytics: data.data,
        chartdata: chart_data,
        bardata: bar_data

      }
    },
    onGetFeedback (state, data) {
      return {
        ...state,
        reviewloading: false,
        feedback_list: data
      }
    },
    ondeleteScheduleRequestSuccess (state, data) {
      if (data.data.n > 0) {
        notification('success', 'Scheduled review requests removed successfully')
      } else if (data.data.n === 0) {
        notification('error', 'No scheduled review requests found')
      }
      return {
        ...state,
        reviewloading: false
      }
    },
    onresetScheduleRequestSuccess (state, data) {
      notification('success', 'Review requests reset successfully')
      return {
        ...state,
        reviewloading: false
      }
    },
    onsendNowSuccess (state, data) {
      notification('success', 'Your review request has been added to the queue. You can track the status of each review request from the Review Requests tab. ')
      return {
        ...state,
        reviewloading: false
      }
    },
    onGetFeedbackDetails (state, data) {
      return {
        ...state,
        reviewloading: false,
        feedback_details: data
      }
    },
    onSendReply (state, data) {
      notification('success', data.message)
      return {
        ...state,
        reviewloading: false,
        apiError: false
      }
    },
    onsendreplyReviewSuccess (state, data) {
      notification('success', 'Message has been sent successfully')
      return {
        ...state,
        reviewloading: false
      }
    },
    oneditreplyReviewSuccess (state, data) {
      notification('success', 'Message has been sent successfully')
      return {
        ...state,
        reviewloading: false
      }
    },
    ondeleteReviewSuccess (state, data) {
      notification('success', 'Review has been removed successfully')
      return {
        ...state,
        reviewloading: false
      }
    },
    sendReviewSuccess (state, data, payload) {
      if (data.recipient_contacts.length < 10) {
        notification('success', 'Your review request has been added to the queue. You can track the status of each review request from the Review Requests tab. ')
      } else {
        notification('success', 'Review request have been scheduled')
      }
      if (payload.recipient_contacts.length) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const email = []
        const mobile = []
        payload.recipient_contacts.forEach(item => {
          if (re.test(String(item).toLowerCase())) {
            email.push(item)
          } else {
            mobile.push(item)
          }
        })
        const mobileCount = mobile.length
        const emailCount = email.length

        const track_data = {
          'event': 'new_review_request',
          'properties': {
            'number_of_review_request_sent': payload.recipient_contacts.length,
            'Email_review_request_number': emailCount,
            'TinyURL': payload.TinyURL,
            'SMS_review_request_number': mobileCount,
            'total_reviews': state && state.reviewList && state.reviewList.total ? state.reviewList.total : 0,
            'success_message': 'Your review request has been added to the queue. You can track the status of each review request from the Review Requests tab. .'
          }
        }
        segmentEventTrack(track_data)
      }
      return {
        ...state,
        reviewloading: false
      }
    },
    ondeleteReviewCommentSuccess (state, data) {
      return {
        ...state,
        reviewloading: false
      }
    },
    getReviewByIdSuccess (state, data) {
      return {
        ...state,
        reviewloading: false,
        reviewDetailsItem: data
      }
    },
    ongetContactListSuccess (state, data) {
      return {
        ...state,
        reviewloading: false,
        contactList: data
      }
    },
    ongetPredefinedTemplateSuccess (state, data) {
      return {
        ...state,
        reviewloading: false,
        predefinedTemplate: data
      }
    },
    onsendSmsRequestSuccess (state, data) {
      return {
        ...state,
        reviewloading: false
      }
    },
    onreviewrequestAnalyticsSuccess (state, data) {
      if (data.followed && data.followed > 0) {
        data.followed_rate = data.followed * 100 / data.delivered
        data.followed_rate = Math.round(data.followed_rate * 100) / 100
      } else {
        data.followed_rate = 0
      }
      return {
        ...state,
        reviewloading: false,
        reviewrequestanalytics: data
      }
    },
    onfetchWidgetDetailsSuccess (state, data) {
      return {
        ...state,
        widgetData: data
      }
    },
    onAutosuggestContactsSuccess (state, data) {
      const contacts = data.map(function (item) {
        return item._source
      })
      return {
        ...state,
        reviewloading: false,
        searchRes: contacts
      }
    },
    onGetReviewWidgetsSuccess (state, data) {
      return {
        ...state,
        reviewloading: false,
        reviewWidgets: data
      }
    },
    onSuccess (state, data) {
      if (data && data.message) {
        notification('success', data && data.message)
      }
      return {
        ...state,
        reviewloading: false
      }
    },
    syncReviewsSuccess (state, data) {
      notification('success', 'Reviews will be synced shortly')
      return {
        ...state,
        reviewloading: false,
        last_sync_time: data.last_updated
      }
    },
    onGetSyncTime (state, data) {
      return {
        ...state,
        reviewloading: false,
        last_sync_time: data.last_updated
      }
    },
    onGetReviewShareableLink (state, data) {
      return {
        ...state,
        reviewShareableURL: data.url
      }
    }

  },
  effects: {

    async autosuggestContacts (payload) {
      const res = await autosuggestContacts(payload)
      await this.onAutosuggestContactsSuccess(res.data)
      return res.data
    },
    async getReviewList (payload, rootState) {
      this.onReviewRequest()
      try {
        const res = await service.ReviewList(payload)
        await this.onReviewListSuccess(res)
        return res
      } catch (e) {
        // this.onError(e)
      }
    },
    async getReviewRequests (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getReviewRequests(payload)
        this.onGetReviewRequestSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getFeedback (payload) {
      this.onRequest()
      try {
        const res = await service.getFeedback(payload)
        await this.onGetFeedback(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteScheduleRequest (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.deleteScheduleRequest(payload)

        await this.ondeleteScheduleRequestSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async resetScheduleRequest (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.resetScheduleRequest(payload)

        await this.onresetScheduleRequestSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async sendNow (payload, rootState) {
      try {
        const res = await service.sendNow(payload)

        await this.onsendNowSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async reviewsAnalytics (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.reviewsAnalytics(payload)
        await this.onreviewsAnalyticsSuccess(res)
        return res
      } catch (e) {
        // this.onError(e)
      }
    },
    async getFeedbackDetails (feedback_id) {
      this.onRequest()
      try {
        const res = await service.getFeedbackDetails(feedback_id)
        await this.onGetFeedbackDetails(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async sendReply (feedback_id, evt, data, rootState) {
      this.onRequest()
      try {
        let res
        if (data.item && data.item.siteId === 'DIRECT') {
          res = await service.sendReply(feedback_id, { 'message': data.reply, 'review_id': feedback_id })
        } else if (data.item && data.item.siteId === 'FACEBOOK') {
          res = await service.sendReplyFB({ 'comment': data.reply, 'review_id': feedback_id })
        } else if (data.item && data.item.siteId === 'GOOGLEMYBUSINESS') {
          res = await service.sendReplyGoogle({ 'comment': data.reply, 'review_id': feedback_id })
        } else if (data.item && (data.item.siteId === 'YELP' || data.item.siteId === 'YELPKNOWLEDGE')) {
          res = await service.sendReplyYelp({ 'comment': data.reply, 'review_id': feedback_id })
        }
        this.onSendReply(res)
        // track here
        const track_data = {
          'event': 'responding_to_feedbacks',
          properties: {
            source: data.item.siteId,
            TinyURL: rootState.auth.me.review_shareable_url,
            success_message: 'Message has been sent successfully'
          }
        }
        segmentEventTrack(track_data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async sendreplyReview (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.sendreplyReview(payload)
        this.onsendreplyReviewSuccess(res)
        // track here
        const track_data = {
          'event': 'responding_to_reviews',
          properties: {
            TinyURL: rootState.auth.me.review_shareable_url,
            success_message: 'Message has been sent successfully'
          }
        }
        segmentEventTrack(track_data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async sendreplyFBGMBYelp (payload, rootState, type) {
      this.onRequest()
      try {
        let res = ''
        if (type === 'fb') {
          res = await service.sendReplyFB(payload)
        } else if (type === 'gmb') {
          res = await service.sendReplyGoogle(payload)
        } else if (type === 'yelp') {
          res = await service.sendReplyYelp(payload)
        }
        this.onsendreplyReviewSuccess(res)
        // track here
        const track_data = {
          'event': 'responding_to_reviews',
          properties: {
            TinyURL: rootState.auth.me.review_shareable_url,
            success_message: 'Message has been sent successfully'
          }
        }
        segmentEventTrack(track_data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async editreplyReview (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.editreplyReview(payload)

        await this.oneditreplyReviewSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async editreplyYelpGMB (payload, rootState, type) {
      this.onRequest()
      try {
        let res = ''
        if (type === 'yelp') {
          res = await service.editreplyYelp(payload)
        }
        await this.oneditreplyReviewSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteReview (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.deleteReview(payload)
        await this.ondeleteReviewSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteReviewComment (payload, rootState, user_id) {
      this.onRequest()
      try {
        const res = await service.deleteReviewComment(payload, user_id)

        await this.ondeleteReviewCommentSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteReviewReplyFBGMBYelp (payload, rootState, type) {
      const typeDel = type && type.type ? type.type : ''
      this.onRequest()
      try {
        let res = ''
        if (typeDel === 'gmb') {
          res = await service.deleteGMBReply(payload, type.userId)
        } else if (typeDel === 'yelp') {
          res = await service.deleteReviewComment(payload, type.userId)
        }
        await this.ondeleteReviewCommentSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getReviewById (payload) {
      this.onRequest()
      try {
        const res = await service.getReviewById(payload)
        await this.getReviewByIdSuccess(res.data)
        return res.data
      } catch (e) {
        this.handleCustomHandle('Sorry, either that review does not exist or you do not have permission to access it')
      }
    },
    async getContactList (payload) {
      this.onRequest()
      try {
        const res = await service.getContactList(payload)
        this.ongetContactListSuccess(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async getContactListFromES (payload) {
      this.onRequest()
      try {
        const res = await service.getContactListFromES(payload)
        this.ongetContactListSuccess(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async getPredefinedTemplate (payload) {
      this.onRequest()
      try {
        const res = await service.getPredefinedTemplate(payload)
        await this.ongetPredefinedTemplateSuccess(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async sendSmsRequest (payload) {
      this.onRequest()
      try {
        const res = await service.sendSmsRequest(payload)
        await this.onsendSmsRequestSuccess(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async reviewrequestAnalytics (payload) {
      this.onRequest()
      try {
        const res = await service.reviewrequestAnalytics(payload)
        await this.onreviewrequestAnalyticsSuccess(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async getReviewWidgets (payload) {
      this.onRequest()
      try {
        const res = await service.getReviewWidgets(payload)
        this.onGetReviewWidgetsSuccess(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async removeReviewWidget (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.removeReviewWidget(payload)
        this.onSuccess(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchWidgetDetails (id) {
      this.onRequest()
      try {
        const res = await service.fetchWidgetDetails(id.id)
        this.onfetchWidgetDetailsSuccess(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async changeFavouriteWidget (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.changeFavouriteWidget(payload)
        this.onSuccess(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async sendSms (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.sendSms(payload)
        this.onSuccess(res)
        // track here
        const track_data = { 'event': 'sms_review_request',
          properties: {
            review_request_sent_to: payload.contacts,
            TinyURL: rootState.auth.me.review_shareable_url,
            success_message: 'Message has been sent successfully'
          } }
        segmentEventTrack(track_data)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async sendReview (payload, rootState, query) {
      this.onRequest()
      try {
        const res = await service.sendReview(payload, query)
        payload.TinyURL = rootState.auth.me.review_shareable_url
        this.sendReviewSuccess(res, payload)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async syncReviews (payload, rootState, noLoader) {
      if (!noLoader) { this.onRequest() }
      try {
        const res = await service.syncReviews(payload)
        this.syncReviewsSuccess(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async getReviewLastSyncTime (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getReviewLastSyncTime(payload)
        this.onGetSyncTime(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async uberAllCategory (payload, rootState, module) {
      this.onRequest()
      try {
        const res = await service.uberAllCategory(payload, module)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getReviewShareableLink (payload) {
      this.onRequest()
      try {
        const res = await service.getReviewShareableLink(payload)
        this.onGetReviewShareableLink(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    }
  }
}
