import React from 'react'
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik'
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import Select, { components } from 'react-select'
import Popup from '@components/popups/basic-modal'
import { numberMask } from '@helpers/inputmask'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment-timezone'
import style from '../index.module.scss'
import './add-booking-popup.scss'
import notification from '@components/notification'
import { readSegregateV2Flag } from '@helpers/segregateFlag'

import {
  phoneNumberFormat,
  isEnabled,
  validateEmail,
  validateMobile,
  UsCurrencyFormatter,
  changeTimezone,
  isDisabled
} from '@helpers/utility'
import AsyncCreatableSelect from 'react-select/async-creatable'
import Icon from '@components/icons'
import Tooltip from '@components/tooltip'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'
import config from '@config/index'
import InputMask from 'react-input-mask'
import OnClickOutside from '@components/outsideClickComponent'
import { parseName } from '@helpers/index'
import { history } from '@app/store'
import ConfirmPopup from '@components/popups/confirm-modal'
import { segmentEventTrack } from '@helpers/segment'
import _ from 'lodash'
import i18next from 'i18next'
const t = i18next.t

let isFormChanged = false
let isAddressChanged = false

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon name="arrowDownThin" />
        {/* <FontAwesomeIcon icon={props.selectProps.menuIsOpen ? "caret-up" : "caret-down"}/> */}
      </components.DropdownIndicator>
    )
  )
}

function ConvertTimeformat(format, str) {
  const time = str
  let hours = Number(time.match(/^(\d+)/)[1])
  const minutes = Number(time.match(/:(\d+)/)[1])
  const AMPM = time.match(/\s(.*)$/)[1]
  if (AMPM === 'PM' && hours < 12) hours = hours + 12
  if (AMPM === 'AM' && hours === 12) hours = hours - 12
  let sHours = hours.toString()
  let sMinutes = minutes.toString()
  if (hours < 10) sHours = '0' + sHours
  if (minutes < 10) sMinutes = '0' + sMinutes
  return sHours + ':' + sMinutes
}
function parseToDate(DATE_TIME) {
  const d = new Date()
  d.setHours(DATE_TIME.substring(0, 2))
  d.setMinutes(DATE_TIME.substring(3, 5))
  return d
}
function getTimeIntervals(time1, time2, interval, startTimeInc) {
  const arr = []
  // eslint-disable-next-line no-unmodified-loop-condition
  while (time1 <= time2) {
    arr.push(time1.toTimeString().substring(0, 5))
    time1.setMinutes(time1.getMinutes() + startTimeInc)
  }
  return arr
}
class TimeInput extends React.Component {
  onChange = (event) => {
    const { field } = this.props
    this.props.handleChange(event.target.value, field)
  }
  render() {
    const { time, disabled, placeholder, isTimeLimited } = this.props
    let startsWithOne
    if (time) {
      startsWithOne = time[0] === '2'
    }
    let mask
    if (isTimeLimited) {
      mask = [/[0-2]/, startsWithOne ? /[0-3]/ : /[0-9]/, ':', /[0-5]/, /[0-9]/]
    } else {
      mask = [/[0-9]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]
    }
    return (
      <InputMask
        id={this.props.id}
        maskPlaceholder="0"
        disabled={disabled}
        className={'form-control form-control-lg border-0 px-0 text-secondary add-reminder-time-field'}
        placeholder={placeholder}
        mask={mask}
        value={time}
        onChange={this.onChange}
      />
    )
  }
}

// const currentDate = new Date();
export default class extends React.Component {
  amountDueTypes = [
    { value: 'full', label: t('bookings.full-payment') },
    { value: 'partial', label: t('bookings.deposit-partial-payment') }
  ]
  amountTypes = [
    { value: 'amount', label: '$' },
    { value: 'percentage', label: '%' }
  ]
  paymentCollectVia = [
    { value: 'direct', label: t('bookings.manually-add-a-transaction') },
    { value: 'getpaid', label: t('bookings.payment-link') }
    // {value:"invoice",label:"Invoice"},
  ]

  serviceLocations = [
    { label: t('bookings.my-business'), value: 'business_address', id: 'business_address' },
    { label: t('bookings.customers-address'), value: 'customer_address', id: 'customer_address' }
  ]

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      bookingDetails: {
        // appointment_reminder_time:this.props.accountsettings && this.props.accountsettings.booking_reminder_time ? this.props.accountsettings.booking_reminder_time : [{ hour: "00", min: "00", value: "00" }],
        send_reminder: true
      },
      selectedServiceLocation: this.serviceLocations[0],
      editServiceLocation: false,
      selectedLocation: [],
      notify_end_user: true,
      newService: ''
    }
  }
  showPopup() {
    this.setState({ open: true })
    // this.getMaxDate();
  }
  handleDateChangeRaw = (e) => {
    e.preventDefault()
  }
  isContactUpdated(oldValues, newValues) {
    let contactUpdated = false
    if (oldValues.first_name !== newValues.first_name) {
      contactUpdated = true
    }
    if (oldValues.last_name !== newValues.last_name) {
      contactUpdated = true
    }
    if (oldValues.customer_phone !== newValues.customer_phone) {
      contactUpdated = true
    }
    if (oldValues.customer_email !== newValues.customer_email) {
      contactUpdated = true
    }
    return contactUpdated
  }
  bookingTimeChanged(oldValues, newValues) {
    let timeChanged = false
    if (oldValues.appointment_date && newValues.appointment_date) {
      if (moment(oldValues.appointment_date).format('L') !== newValues.appointment_date) {
        timeChanged = true
        return timeChanged
      }
    }
    if (oldValues.appointment_start_date && newValues.appointment_start_time) {
      let time = moment.utc(oldValues.appointment_start_date).format('LT')
      time = ConvertTimeformat('24', time)
      if (time !== newValues.appointment_start_time) {
        timeChanged = true
        return timeChanged
      }
    }
    return timeChanged
  }
  isCustomerEmailChanged(email, selected, created) {
    if (!email && ((selected && selected.email) || (created && created.data && created.data.email))) {
      return true
    } else if (email && selected && selected.email && email !== selected.email) {
      return true
    } else if (email && !selected && created && created.data && created.data.email && email !== created.data.email) {
      return true
    } else if (email && (!selected || !selected.email)) {
      return true
    } else {
      return false
    }
  }
  isCustomerNumberChanged(mobile, selected, created) {
    if (!mobile && ((selected && selected.mobile) || (created && created.data && created.data.mobile))) {
      return true
    }
    if (mobile && selected && selected.mobile) {
      if (mobile !== this.cleanedNumber(selected.mobile)) {
        return true
      }
    }
    if (mobile && !selected && created && created.data && created.data.mobile) {
      if (mobile !== this.cleanedNumber(created.data.mobile)) {
        return true
      }
    }
    if (mobile && (!selected || !selected.mobile)) {
      return true
    } else {
      return false
    }
  }
  isCustomerNameChanged(fname, lname, selected) {
    if (!fname && !lname && selected && (selected.first_name || selected.last_name)) {
      return true
    }
    if (fname && selected && selected.first_name !== fname) {
      return true
    }
    if (lname && selected && selected.last_name !== lname) {
      return true
    } else {
      return false
    }
  }
  cleanedNumber(number) {
    if (number) {
      return phoneNumberFormat(number.replace('+1', ''))
    }
  }
  handleSubmit(values, { resetForm }) {
    // values.service_id = this.state.service.value;
    values.appointment_date = this.state.selectedDate
    values.appointment_start_time = values.selectedAppTime.value.split('-')[0]
    const endTime = parseToDate(values.appointment_start_time)
    endTime.setMinutes(endTime.getMinutes() + this.state.service.dataObj.duration)
    values.appointment_end_time = endTime.toTimeString().substring(0, 5)
    values.admin_offline = true
    values.email_notification = true
    delete values.selectedAppTime
    delete values.customer_email_phone

    const res = parseName(values.customername)
    if (res) {
      values.first_name = res.first
      values.last_name = res.last
    }

    let errorArr = []
    if (values.appointment_reminder_flag) {
      errorArr = values.appointment_reminder_time.map((item) => {
        return !(item.hour === '00' && item.min === '00')
      })
    } else {
      values.appointment_reminder_time = []
    }
    if (errorArr.includes(false)) {
      notification('error', t('bookings.invalid-time-slots-found-in-reminder-time'))
      return
    }

    // need to confirm the following code
    if (this.props.contactDetails && this.props.contactDetails.contact && this.props.contactId) {
      values.contact_id = this.props.contactDetails.contact._id
      values.contact_update = this.isCompare(this.props.contactDetails, values)
    }

    delete values.date
    delete values.appointment

    if (this.props.bookingType === 'edit') {
      values.notify_end_user = this.state.notify_end_user
      values.admin_offline = true

      // values.contact_update = true
      values.contact_update = this.isContactUpdated(this.props.editBookingDetails, values)

      values.booking_time_changed = this.bookingTimeChanged(this.props.editBookingDetails, values)

      delete values.isMobileChanged
      delete values.isEmailChanged
      delete values.isAddressChanged
      delete values.photo
    } else {
      // checking if amny details changed
      values.isNameChanged = this.isCustomerNameChanged(values.first_name, values.last_name, this.state.selectedContact)
      values.isEmailChanged = this.isCustomerEmailChanged(
        values.customer_email,
        this.state.selectedContact,
        this.props.privateContact
      )
      values.isMobileChanged = this.isCustomerNumberChanged(
        values.customer_phone,
        this.state.selectedContact,
        this.props.privateContact
      )
    }

    values.isAddressChanged = isAddressChanged
    if (values && values.is_payment) {
      if (values.allow_partial_payment) {
        if (values.minimum_payment_type === 'percentage') {
          values.payment_amount =
            values.service_rate * values.service_quantity * (values.minimum_payment_percentage / 100)
        } else if (values.minimum_payment_type === 'amount') {
          values.payment_amount = values.minimum_payment_amount
        }
      } else {
        values.payment_amount = values.service_rate * values.service_quantity
      }
      values.payment_amount = parseFloat(
        parseFloat(values.payment_amount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      )
    }
    // payment tracking
    const segTrackData = {
      event: 'add_booking_continue',
      properties: {
        user_id: this.props.me && this.props.me._id,
        role:
          this.props.me &&
          this.props.me.role_id &&
          this.props.me.role_id.role_name &&
          this.props.me.role_id.role_name &&
          this.props.me.role_id.role_name === 'trial'
            ? 'free-trial'
            : this.props.me.role_id.role_name,
        payment_option: values.is_payment, // Collect Advance Payment on/off
        payment_amount: values.is_payment ? values.payment_type : null, // Full or partial payment
        payment_method: values.is_payment
          ? values.collect_via === 'direct'
            ? 'manual'
            : values.collect_via === 'getpaid'
            ? 'payment_link'
            : null
          : null
      }
    }
    segmentEventTrack(segTrackData)
    if (values.customer_email || values.customer_phone) {
      this.props.onSubmit(
        {
          ...values,
          custom_fields: Object.values(values.custom_fields)
        },
        resetForm
      )
      isFormChanged = false
      isAddressChanged = false
    } else {
      notification('error', t('bookings.email-or-phone-number-required'))
    }
  }

  closeModal(resetForm) {
    this.setState({ open: false, service: '', date: '', selectedSlot: '' })
    if (resetForm) {
      resetForm({
        first_name: '',
        last_name: '',
        customer_email: '',
        customer_phone: '',
        service_id: '',
        appointment_date: '',
        selectedAppTime: '',
        send_reminder: true
      })
    }
    isFormChanged = false
    isAddressChanged = false
    this.props.close()
  }
  isCompare(contactDetails, values) {
    let contactUpdate = true
    const mobileNumber =
      contactDetails && contactDetails.contact && contactDetails.contact.mobile
        ? numberMask(contactDetails.contact.mobile.replace('+1', ''))
        : contactDetails && contactDetails.lead && contactDetails.lead.customer_phone
        ? numberMask(contactDetails.lead.customer_phone.replace('+1', ''))
        : ''
    if (
      contactDetails.contact.first_name === values.first_name &&
      contactDetails.contact.last_name === values.last_name &&
      contactDetails.contact.email === values.customer_email &&
      mobileNumber === values.customer_phone
    ) {
      contactUpdate = false
    }
    return contactUpdate
  }
  handleCancel() {
    const { bookingDetails } = this.state
    if (bookingDetails && bookingDetails.status === 'incomplete') {
      this.cancelAppointment()
    } else {
      isFormChanged = false
      this.setState({ open: false, date: '', newSlots: '', service: '', selectedSlot: '' })
      // resetForm({ first_name: "", last_name: "", customer_email: "", customer_phone: "", service_id: "", appointment_date: "", selectedAppTime: "", send_reminder: true })
      this.props.close()
    }
  }
  async getDateSlots(setFieldValue, selectedService, key) {
    if (selectedService && selectedService.__isNew__) {
      this.setState({ newService: selectedService })
    } else {
      this.setState({ newService: '' })
    }
    if (key) {
      this.setState({ date: null, selectedSlot: null })
    }
    // setFieldValue(key, selectedService);
    const bookingDetails = { ...this.state.bookingDetails }
    bookingDetails.service_id =
      selectedService && selectedService.dataObj && selectedService.dataObj._id ? selectedService.dataObj._id : ''
    if (this.props.bookingType === 'edit' && this.props.editBookingDetails) {
      bookingDetails.service_quantity = this.props.editBookingDetails.service_quantity
    } else {
      bookingDetails.service_quantity = 1
    }
    bookingDetails.service_rate = selectedService && selectedService.dataObj && selectedService.dataObj.price
    if (key) {
      bookingDetails.date = ''
      bookingDetails.selectedAppTime = ''
      bookingDetails.appointment = ''
    }

    bookingDetails.selectedService = selectedService.dataObj
    bookingDetails.allow_partial_payment = selectedService.dataObj && selectedService.dataObj.allow_partial_payment
    if (bookingDetails.allow_partial_payment) {
      bookingDetails.minimum_payment_amount = selectedService.dataObj && selectedService.dataObj.minimum_payment_amount
      bookingDetails.minimum_payment_percentage =
        selectedService.dataObj && selectedService.dataObj.minimum_payment_percentage
      bookingDetails.minimum_payment_type = selectedService.dataObj && selectedService.dataObj.minimum_payment_type
    }

    this.setState({ bookingDetails: bookingDetails })
    // if (this.state.selectedSlot) {
    //   this.setState({ selectedSlot: {label:"",value:""} });
    // }
    if (selectedService && selectedService.dataObj && selectedService.dataObj.dateRange) {
      const range = this.getMaxDate(selectedService.dataObj.dateRange)
      this.setState({ maxDate: range })

      this.getOffDays(selectedService.dataObj)

      // await this.generateAllslotDate(selectedService.dataObj)
    }
    this.setState({ service: selectedService, newSlots: [], slotsNotAvaiable: [] })
  }
  async getAMPM(input) {
    const timeString = input
    const H = +timeString.substr(0, 2)
    const h = H % 12 || 12
    const ampm = H < 12 ? 'AM' : 'PM'
    const TIME_START = h + timeString.substr(2, 3) + ampm
    return TIME_START
  }
  getMaxDate(dateRange) {
    const date = changeTimezone(new Date(), this.props.userTimeZone && this.props.userTimeZone.key)
    date.setDate(date.getDate() + dateRange)
    //   this.setState({ maxDate: date })
    return date
  }
  generateAllslotDate(service) {
    const { dateRange } = service
    const dates = []
    if (dateRange) {
      for (let i = 0; i <= dateRange; i++) {
        const date = changeTimezone(new Date(), this.props.userTimeZone && this.props.userTimeZone.key)
        date.setDate(date.getDate() + i)
        dates.push({ date: moment(date).format('YYYY-MM-DD HH:mm:ss') })
      }
    }
    this.generateBookingTimes(service, dates)
  }
  async generateBookingTimes(service, dates) {
    const bookinglots = {}

    Object.keys(service.business_hours).forEach(function (day) {
      if (service.business_hours.hasOwnProperty(day)) {
        bookinglots[day] = {}
        bookinglots[day].open = service.business_hours[day].open
        bookinglots[day].split = service.business_hours[day].split
        bookinglots[day].slots = []

        if (service.business_hours[day].open) {
          service.business_hours[day].slots.forEach(function (item) {
            let ts, te

            if (!service.business_hours[day].open_allday) {
              ts = ConvertTimeformat('24', item.time_start)
              te = ConvertTimeformat('24', item.time_end)
            } else {
              ts = '00:00'
              te = '24:00'
            }

            const intervals = getTimeIntervals(parseToDate(ts), parseToDate(te), service.duration, service.startTimeInc)

            intervals.forEach((interval, index) => {
              const nextInterval = intervals[index + 1]
              if (nextInterval) {
                bookinglots[day].slots.push({
                  time_start: interval,
                  time_end: nextInterval,
                  is_booked: false,
                  is_available: true,
                  _id: Math.floor(Math.random() * 1000000000).toFixed()
                })
              }
            })
          })
        }
      }
    })

    const slotDates = []
    dates.forEach((item) => {
      const key = moment(item.date).format('dddd').toLowerCase()
      const data = { date: item.date, slots: bookinglots[key].slots }
      slotDates.push(data)
    })
    await this.setState({ slotOption: slotDates })
  }
  async getDateFormat(d) {
    const date = new Date(d)

    const mnth = ('0' + (date.getMonth() + 1)).slice(-2)

    const day = ('0' + date.getDate()).slice(-2)
    this.setState({ selectedDate: [mnth, day, date.getFullYear()].join('/') })
  }
  async isFuture(date, time, period) {
    const zone = this.props.userTimeZone

    // let zone = this.props.userTimeZone && this.props.userTimeZone.offset_string

    // set time zone to business time zone
    moment.tz.setDefault(zone.key)
    if (time) {
      const t = moment(
        moment(date).format('YYYY-MM-DD') + ' ' + time.split('-')[0] + ' ' + period,
        'YYYY-MM-DD HH:mm a'
      )
      if (t.isValid() && moment(t).isAfter(moment().utcOffset(zone.offset_string))) {
        moment.tz.setDefault()
        return true
      } else {
        moment.tz.setDefault()
        return false
      }
    }
  }
  isSlotToBeDisabled(date, startTime, endTime, slotTime) {
    // checking if the slot is from the same day
    if (date && startTime) {
      const res = moment(moment(date)).isSame(moment(startTime), 'day')
      if (res) {
        const st = moment(moment(date).format('YYYY-MM-DD') + ' ' + slotTime.split('-')[0]).format(
          'YYYY-MM-DD HH:mm:ss'
        )
        if (startTime < st && st < endTime) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
  async dateChanged(setFieldValue, d, key, hideError) {
    if (setFieldValue) {
      setFieldValue(key, d)
      isFormChanged = true
    }
    const bookingDetails = { ...this.state.bookingDetails }
    bookingDetails[key] = d
    this.setState({ bookingDetails: bookingDetails })
    await this.setState({ date: d, newSlots: '', selectedSlot: '', slotsNotAvaiable: [], editAppointmentDate: false })
    if (d) {
      this.setState({ slotLoading: true })
      const payload = {
        serviceId: this.state.service.value,
        query: { date: moment(d).format('L'), checkAllService: true }
      }
      if (bookingDetails._id) {
        payload.query = { ...payload.query, booking_id: bookingDetails._id }
      }
      await this.generateAllslotDate(this.state.service.dataObj)
      await this.props.getUnAvailableDateSlots(payload)
      this.setState({ slotLoading: false })
      await this.getDateFormat(d)
      if (this.state.selectedDate) {
        let slots = []
        const formattedDate =
          this.state.selectedDate.split('/')[2] +
          '-' +
          this.state.selectedDate.split('/')[0] +
          '-' +
          this.state.selectedDate.split('/')[1]
        if (this.state.slotOption) {
          this.state.slotOption.forEach(function (eachDate) {
            if (eachDate.date.indexOf(formattedDate) > -1) slots = eachDate.slots
          })
        }
        const zone = this.props.userTimeZone && this.props.userTimeZone.key

        if (this.props.unAvailabledateSlots && this.props.unAvailabledateSlots.length) {
          this.props.unAvailabledateSlots.forEach((element) => {
            element.start_time_utc = moment(element.start_time_utc).tz(zone).format('YYYY-MM-DD HH:mm:ss')
            element.end_time_utc = moment(element.end_time_utc).tz(zone).format('YYYY-MM-DD HH:mm:ss')

            // element.start_time_utc = moment(element.start_time_utc).utcOffset(zone).format('YYYY-MM-DD HH:mm:ss')
            // element.end_time_utc = moment(element.end_time_utc).utcOffset(zone).format('YYYY-MM-DD HH:mm:ss')

            let updatedStart, updatedEnd

            // adding duration and preperation time to the unavilable slot
            if (this.state.service.dataObj && this.state.service.dataObj.block_off_time) {
              updatedStart = moment(element.start_time_utc)
                .subtract(
                  this.state.service.dataObj.duration + parseInt(this.state.service.dataObj.block_off_time),
                  'm'
                )
                .format('YYYY-MM-DD HH:mm:ss')
              updatedEnd = moment(element.end_time_utc)
                .add(this.state.service.dataObj.block_off_time, 'm')
                .format('YYYY-MM-DD HH:mm:ss')
            } else {
              updatedStart = moment(element.start_time_utc)
                .subtract(this.state.service.dataObj.duration, 'm')
                .format('YYYY-MM-DD HH:mm:ss')
              updatedEnd = moment(element.end_time_utc).format('YYYY-MM-DD HH:mm:ss')
            }

            // if the updated start time is not on same day, set the start time to current date
            const currentDate = moment(d).format('DD/MM/YYYY')
            const currentStart = moment(updatedStart).format('DD/MM/YYYY')
            const currentEnd = moment(updatedEnd).format('DD/MM/YYYY')

            if (currentDate !== currentStart && currentDate !== currentEnd) {
              return false
            } else if (currentDate !== currentStart && currentDate === currentEnd) {
              updatedStart = moment(d).format('YYYY-MM-DD') + ' 00:00:00'
              slots.shift() // removing 00:00 from slot array
            } else if (moment(updatedEnd).format('LT') === '12:00 AM') {
              // if end time is 12 am update end time to next day
              updatedEnd = moment(d).format('YYYY-MM-DD') + ' 24:00:00'
            }

            while (updatedStart < updatedEnd) {
              slots = this.checkIfSlotValid(slots, updatedStart, updatedEnd, formattedDate)
              updatedStart = moment(updatedStart)
                .add(this.state.service.dataObj.startTimeInc, 'm')
                .format('YYYY-MM-DD HH:mm:ss')
            }
          })
        }
        const slotArr = []
        if (slots.length) {
          for (let i = 0; i < slots.length; i++) {
            if (slots[i].is_booked === false && slots[i].is_available && slots[i].time_start !== slots[i].time_end) {
              const startTime = await this.getAMPM(slots[i].time_start)
              const res = await this.isFuture(formattedDate, startTime, startTime)
              if (res) {
                slotArr.push({ label: `${startTime}`, value: slots[i].time_start })
              }
            }
          }
          await this.setState({ newSlots: slotArr })
          if (slotArr.length === 0 || this.state.newSlots.length === 0) {
            if (!hideError) {
              notification('error', t('bookings.no-slots-available-on-the-selected-date'))
            }
            // await this.setState({ date: "", newSlots: "" });
          }
        } else {
          if (!hideError) {
            notification('error', t('bookings.no-slots-available-on-the-selected-date'))
          }
        }
      }
    }
  }
  checkIfSlotValid(slots, startTime, endTime, formattedDate) {
    const s = slots
    const selectedDate = moment(formattedDate).format('YYYY-MM-DD HH:mm:ss')
    // disable slots if its already booked or not available
    s.forEach((slot) => {
      if (this.isSlotToBeDisabled(selectedDate, startTime, endTime, slot.time_start)) {
        slot.is_available = false
        slot.is_booked = true
      }
    })
    return s
  }
  async clearDatepicker() {
    await this.setState({ date: '' })
  }
  setSelectedTimeSlot(setFieldValue, value, field) {
    isFormChanged = true
    setFieldValue(field, value)
    const bookingDetails = { ...this.state.bookingDetails }
    bookingDetails.selectedAppTime = value
    this.setState({ bookingDetails: bookingDetails })
    this.setState({ selectedSlot: value })
  }
  createNewVisible(array) {
    if (array.length > 0) {
      if (array[0] && array[0].options.length === 0) {
        return true
      } else return false
    } else return false
  }
  async handleSelectOption(setFieldValue, data, field, action) {
    isFormChanged = true

    if (action && action.action === 'clear') {
      this.setState({ hasContact: false, selectedValue: '' })
      setFieldValue(field, '')
    }
    if (data && data.value && data.contactObj) {
      this.setState({ selectedContact: data.contactObj })
      let obj = { ...this.state.bookingDetails }
      const location = {}
      obj.first_name = data.contactObj.first_name
      obj.last_name = data.contactObj.last_name
      obj.customer_email = data.contactObj.email
      obj.customer_phone = phoneNumberFormat(data.contactObj.mobile)
      // obj.customer_id = data.contactObj.contact_id;
      obj.contact_id = data.contactObj.contact_id
      obj.photo = data.contactObj.image

      // obj.customername = `${data.contactObj.first_name ? data.contactObj.first_name : ""} ${data.contactObj.last_name ? data.contactObj.last_name: ""}`
      obj.customername = ''

      if (data.contactObj.first_name) {
        obj.customername += data.contactObj.first_name
      }
      if (data.contactObj.last_name) {
        obj.customername += ` ${data.contactObj.last_name}`
      }

      if (obj.customer_email) {
        obj.send_email = true
      }
      if (obj.customer_phone && this.state.twilioEnabled) {
        obj.send_sms = true
      }
      const val = {
        image: data.image,
        label: data.label ? data.label : data.subLabel ? data.subLabel : data.value,
        value: data.value
      }
      if (data.contactObj.address) {
        location.address = data.contactObj.address
        if (data.contactObj.address2) {
          location.address2 = data.contactObj.address2
        }
        if (data.contactObj.city) {
          location.city = data.contactObj.city
        }
        if (data.contactObj.state) {
          location.state = data.contactObj.state
        }
        if (data.contactObj.country) {
          location.country = data.contactObj.country
        }
        if (data.contactObj.zip) {
          location.zip = data.contactObj.zip
        }
        obj = { ...obj, ...location }
      }
      if (location.address) {
        let label = ''
        for (const key in location) {
          if (location.hasOwnProperty(key)) {
            label += `${location[key]}, `
          }
        }
        this.setState({ selectedLocation: { label: label, value: { ...location } } })
      }
      this.setState({
        hasContact: true,
        bookingDetails: obj,
        selectedContact: data.contactObj,
        selectedValue: val,
        location: location
      })
      setFieldValue(field, data.value)
    }
    if (
      (action && action.action === 'create-option') ||
      (action && action.action === 'select-option' && !data.contactObj)
    ) {
      const mobileTest = validateMobile(data.value)
      const emailTest = validateEmail(data.value)
      if (data.value && !mobileTest && !emailTest) {
        const me = this.props.me || {}
        const hasTwilioAssigned = me.account_settings && me.account_settings.twilio && me.account_settings.twilio.length
        const isTrialUser = me.role_id && me.role_id.role_name === 'trial'
        const twilioEnabled = hasTwilioAssigned || isTrialUser

        const message = twilioEnabled
          ? t('bookings.please-enter-a-valid-email-or-mobile')
          : t('bookings.please-enter-a-valid-email')
        notification('error', message)
        this.setState({ hasContact: false, selectedValue: '' })
        setFieldValue(field, '')
      } else {
        let obj = { ...this.state.bookingDetails }
        let payload = {}
        if (mobileTest) {
          payload = {
            mobile: phoneNumberFormat(data.value),
            from_messenger: false,
            groupId: 0,
            is_public: true
          }
          obj.customer_phone = phoneNumberFormat(data.value)
          if (this.state.twilioEnabled) {
            obj.send_sms = true
          }
        }
        if (emailTest) {
          payload = {
            email: data.value,
            from_messenger: false,
            groupId: 0,
            is_public: true
          }
          obj.customer_email = data.value
          obj.send_email = true
        }
        const val = {
          label: data.value,
          value: data.value
        }
        this.setState({ hasContact: true, bookingDetails: obj, selectedValue: val })
        await this.props.savePrivateContact(payload)
        obj = { ...this.state.bookingDetails }
        obj.contact_id =
          this.props.privateContact && this.props.privateContact.data && this.props.privateContact.data._id
        obj.customer_email =
          this.props.privateContact && this.props.privateContact.data && this.props.privateContact.data.email
        obj.customer_phone =
          this.props.privateContact && this.props.privateContact.data && this.props.privateContact.data.mobile
            ? phoneNumberFormat(this.props.privateContact.data.mobile.replace('+1', ''))
            : ''
        this.setState({ bookingDetails: obj })
      }
    }
  }
  customOptions = ({ value, label, subLabel, image, contactObj, index }, { context }) => {
    if (context === 'value') {
      return (
        <div key={index} className="d-flex flex-grow-0">
          <div className="mr-10">
            <figure className={`mb-0 ${style['b-image']}`}>
              <img
                className={`w-100 rounded-circle ${style['image']}`}
                src={image || 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'}
                alt=""
              />
            </figure>
          </div>
          <div className="d-flex flex-grow-1 justify-content-between align-items-center">
            <div>{label || subLabel}</div>
          </div>
        </div>
      )
    }
    if (context === 'menu') {
      return (
        <React.Fragment>
          <div id="contactOptionValue" className="d-flex flex-grow-0">
            <div className="mr-10">
              <figure className={`mb-0 ${style['b-image']}`}>
                <img
                  className={`w-100 rounded-circle ${style['image']}`}
                  src={image || 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'}
                  alt=""
                />
              </figure>
            </div>
            <div className="d-flex align-items-center flex-grow-1 justify-content-between">
              <div className="text-truncate max-w">{label || subLabel}</div>
              <div className="text-truncate max-w">{label && subLabel ? `${subLabel}` : ''}</div>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }
  autoSuggestContacts = async (input) => {
    if (input.length >= 2) {
      const data = {
        nopaging: true,
        status: 'active',
        excludeNullGroup: true,
        search: input || ''
      }
      if (this.props.products && isEnabled('contact-manager', this.props.products)) {
        await this.props.autosuggestContacts(data)
        return [
          {
            options: this.props.contactList && this.props.contactList.length > 0 ? this.props.contactList : []
          }
        ]
      }
      return [
        {
          options: []
        }
      ]
    }
  }
  async getAddress(data) {
    isFormChanged = true
    this.setState({ selectedLocation: data })
    if (data && data.value && data.value.place_id) {
      const addressResult = await geocodeByPlaceId(data.value.place_id)
        .then((results) => results)
        .catch((error) => console.error(error))
      if (addressResult && addressResult.length && addressResult[0].formatted_address) {
        const addressDetails = {}
        let streetAddress = ''
        let streetNumber = ''
        let establishment = ''
        let city = null
        addressResult[0] &&
          addressResult[0].address_components &&
          addressResult[0].address_components.forEach((element) => {
            if (element.types[0] === 'country') {
              addressDetails.country = element.short_name
            } else if (element.types[0] === 'administrative_area_level_1') {
              addressDetails.state = element.short_name
            } else if (element.types[0] === 'locality') {
              addressDetails.city = element.long_name
            } else if (element.types[0] === 'postal_code') {
              addressDetails.zip = element.long_name
            } else if (element.types[0] === 'route') {
              streetAddress = element.long_name
            } else if (element.types[0] === 'street_number') {
              streetNumber = element.long_name
            } else if (element.types[0] === 'establishment') {
              establishment = element.long_name
            } else if (element.types[0] === 'political') {
              city = element.long_name
            }
          })

        let address = ''
        address += streetNumber || ''
        address += streetNumber ? ' ' : ''
        address += streetAddress
        address += `${city ? `,${city}` : ''}`
        address += establishment || ''

        addressDetails.address = address
        const bookingDetails = { ...this.state.bookingDetails, ...addressDetails }
        this.setState({ bookingDetails: bookingDetails })
        isAddressChanged = true
      }
    }
  }
  durationToMinutes(duration) {
    const arr = duration.split(':')
    const hr = Number(arr[0])
    const min = Number(arr[1])
    return hr * 60 + min
  }
  DurationtoString(item) {
    let hr = Math.floor(item / 60)
    let mn = item % 60
    if (hr.toString().length === 1) {
      hr = `0${hr}`
    }
    if (mn.toString().length === 1) {
      mn = `0${mn}`
    }
    return `${hr}:${mn}`
  }
  handleReminderTimeChange(setFieldValue, fields, index, value) {
    isFormChanged = true
    const arr = value.split(':')
    const hr = Number(arr[0])
    const min = Number(arr[1])
    const newdata = { hour: hr.toString(), min: min.toString(), value: this.durationToMinutes(value) }
    const allReminderTimes = fields
    allReminderTimes[index] = newdata
    const bookingDetails = { ...this.state.bookingDetails }
    bookingDetails.appointment_reminder_time = allReminderTimes
    this.setState({ bookingDetails: bookingDetails })
  }
  async removeContact() {
    const obj = { ...this.state.bookingDetails }
    obj.customer_email = ''
    obj.first_name = ''
    obj.last_name = ''
    obj.customername = ''
    // obj.customer_id = "";
    obj.customer_phone = ''
    obj.send_email = false
    obj.send_sms = false
    obj.photo = ''
    this.setState({
      hasContact: false,
      bookingDetails: obj,
      editSendType: false,
      selectedContact: '',
      customName: '',
      selectedValue: ''
    })
  }
  setOriginalValues(setFieldValue, type) {
    const obj = { ...this.state.bookingDetails }
    if (type === 'email') {
      setFieldValue('email', this.state.selectedContact.email)
      obj.customer_email = this.state.selectedContact.email
    } else if (type === 'mobile') {
      setFieldValue('mobile', phoneNumberFormat(this.state.selectedContact.mobile))
      obj.customer_phone = phoneNumberFormat(this.state.selectedContact.mobile)
    }
    this.setState({ bookingDetails: obj })
  }
  updateField(field, value) {
    isFormChanged = true
    const bookingDetails = { ...this.state.bookingDetails }
    bookingDetails[field] = value
    this.setState({ bookingDetails: bookingDetails })
  }
  async onRemoveReminderTime(setFieldValue, values, index) {
    isFormChanged = true
    const keyArray = [...values.appointment_reminder_time]
    keyArray.splice(index, 1)
    if (keyArray.length === 0) {
      // setFieldValue("appointment_reminder_flag", false)
      const bookingDetails = { ...this.state.bookingDetails }
      bookingDetails.appointment_reminder_time = [{ hour: '00', min: '00', value: '00' }]
      // selectedItem.appointment_reminder_flag = false
      await this.setState({ bookingDetails: bookingDetails, appointment_reminder_flag: false })
    } else {
      const bookingDetails = { ...this.state.bookingDetails }
      bookingDetails.appointment_reminder_time = keyArray
      // selectedItem.appointment_reminder_flag = true
      await this.setState({ bookingDetails: bookingDetails })
    }
  }
  async componentDidUpdate() {
    if (document.getElementById('frm_addService_txtPartialPayemnt')) {
      window.$('#frm_addService_txtPartialPayemnt').on('scroll touchmove mousewheel', function (e) {
        e.preventDefault()
        e.stopPropagation()
        return false
      })
    }
  }
  async componentDidMount() {
    // window.addEventListener("message", this.listenCardConnect.bind(this));
    // customer_email: this.props.contactDetails && this.props.contactDetails.contact && this.props.contactDetails.contact.email ? this.props.contactDetails.contact.email : this.props.contactDetails && this.props.contactDetails.lead && this.props.contactDetails.lead.customer_email ? this.props.contactDetails.lead.customer_email : "",
    // customer_phone: this.props.contactDetails && this.props.contactDetails.contact && this.props.contactDetails.contact.mobile ? numberMask(this.props.contactDetails.contact.mobile.replace("+1", "")) : this.props.contactDetails && this.props.contactDetails.lead && this.props.contactDetails.lead.customer_phone ? numberMask(this.props.contactDetails.lead.customer_phone.replace("+1", "")) : "",

    const me = this.props.me || {}
    const hasTwilioAssigned = me.account_settings && me.account_settings.twilio && me.account_settings.twilio.length
    const isTrialUser = me.role_id && me.role_id.role_name === 'trial'
    const twilioEnabled = hasTwilioAssigned || isTrialUser

    if (this.props.getServices && typeof this.props.getServices === 'function') {
      await this.props.getServices()
    }

    const flag = await readSegregateV2Flag(
      this.props.accountsettings.payment_type,
      'payment_v2',
      this.props.products,
      this.props.me
    )
    this.setState({ payment_v2: flag })

    let ErrorMessageSendVia = t('bookings.sms-service-is-not-enabled-in-your-account-try-with-an-email-instead')
    if (twilioEnabled) {
      ErrorMessageSendVia = t('bookings.please-select-email-or-sms-checkbox')
    }

    this.setState({ twilioEnabled, ErrorMessageSendVia })
    let bookingDetails = { ...this.state.bookingDetails }

    if (this.props.editBookingDetails) {
      // PREFILLING FORM DATA WHILE BOOKING EDIT

      bookingDetails = { ...this.props.editBookingDetails }
      if (bookingDetails.service_id) {
        bookingDetails.service_id.service_rate = bookingDetails.service_rate
        bookingDetails.service_id.service_quantity = bookingDetails.service_quantity

        await this.setState({
          service: {
            value: bookingDetails.service_id._id,
            label: bookingDetails.service_id.title,
            dataObj: bookingDetails.service_id
          }
        })
        this.getDateSlots(null, this.state.service)
        this.getOffDays(bookingDetails.service_id)
        bookingDetails.service_id = this.props.editBookingDetails.service_id._id
        bookingDetails.selectedService = this.props.editBookingDetails.service_id
        bookingDetails.allow_partial_payment = this.props.editBookingDetails.payment_type === 'partial'

        // if (bookingDetails.allow_partial_payment) {
        //     bookingDetails.minimum_payment_amount = this.props.editBookingDetails.service_id && this.props.editBookingDetails.service_id.minimum_payment_amount
        //     bookingDetails.minimum_payment_percentage = this.props.editBookingDetails.service_id && this.props.editBookingDetails.service_id.minimum_payment_percentage
        //     bookingDetails.minimum_payment_type = this.props.editBookingDetails.service_id && this.props.editBookingDetails.service_id.minimum_payment_type
        // }
      }

      bookingDetails.contact_id =
        this.props.editBookingDetails &&
        this.props.editBookingDetails.contact &&
        this.props.editBookingDetails.contact._id

      // if(bookingDetails.first_name){
      //     bookingDetails.customername = `${bookingDetails.first_name} ${bookingDetails.last_name ? bookingDetails.last_name : ""}`
      // }
      bookingDetails.customername = ''
      if (bookingDetails.first_name) {
        bookingDetails.customername += bookingDetails.first_name
      }
      if (bookingDetails.last_name) {
        bookingDetails.customername += ` ${bookingDetails.last_name}`
      }

      if (bookingDetails.appointment_date) {
        // eslint-disable-next-line
        let appointment_date = bookingDetails.appointment_date.replace(/-/g, '/').replace(/T.+/, '')
        bookingDetails.appointment = new Date(bookingDetails.appointment_date)
        bookingDetails.date = new Date(bookingDetails.appointment_date)
        this.setState({ date: new Date(appointment_date) })
        this.dateChanged(null, new Date(appointment_date), 'date', true)
      }
      if (bookingDetails.appointment_start_date) {
        const slot = {}
        slot.label = moment.utc(bookingDetails.appointment_start_date).format('LT')
        slot.value = moment.utc(bookingDetails.appointment_start_date).format('LT')

        const apptTime = ConvertTimeformat('24', slot.value)

        bookingDetails.selectedAppTime = { label: bookingDetails.appointment_start_date, value: apptTime }
        this.setState({ selectedSlot: slot })
      }
      if (bookingDetails.appointment_reminder_time && bookingDetails.appointment_reminder_time.length) {
        this.setState({ appointment_reminder_flag: true })
      }
      if (bookingDetails.service_occurs_at) {
        if (bookingDetails.service_occurs_at === 'customer_address') {
          this.setState({ selectedServiceLocation: this.serviceLocations[1] })
          bookingDetails.address = bookingDetails.service_address
          bookingDetails.address2 = bookingDetails.service_address2
          bookingDetails.state = bookingDetails.service_state
          bookingDetails.city = bookingDetails.service_city
          bookingDetails.zip = bookingDetails.service_zip
          let label = ''
          if (bookingDetails.service_address) {
            label += bookingDetails.service_address
          }
          if (bookingDetails.service_city) {
            label += `, ${bookingDetails.service_city}`
          }
          if (bookingDetails.service_state) {
            label += `, ${bookingDetails.service_state}`
          }
          if (bookingDetails.service_zip) {
            label += `, ${bookingDetails.service_zip}`
          }
          this.setState({ selectedLocation: { label: label, value: { address: label } } })
        }
        if (bookingDetails.service_occurs_at === 'business_address') {
          this.setState({ selectedServiceLocation: this.serviceLocations[0] })
        }
      }
      // if (bookingDetails.contact && bookingDetails.contact._id) {
      //     bookingDetails.customer_id = bookingDetails.contact._id
      // }
    } else {
      if (this.props.businessSettings && this.props.businessSettings.door_delivery) {
        this.setState({ selectedServiceLocation: this.serviceLocations[1] })
      }
      // initially prefill global reminder time in customer reminder time field
      if (
        this.props.accountsettings &&
        this.props.accountsettings.booking_reminder_time &&
        this.props.accountsettings.booking_reminder_time.length &&
        this.props.accountsettings.booking_reminder_time
      ) {
        bookingDetails.appointment_reminder_time = this.props.accountsettings.booking_reminder_time
        this.setState({ appointment_reminder_flag: true })
      }
    }

    if (this.props.contactDetails) {
      if (this.props.contactDetails.contact && this.props.contactDetails.contact.first_name) {
        bookingDetails.first_name = this.props.contactDetails.contact.first_name
      } else if (
        this.props.contactDetails &&
        this.props.contactDetails.lead &&
        this.props.contactDetails.lead.first_name
      ) {
        bookingDetails.first_name = this.props.contactDetails.lead.first_name
      }
      if (this.props.contactDetails.contact && this.props.contactDetails.contact.last_name) {
        bookingDetails.last_name = this.props.contactDetails.contact.last_name
      } else if (
        this.props.contactDetails &&
        this.props.contactDetails.lead &&
        this.props.contactDetails.lead.last_name
      ) {
        bookingDetails.last_name = this.props.contactDetails.lead.last_name
      }

      bookingDetails.customername = ''
      if (bookingDetails.first_name) {
        bookingDetails.customername += bookingDetails.first_name
      }
      if (bookingDetails.last_name) {
        bookingDetails.customername += ` ${bookingDetails.last_name}`
      }

      // prefill contact deatils for new booking
      const { contactDetails } = this.props
      if (
        contactDetails &&
        contactDetails.contact &&
        (contactDetails.contact.address || contactDetails.contact.address2)
      ) {
        const { contact } = contactDetails
        bookingDetails.address = contact.address
        bookingDetails.address2 = contact.address2
        bookingDetails.state = contact.state
        bookingDetails.city = contact.city
        bookingDetails.zip = contact.zip
        let label = ''
        if (bookingDetails.address) {
          label += bookingDetails.address
        }
        if (bookingDetails.city) {
          label += `, ${bookingDetails.city}`
        }
        if (bookingDetails.state) {
          label += `, ${bookingDetails.state}`
        }
        if (bookingDetails.zip) {
          label += `, ${bookingDetails.zip}`
        }
        this.setState({ selectedLocation: { label: label, value: { address: label } } })
      }

      // bookingDetails.customername = `${bookingDetails.first_name ? bookingDetails.first_name : "" } ${bookingDetails.last_name ? bookingDetails.last_name : ""}`

      if (this.props.contactDetails.contact && this.props.contactDetails.contact.email) {
        bookingDetails.customer_email = this.props.contactDetails.contact.email
      } else if (
        this.props.contactDetails &&
        this.props.contactDetails.lead &&
        this.props.contactDetails.lead.customer_email
      ) {
        bookingDetails.customer_email = this.props.contactDetails.lead.customer_email
      }

      if (this.props.contactDetails.contact && this.props.contactDetails.contact.mobile) {
        bookingDetails.customer_phone = numberMask(this.props.contactDetails.contact.mobile.replace('+1', ''))
      } else if (
        this.props.contactDetails &&
        this.props.contactDetails.lead &&
        this.props.contactDetails.lead.customer_phone
      ) {
        bookingDetails.customer_phone = numberMask(this.props.contactDetails.lead.customer_phone.replace('+1', ''))
      }
      // if(this.props.contactDetails.contact){
      //     bookingDetails.customer_id = this.props.contactDetails.contact._id
      // }
    }
    if (bookingDetails && (bookingDetails.customer_email || bookingDetails.customer_phone)) {
      if (bookingDetails.customer_email) {
        bookingDetails.send_email = true
      }
      if (bookingDetails.customer_phone) {
        bookingDetails.customer_phone = numberMask(bookingDetails.customer_phone.replace('+1', ''))
      }
      if (bookingDetails.customer_phone && hasTwilioAssigned) {
        bookingDetails.send_sms = true
      }
      const selectedContact = {
        email: bookingDetails.customer_email,
        mobile: bookingDetails.customer_phone,
        first_name: bookingDetails.first_name,
        last_name: bookingDetails.last_name
      }
      this.setState({ hasContact: true, bookingDetails: bookingDetails, selectedContact: selectedContact })
    }
  }

  getOffDays(service) {
    const weeks = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    const offDays = []
    if (service && service.business_hours) {
      Object.keys(service.business_hours).forEach(function (day, index) {
        if (service.business_hours[day].open === false) {
          offDays.push(weeks.indexOf(day))
        }
      })
    }
    this.setState({ offDays: offDays })
  }

  toggleEditing() {
    this.setState({ isEditing: !this.state.isEditing })
  }

  isOffDay(date) {
    if (this.state.offDays) {
      if (this.state.offDays.indexOf(date.getDay()) > -1) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }
  goToServicePage() {
    if (this.props.user && this.props.user.user_unique_id) {
      history.push(`/${this.props.user.user_unique_id}/services`)
    }
  }
  setPaymentDueType(setFieldValue, value) {
    isFormChanged = true
    const bookingDetails = { ...this.state.bookingDetails }
    bookingDetails.allow_partial_payment = value === 'partial'
    bookingDetails.payment_type = value
    if (value === 'partial' && bookingDetails.collect_via === 'invoice') {
      bookingDetails.collect_via = 'direct'
    }
    this.setState({ bookingDetails: bookingDetails })
  }
  setPaymentViaType(setFieldValue, value) {
    isFormChanged = true
    const bookingDetails = { ...this.state.bookingDetails }
    bookingDetails.collect_via = value
    this.setState({ bookingDetails: bookingDetails })
  }
  getPaymentVia(via) {
    let label
    this.paymentCollectVia.forEach((t) => {
      if (t.value === via) {
        label = t.label
      }
    })
    return label
  }
  selectMinimumAmountType(value) {
    isFormChanged = true
    let type = ''
    if (value === 'percentage') {
      type = 'percentage'
    } else if (value === 'amount') {
      type = 'amount'
    }
    const bookingDetails = { ...this.state.bookingDetails }
    bookingDetails.minimum_payment_type = type
    this.setState({ bookingDetails: bookingDetails })
  }
  cancelAppointment() {
    this.setState({ showCancelconfirm: true })
  }
  closeConfirmation() {
    this.setState({ showCancelconfirm: false })
  }
  async handleCancelAppointment() {
    this.setState({ cancelProgress: true })
    await this.props.cancelIncompleteBooking()
    this.setState({ showCancelconfirm: false, cancelProgress: false })
    this.props.close()
  }
  async checkForNewService(rate) {
    if (this.state.newService && rate) {
      const payload = {
        user_id: this.props.me && this.props.me._id,
        price: rate,
        title: this.state.newService && this.state.newService.label,
        is_business_hours: true,
        source: 'direct'
      }
      const addedSer = await this.props.createService(payload)
      await this.props.getServices({ nopaging: true, sort: 'sort_order', asc: true })
      if (addedSer) {
        const data = {
          label: addedSer.title,
          value: addedSer._id,
          dataObj: addedSer
        }
        this.getDateSlots(null, data, 'service_id')
      }
    }
  }
  render() {
    console.log('SERVICES: ', this.serviceLocations)
    const options =
      this.props.services && this.props.services.length
        ? this.props.services.map((item) => ({ value: item._id, label: item.title, dataObj: item }))
        : []

    const filterOptions = (inputValue) => {
      return options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()))
    }

    const getCustomFieldsSchema = (customFields = []) => {
      return {
        custom_fields: Yup.object().shape({
          ...customFields.reduce((prev, curr) => {
            const schema = {
              text: Yup.string(),
              number: Yup.number().typeError(
                t('bookings.0-should-be-a-number', {
                  0: curr.name
                })
              ),
              boolean: Yup.boolean(),
              dropdown: Yup.array().of(Yup.string())
            }[curr.type]

            if (!schema) {
              return prev
            }

            prev[curr.name] = Yup.object().shape({
              value: curr.mandatory
                ? schema.required(
                    t('bookings.0-is-required', {
                      0: curr.name
                    })
                  )
                : schema
            })

            return prev
          }, {})
        })
      }
    }

    const getCustomFieldsDefaultValues = (customFields = [], bookingCustomFields = []) => {
      return {
        custom_fields: customFields.reduce((prev, curr) => {
          prev[curr.name] = {
            name: curr.name,
            type: curr.type,
            mandatory: curr.mandatory,
            custom_field_id: curr._id,
            value: {
              text: _.get(curr, 'text_extra.default_value', ''),
              number: _.get(curr, 'number_extra.default_value', null),
              boolean: _.get(curr, 'boolean_extra.default_value', false),
              dropdown: []
            }[curr.type]
          }

          const bookingCustomField = bookingCustomFields.find((item) => item.custom_field_id === curr._id)
          if (!bookingCustomField) {
            return prev
          }

          prev[curr.name].value = bookingCustomField.value
          return prev
        }, {})
      }
    }

    const loadServiceOptions = (inputValue) =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(filterOptions(inputValue))
        }, 1000)
      })

    const { service, bookingDetails } = this.state
    const disabledDates =
      service && service.dataObj && service.dataObj.disabled_date && service.dataObj.disabled_date.length
        ? service.dataObj.disabled_date.map((d) => new Date(d.replace(/-/g, '/')))
        : []

    const { customFields = [], editBookingDetails } = this.props
    console.log('customFields', customFields)
    return (
      <div className="add-contact-modal">
        <Popup
          open={this.props.open}
          title={
            this.props.bookingType && this.props.bookingType === 'edit'
              ? t('bookings.edit-booking')
              : t('bookings.add-booking-0')
          }
          cancelText={t('bookings.cancel')}
          onCancel={() => this.setState({ open: false })}
          type="default"
          overrideForm={true}
          modalBackdropClicked={this.handleCancel.bind(this)}
          id="modalAddBooking">
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
            initialValues={{
              first_name: bookingDetails && bookingDetails.first_name ? bookingDetails.first_name : '',
              last_name: bookingDetails && bookingDetails.last_name ? bookingDetails.last_name : '',
              customer_email: bookingDetails && bookingDetails.customer_email ? bookingDetails.customer_email : '',
              customer_phone: bookingDetails && bookingDetails.customer_phone ? bookingDetails.customer_phone : '',
              customername: bookingDetails && bookingDetails.customername ? bookingDetails.customername : '',
              photo: bookingDetails && bookingDetails.photo ? bookingDetails.photo : '',
              customer_email_phone:
                bookingDetails &&
                (bookingDetails.customer_email
                  ? bookingDetails.customer_email
                  : bookingDetails.customer_phone
                  ? bookingDetails.customer_phone
                  : ''),

              service_occurs_at:
                bookingDetails && bookingDetails.service_occurs_at
                  ? bookingDetails.service_occurs_at
                  : this.props.businessSettings && this.props.businessSettings.door_delivery
                  ? this.serviceLocations[1].value
                  : this.serviceLocations[0].value,
              service_address: bookingDetails && bookingDetails.address ? bookingDetails.address : '',
              service_city: bookingDetails && bookingDetails.city ? bookingDetails.city : '',
              service_state: bookingDetails && bookingDetails.state ? bookingDetails.state : '',
              service_zip: bookingDetails && bookingDetails.zip ? bookingDetails.zip : '',

              service_id: bookingDetails && bookingDetails.service_id ? bookingDetails.service_id : '',
              service_rate: bookingDetails && bookingDetails.service_rate ? bookingDetails.service_rate : '',
              date: bookingDetails && bookingDetails.date ? bookingDetails.date : '',
              selectedAppTime: bookingDetails && bookingDetails.selectedAppTime ? bookingDetails.selectedAppTime : '',
              customer_message:
                bookingDetails && bookingDetails.customer_message
                  ? bookingDetails.customer_message
                  : t('bookings.thanks-for-your-interest-in-our-services'),
              send_reminder:
                bookingDetails && bookingDetails.send_reminder !== (null || undefined)
                  ? bookingDetails.send_reminder
                  : true,
              service_address2: bookingDetails && bookingDetails.address2 ? bookingDetails.address2 : '',
              appointment_reminder_time:
                bookingDetails &&
                bookingDetails.appointment_reminder_time &&
                bookingDetails.appointment_reminder_time.length
                  ? bookingDetails.appointment_reminder_time
                  : this.state.service &&
                    this.state.service.dataObj &&
                    this.state.service.dataObj.appointment_reminder_time_new &&
                    this.state.service.dataObj.appointment_reminder_time_new.length
                  ? this.state.service.dataObj.appointment_reminder_time_new
                  : this.props.accountsettings &&
                    this.props.accountsettings.booking_reminder_time &&
                    this.props.accountsettings.booking_reminder_time.length
                  ? this.props.accountsettings.booking_reminder_time
                  : [{ hour: '00', min: '00', value: '00' }],
              service_quantity:
                bookingDetails && bookingDetails.service_quantity ? bookingDetails.service_quantity : '',

              appointment: bookingDetails && (bookingDetails.selectedAppTime || bookingDetails.date),

              appointment_reminder_flag: this.state.appointment_reminder_flag,
              isMobileChanged: false,
              isEmailChanged: false,
              isAddressChanged: false,
              send_email: bookingDetails && bookingDetails.send_email ? bookingDetails.send_email : false,
              send_sms: bookingDetails && bookingDetails.send_sms ? bookingDetails.send_sms : false,

              // new variables
              allow_partial_payment:
                bookingDetails && bookingDetails.hasOwnProperty('allow_partial_payment')
                  ? bookingDetails.allow_partial_payment
                  : false,
              collect_via:
                bookingDetails && bookingDetails.hasOwnProperty('collect_via')
                  ? bookingDetails.collect_via
                  : this.paymentCollectVia[0].value,
              minimum_payment_type:
                bookingDetails && bookingDetails.minimum_payment_type ? bookingDetails.minimum_payment_type : 'amount',
              minimum_payment_amount:
                bookingDetails && bookingDetails.minimum_payment_amount ? bookingDetails.minimum_payment_amount : '',
              minimum_payment_percentage:
                bookingDetails && bookingDetails.minimum_payment_percentage
                  ? bookingDetails.minimum_payment_percentage
                  : '',
              // isPayment:bookingDetails && bookingDetails.hasOwnProperty("isPayment") ? bookingDetails.isPayment : false,
              is_payment:
                bookingDetails && bookingDetails.hasOwnProperty('is_payment')
                  ? bookingDetails.is_payment
                  : !!(
                      this.props.bookingType === 'edit' &&
                      bookingDetails.status === 'incomplete' &&
                      bookingDetails.collect_via
                    ),
              selectedService: bookingDetails && bookingDetails.selectedService,
              payment_type:
                bookingDetails &&
                bookingDetails.hasOwnProperty('allow_partial_payment') &&
                (bookingDetails.allow_partial_payment ? 'partial' : 'full'),
              status: bookingDetails && bookingDetails.status ? bookingDetails.status : '',
              booking_id: bookingDetails && bookingDetails._id ? bookingDetails._id : '',
              // new variables till here

              // customer_id: bookingDetails && bookingDetails.customer_id ? bookingDetails.customer_id : "",
              contact_id: bookingDetails && bookingDetails.contact_id ? bookingDetails.contact_id : '',
              ...getCustomFieldsDefaultValues(customFields, _.get(editBookingDetails, 'custom_fields', undefined))
            }}
            validationSchema={
              this.props.bookingType === 'new' ||
              (this.props.bookingType === 'edit' && bookingDetails.status === 'incomplete')
                ? Yup.object().shape({
                    // customer_email: Yup.string().email("Please enter valid email"),
                    // customer_phone: Yup.string().matches(/^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/, "Only US phone numbers without country code are permitted.eg : (500) 555-0006"),
                    date: Yup.date().required(t('bookings.required')),
                    selectedAppTime: Yup.string().required(t('bookings.required')),
                    service_id: Yup.string().required(t('bookings.required')),
                    send_reminder: Yup.string(),
                    service_quantity: Yup.string().test(
                      'service_quantity',
                      t('bookings.invalid-quantity'),
                      function (value) {
                        const serviceQuantity = this.parent.service_quantity
                        if (serviceQuantity === '0' || !serviceQuantity) {
                          return false
                        }
                        return true
                      }
                    ),
                    customer_email_phone: Yup.string().test(
                      'customer_email',
                      t('bookings.email-or-phone-number-required'),
                      function (value) {
                        const customerEmail = this.parent.customer_email
                        const customerPhone = this.parent.customer_phone
                        if (!customerPhone && !customerEmail) return value != null
                        return true
                      }
                    ),
                    service_address: Yup.string().test(
                      'service_address',
                      t('bookings.customer-address-is-required'),
                      function (value) {
                        const { service_occurs_at } = this.parent
                        if (service_occurs_at === 'customer_address') {
                          return value
                        }
                        return true
                      }
                    ),
                    appointment: Yup.string().required(t('bookings.required')).nullable(),
                    minimum_payment_percentage: Yup.string().test(
                      'minimum_payment_percentage',
                      t('bookings.entered-amount-should-be-equal-or-greater-than-minimum-payment'),
                      function (value) {
                        const minimumPaymentType = this.parent.minimum_payment_type
                        const allowPartialPayment = this.parent.allow_partial_payment
                        const isPayment = this.parent.is_payment
                        if (allowPartialPayment && minimumPaymentType === 'percentage' && isPayment) {
                          if (this.parent.selectedService.minimum_payment_amount) {
                            const amt = this.parent.service_quantity * this.parent.service_rate
                            const perAmt = (value * amt) / 100
                            if (perAmt < this.parent.selectedService.minimum_payment_amount) {
                              return false
                            }
                          }
                          if (
                            this.parent.selectedService.minimum_payment_percentage &&
                            this.parent.selectedService.minimum_payment_percentage > value
                          ) {
                            return false
                          }
                          return value
                        }
                        return true
                      }
                    ),
                    minimum_payment_amount: Yup.string().test(
                      'minimum_payment_amount',
                      t('bookings.entered-amount-should-be-equal-or-greater-than-minimum-payment'),
                      function (value) {
                        const minimumPaymentType = this.parent.minimum_payment_type
                        const allowPartialPayment = this.parent.allow_partial_payment
                        const isPayment = this.parent.is_payment
                        if (allowPartialPayment && minimumPaymentType === 'amount' && isPayment) {
                          if (this.parent.selectedService.minimum_payment_percentage) {
                            const amt = this.parent.service_quantity * this.parent.service_rate
                            const perAmt = (value / amt) * 100
                            if (perAmt < this.parent.selectedService.minimum_payment_percentage) {
                              return false
                            }
                          }
                          if (
                            this.parent.selectedService.minimum_payment_amount &&
                            this.parent.selectedService.minimum_payment_amount > value
                          ) {
                            return false
                          }
                          return value
                        }
                        return true
                      }
                    ),
                    ...getCustomFieldsSchema(customFields)
                  })
                : this.props.bookingType === 'edit'
                ? Yup.object().shape({
                    date: Yup.date().required(t('bookings.required')),
                    selectedAppTime: Yup.string().required(t('bookings.required')),
                    service_id: Yup.string().required(t('bookings.required')),
                    send_reminder: Yup.string(),
                    service_quantity: Yup.string().test(
                      'service_quantity',
                      t('bookings.invalid-quantity'),
                      function (value) {
                        const serviceQuantity = this.parent.service_quantity
                        if (serviceQuantity === '0' || !serviceQuantity) {
                          return false
                        }
                        return true
                      }
                    ),
                    customer_email_phone: Yup.string().test(
                      'customer_email',
                      t('bookings.email-or-phone-number-required'),
                      function (value) {
                        const customerEmail = this.parent.customer_email
                        const customerPhone = this.parent.customer_phone
                        if (!customerPhone && !customerEmail) return value != null
                        return true
                      }
                    ),
                    service_address: Yup.string().test(
                      'service_address',
                      t('bookings.customer-address-is-required'),
                      function (value) {
                        const { service_occurs_at } = this.parent
                        if (service_occurs_at === 'customer_address') {
                          return value
                        }
                        return true
                      }
                    ),
                    appointment: Yup.string().required(t('bookings.required')).nullable(),
                    ...getCustomFieldsSchema(customFields)
                  })
                : null
            }
            onReset={this.handleCancel.bind(this)}
            onSubmit={this.handleSubmit.bind(this)}
            render={({ values, setFieldValue, resetForm, errors }) => {
              console.log(values.customFields)
              return (
                <div className="mt-20">
                  {errors.test && console.log('errors', errors)}
                  <Form className={`custom-add-booking-form ${style['complete-transaction-form']} `}>
                    <button type="reset" className={'close custom-modal-close-button '}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                    {this.props.serviceLoading ? (
                      <div
                        className={`position-absolute top-0 right-0 z-index-2 bottom-0 left-0 d-flex align-items-center justify-content-center ${style['form-loader']}`}>
                        <img src="https://dufzo4epsnvlh.cloudfront.net/image/loader-dot.svg" alt="loader" />
                      </div>
                    ) : null}
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                          <label className="label-text">
                            {t('bookings.contact-0')} <span className="star">*</span>
                            <ErrorMessage name="customer_email_phone" component="span" className={'form-error'} />
                          </label>
                          {!this.state.hasContact ? (
                            <AsyncCreatableSelect
                              id="addBookingContact"
                              name="contacts"
                              isClearable
                              placeholder={t('bookings.type-name-or-enter-email-phone-number')}
                              className="dropdown-select no-arrow-down"
                              classNamePrefix="dropdownSelect"
                              loadOptions={this.autoSuggestContacts}
                              value={
                                this.state.selectedValue
                                  ? {
                                      label: this.state.selectedValue.label,
                                      value: this.state.selectedValue.value,
                                      image: this.state.selectedValue.image
                                    }
                                  : []
                              }
                              formatOptionLabel={this.customOptions}
                              noOptionsMessage={() => null}
                              onChange={(event, type) =>
                                this.handleSelectOption(setFieldValue, event, 'contacts', type)
                              }
                              formatCreateLabel={(userInput) => (
                                <div className="react-select-unavailable-data">
                                  <span className="react-select-highlight-text">
                                    {t('bookings.create-new-contact')}
                                  </span>
                                </div>
                              )}
                              isValidNewOption={(inputValue, selectValue, selectOptions) =>
                                this.createNewVisible(selectOptions)
                              }
                            />
                          ) : null}
                          {this.state.hasContact ? (
                            <div className="selected-user border rounded-sm">
                              <div className="row-user-contact border-bottom d-flex align-items-center position-relative">
                                <div className="user-contact pl-15">{t('bookings.contact')}</div>
                                {this.state.bookingDetails &&
                                !this.state.bookingDetails.customer_first_name &&
                                !this.state.bookingDetails.customer_last_name ? (
                                  <React.Fragment>
                                    <div className="user-phone-value pr-25">
                                      <Field
                                        type="text"
                                        name="customername"
                                        className="form-control form-control-lg"
                                        placeholder={'(Optional)'}
                                        value={values.customername}
                                        onChange={(event) => {
                                          // setFieldValue("customername", event.target.value);
                                          // this.setState({ customName: event.target.value })
                                          this.updateField('customername', event.target.value)
                                        }}
                                      />
                                    </div>
                                    <div className="position-absolute cursor-pointer right-0 mr-15 icon-block">
                                      <Icon
                                        onClick={this.removeContact.bind(this)}
                                        name="closeThin"
                                        className="stroke-icon d-block"
                                      />
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {this.state.bookingDetails &&
                                      (this.state.bookingDetails.first_name || this.state.bookingDetails.last_name) && (
                                        <figure className={`mb-0 mr-10 ml-30 ${style['b-image']}`}>
                                          <img
                                            className={`w-100 rounded-circle ${style['image']}`}
                                            src={
                                              this.state.bookingDetails && this.state.bookingDetails.photo
                                                ? this.state.bookingDetails.photo
                                                : 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'
                                            }
                                            alt=""
                                          />
                                        </figure>
                                      )}
                                    <div
                                      className="user-names d-flex justify-content-between"
                                      style={{
                                        width:
                                          this.state.bookingDetails &&
                                          !this.state.bookingDetails.last_name &&
                                          !this.state.bookingDetails.first_name &&
                                          '60%'
                                      }}>
                                      {this.state.bookingDetails && this.state.bookingDetails.first_name && (
                                        <span>{this.state.bookingDetails.first_name}</span>
                                      )}
                                      {this.state.bookingDetails && this.state.bookingDetails.last_name && (
                                        <span className="mr-auto pl-5">{this.state.bookingDetails.last_name}</span>
                                      )}
                                      <Icon
                                        onClick={this.removeContact.bind(this)}
                                        name="closeThin"
                                        className="stroke-icon"
                                      />
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                              <div className="row-user-email border-bottom d-flex align-items-center position-relative">
                                <div className="user-email pl-15">{t('bookings.email-0')}</div>
                                <div className="user-email-value pr-20">
                                  <Field
                                    type="text"
                                    name="email"
                                    className="form-control form-control-lg"
                                    placeholder={
                                      this.state.bookingDetails.customer_phone
                                        ? '(Optional)'
                                        : t('bookings.enter-email')
                                    }
                                    value={this.state.bookingDetails.customer_email}
                                    onChange={(event) => {
                                      setFieldValue('email', event.target.value)
                                      const obj = { ...this.state.bookingDetails }
                                      if (event.target.value) {
                                        obj.send_email = true
                                      } else {
                                        obj.send_email = false
                                      }
                                      obj.customer_email = event.target.value
                                      isFormChanged = true
                                      this.setState({ bookingDetails: obj })
                                    }}
                                  />
                                </div>
                                {this.state.bookingDetails &&
                                this.state.selectedContact &&
                                this.state.selectedContact.email &&
                                this.state.bookingDetails.customer_email !== this.state.selectedContact.email ? (
                                  <div
                                    className=" position-absolute cursor-pointer right-0 mr-15"
                                    onClick={this.setOriginalValues.bind(this, setFieldValue, 'email')}>
                                    <Tooltip position="left" message={t('bookings.revert-to-email-on-file')}>
                                      <Icon name="undoThin" className={`${style['undo']}`} />
                                    </Tooltip>
                                  </div>
                                ) : null}
                              </div>
                              <div className="row-user-phone  d-flex align-items-center position-relative">
                                <div className="user-phone pl-15">{t('bookings.phone-0')}</div>
                                <div className="user-phone-value pr-20">
                                  <Field
                                    type="text"
                                    name="mobile"
                                    className="form-control form-control-lg"
                                    placeholder={
                                      this.state.bookingDetails.customer_email
                                        ? t('bookings.optional')
                                        : t('bookings.enter-mobile')
                                    }
                                    value={values.customer_phone}
                                    maxLength={14}
                                    onChange={(event) => {
                                      setFieldValue('mobile', numberMask(event.target.value))
                                      const obj = Object.assign(this.state.bookingDetails)
                                      if (event.target.value && this.state.twilioEnabled) {
                                        obj.send_sms = true
                                      } else {
                                        obj.send_sms = false
                                      }
                                      obj.customer_phone = numberMask(event.target.value)
                                      isFormChanged = true
                                      this.setState({ bookingDetails: obj })
                                    }}
                                  />
                                </div>
                                {this.state.bookingDetails &&
                                this.state.selectedContact &&
                                this.state.selectedContact.mobile &&
                                this.state.bookingDetails.customer_phone !==
                                  phoneNumberFormat(this.state.selectedContact.mobile.replace('+1')) ? (
                                  <div
                                    className="position-absolute cursor-pointer right-0 mr-15"
                                    onClick={this.setOriginalValues.bind(this, setFieldValue, 'mobile')}>
                                    <Tooltip position="left" message={t('bookings.revert-to-phone-on-file')}>
                                      <Icon name="undoThin" className={`${style['undo']}`} />
                                    </Tooltip>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide location-block">
                          <label className="label-text">
                            LOCATION <span className="star">*</span>
                            <ErrorMessage name="service_address" component="span" className={'form-error'} />
                          </label>
                          <div className="booking-detail border rounded-sm">
                            <div className="row-send-via border-bottom d-flex align-items-center">
                              <div className="send-via px-15">{t('bookings.service-occurs-at')}</div>
                              <div className="send-via-value position-relative">
                                <div
                                  className="send-via-value-item px-15 cursor-pointer d-flex align-items-center justify-content-between"
                                  onClick={() =>
                                    this.setState({ editServiceLocation: !this.state.editServiceLocation })
                                  }>
                                  <span>
                                    {this.state.selectedServiceLocation ? this.state.selectedServiceLocation.label : ''}
                                  </span>
                                  <div className={`${style['arrow-icon']}`}>
                                    <Icon name="arrowDownThin" />
                                  </div>
                                </div>
                                {this.state.editServiceLocation && (
                                  <div className="dropdown-send-via-menu position-absolute right-0  bg-white">
                                    {this.serviceLocations.map((location) => (
                                      <div
                                        key={location.id}
                                        className="dropdown-send-via-menu-item border-bottom px-15 py-10"
                                        onClick={() => {
                                          const bookingDetails = { ...this.state.bookingDetails }
                                          bookingDetails.service_occurs_at = location.value
                                          isFormChanged = true
                                          this.setState({
                                            selectedServiceLocation: location,
                                            bookingDetails: bookingDetails,
                                            editServiceLocation: false
                                          })
                                        }}>
                                        <div className="form-group d-flex m-0">{location.label}</div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                            {this.state.selectedServiceLocation &&
                            this.state.selectedServiceLocation.value === 'customer_address' ? (
                              <React.Fragment>
                                <div className="border-bottom d-flex align-items-center">
                                  <div className="due-date px-15 ">
                                    {t('bookings.customers-address')}
                                    <span className="star ml-3">*</span>
                                  </div>
                                  <div className="due-date-value d-flex">
                                    <div className="w-100 pl-15 due-date-value-date d-flex align-items-center justify-content-between">
                                      <div className="dropdown position-relative w-100">
                                        <GooglePlacesAutocomplete
                                          apiKey={config.googlePlacesKey}
                                          autocompletionRequest={{
                                            // bounds: [
                                            //     { lat: 50, lng: 50 },
                                            //     { lat: 100, lng: 100 }
                                            // ],
                                            componentRestrictions: {
                                              country: ['us']
                                            },
                                            types: ['address']
                                          }}
                                          selectProps={{
                                            className:
                                              'dropdown-select no-arrow-down child-no-border-no-padd customers-address',
                                            classNamePrefix: 'dropdownSelect',
                                            placeholder: t('bookings.enter-address'),
                                            value: this.state.selectedLocation,
                                            onChange: this.getAddress.bind(this),
                                            noOptionsMessage: () => null
                                            // noOptionsMessage: ({ inputValue }) =>
                                            //     inputValue ? (
                                            //         <div className="py-5" onClick={() => {
                                            //         /*do whatever you need, e.g. set this value somewhere `*/
                                            //             this.setState({
                                            //                 selectedLocation:{label:inputValue,value:inputValue}
                                            //             })
                                            //             this.updateField("address",inputValue)
                                            //         }}>
                                            //       Add this address
                                            //         </div>
                                            //     ) : null
                                          }}
                                        />
                                      </div>
                                    </div>

                                    {this.state.selectedLocation && this.state.selectedLocation.label ? (
                                      <div className="d-flex align-items-center mr-15">
                                        <button
                                          type="button"
                                          className={'btn border-0 bg-white px-0 icon-block'}
                                          onClick={() => {
                                            const bookingDetails = { ...this.state.bookingDetails }
                                            bookingDetails.address = ''
                                            bookingDetails.city = ''
                                            bookingDetails.state = ''
                                            bookingDetails.country = ''
                                            bookingDetails.zip = ''
                                            bookingDetails.address2 = ''
                                            isFormChanged = true
                                            this.setState({ bookingDetails: bookingDetails, selectedLocation: null })
                                          }}>
                                          {/* <span aria-hidden="true">&times;</span> */}
                                          <Icon name="closeThin" className={style['stroke-icon']} />
                                        </button>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className={'b-card-item-small'}>
                                    <div className="d-block h-100 px-15">
                                      <div className="d-flex align-items-center">
                                        <span className="line-height-normal">{t('bookings.unit-optional')}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={'b-card-item-large px-15'}>
                                    <Field
                                      type="text"
                                      placeholder={t('bookings.apartment-suite-etc')}
                                      className="form-control form-control-lg border-0 pl-0 pr-30 w-100 input-optional"
                                      name="service_address2"
                                      // values={values.service_address2}
                                      onChange={(event) => {
                                        isAddressChanged = true
                                        this.updateField('address2', event.target.value)
                                      }}
                                    />
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                          <label className="label-text">
                            {t('bookings.add-a-service')}
                            <ErrorMessage name="service_id" component="span" className={'form-error'} />
                            <ErrorMessage name="service_quantity" component="span" className={'form-error'} />
                          </label>
                          {/* {this.props.serviceLoading || (options && options.length) ? ( */}
                          <div className={`${style['scroll-for-mobile']}`}>
                            <table className="table table-add-booking rounded-sm">
                              <thead>
                                <tr>
                                  <th>{t('bookings.item')}</th>
                                  <th className="text-right qty">QTY</th>
                                  <th className="text-right rate">{t('bookings.rate')}</th>
                                  <th className="text-right amount">{t('bookings.amount-0')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="td-item-label td-item-label-name position-relative">
                                    <div className="d-flex justify-content-between">
                                      <div className="flex-grow-1 pr-15 ">
                                        <div className="item-name">
                                          <AsyncCreatableSelect
                                            closeMenuOnSelect={true}
                                            defaultOptions={options}
                                            loadOptions={loadServiceOptions}
                                            isMulti={false}
                                            className="dropdown-select dropdown-select-add-services"
                                            classNamePrefix="dropdownSelect"
                                            placeholder={t('bookings.choose-a-service')}
                                            name="service_id"
                                            value={this.state.service}
                                            maxMenuHeight="120px"
                                            noOptionsMessage={() => t('bookings.no-services-0')}
                                            onChange={(e) => this.getDateSlots(setFieldValue, e, 'service_id')}
                                            components={{ DropdownIndicator }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="td-item-label td-item-label-qty text-right light-gray">
                                    {this.state.service ? (
                                      <Field
                                        type="text"
                                        name="service_quantity"
                                        placeholder="--"
                                        className="form-control form-control-lg"
                                        onChange={(event) => {
                                          const { value } = event.target
                                          const regex = /^[0-9]*$/
                                          if (regex.test(value.toString()) || value.length === 0) {
                                            this.updateField('service_quantity', value)
                                          }
                                        }}
                                      />
                                    ) : null}
                                  </td>
                                  <td className="td-item-label td-item-label-rate text-right light-gray">
                                    {this.state.service ? (
                                      <>
                                        {!this.state.showRateField ? (
                                          <div
                                            className={'service_rate_label cursor-pointer'}
                                            onClick={() => {
                                              this.setState({ showRateField: true })
                                            }}>
                                            ${UsCurrencyFormatter(parseFloat(values.service_rate))}
                                          </div>
                                        ) : null}
                                        {this.state.showRateField ? (
                                          <Field
                                            type="text"
                                            name="service_rate"
                                            placeholder="--"
                                            autoFocus
                                            className="form-control form-control-lg"
                                            maxLength={config.amountFieldLength}
                                            onChange={(event) => {
                                              const { value } = event.target
                                              const regex = /^(0*[1-9][0-9]*(\.[0-9]{0,2})?|0*\.[0-9]{0,2})$/
                                              if (regex.test(value.toString()) || value.length === 0) {
                                                this.updateField('service_rate', value)
                                              }
                                            }}
                                            onBlur={() => {
                                              this.checkForNewService(values.service_rate)
                                              this.setState({ showRateField: false })
                                            }}
                                          />
                                        ) : null}
                                      </>
                                    ) : null}
                                  </td>
                                  <td className="text-right td-item-label-total">
                                    {this.state.service ? (
                                      <div className="position-relative">
                                        $
                                        {UsCurrencyFormatter(
                                          parseInt(values.service_quantity) * parseFloat(values.service_rate)
                                        )}
                                        <div className="position-absolute remove-item">
                                          {this.state.service && this.state.service.value ? (
                                            <i
                                              onClick={(event) => {
                                                const bookingDetails = { ...this.state.bookingDetails }
                                                bookingDetails.date = null
                                                bookingDetails.appointment = ''
                                                bookingDetails.selectedAppTime = ''
                                                bookingDetails.service_id = ''
                                                bookingDetails.service_quantity = ''
                                                bookingDetails.service_rate = ''
                                                isFormChanged = true
                                                this.setState({
                                                  service: null,
                                                  bookingDetails: bookingDetails,
                                                  appointment_reminder_flag: false,
                                                  date: null,
                                                  selectedSlot: null
                                                })
                                              }}
                                              className={'mx-10 top-minus-2'}>
                                              <Icon name="removeMinus" />
                                            </i>
                                          ) : null}
                                        </div>
                                      </div>
                                    ) : null}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          {/* ) : null} */}

                          {/* NO SERVICE FOUND */}
                          {/* {!this.props.serviceLoading && options && options.length === 0 ? (
                            <div>
                              <button
                                onClick={this.goToServicePage.bind(this)}
                                type="button"
                                className="btn btn-sm d-flex text-primary align-items-center mb-0">
                                <strong className="mr-5">+</strong>
                                CREATE SERVICE
                              </button>
                            </div>
                          ) : null} */}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide appointment-block">
                          <label className="label-text">
                            {t('bookings.appointment')} <span className="star">*</span>
                            <ErrorMessage name="appointment" component="span" className={'form-error'} />
                          </label>
                          <div className="booking-detail border rounded-sm">
                            <div className="row-due-date border-bottom d-flex align-items-center">
                              <div className="due-date px-15">{t('bookings.date')}</div>
                              <div className="due-date-value">
                                <div
                                  className={`px-15 due-date-value-date d-flex align-items-center justify-content-between ${
                                    !this.state.service || this.state.newService ? 'cursor-not-allowed' : ''
                                  }  `}
                                  onClick={() => {
                                    if (this.state.service) {
                                      this.setState({ editAppointmentDate: !this.state.editAppointmentDate })
                                    }
                                  }}>
                                  <span className={this.state.date ? 'date-added' : ''}>
                                    {this.state.date
                                      ? moment(this.state.date).format('L')
                                      : t('bookings.choose-a-date')}
                                  </span>
                                </div>
                                {this.state.editAppointmentDate && this.state.service && !this.state.newService ? (
                                  <OnClickOutside
                                    handleClickOutside={() => this.setState({ editAppointmentDate: false })}>
                                    <div
                                      id="datePickerContainer"
                                      className="due-date-calendar react-datepicker-popper hide-outside-dates d-sm-flex pt-10 pl-10 pr-15 pb-0 z-index-2">
                                      <DatePicker
                                        inline
                                        id="sendInvoice_DatePicker"
                                        // selected={values.dueDate}
                                        dateFormat="MM/dd/yyyy"
                                        className="form-control border-0 p-0 datepicker"
                                        name="dueDate"
                                        onChange={(e) => this.dateChanged(setFieldValue, e, 'date', false)}
                                        minDate={changeTimezone(
                                          new Date(),
                                          this.props.userTimeZone && this.props.userTimeZone.key
                                        )}
                                        disabled={!this.state.service}
                                        maxDate={this.state.maxDate}
                                        excludeDates={disabledDates}
                                        filterDate={this.isOffDay.bind(this)}
                                      />
                                    </div>
                                  </OnClickOutside>
                                ) : null}
                              </div>
                            </div>

                            <div className="border-bottom d-flex align-items-center">
                              <div className="due-date px-15">{t('bookings.time')}</div>
                              <div
                                className={`due-date-value ${
                                  !this.state.service || this.state.newService ? 'cursor-not-allowed' : ''
                                } `}>
                                <div className="service-slot-dropdown-section position-relative">
                                  <div className="d-flex justify-content-between">
                                    <div className="flex-grow-1 pl-15">
                                      <div className="item-name">
                                        {this.state.slotLoading ? (
                                          <div
                                            className={
                                              'd-flex align-item-center justify-content-start card-row-item-height'
                                            }>
                                            <img
                                              src="https://dufzo4epsnvlh.cloudfront.net/image/loader-dot.svg"
                                              alt="loader"
                                            />
                                          </div>
                                        ) : (
                                          <Select
                                            closeMenuOnSelect={true}
                                            options={this.state.newSlots}
                                            isMulti={false}
                                            placeholder={t('bookings.choose-a-time')}
                                            name="selectedAppTime"
                                            className="dropdown-select dropdown-select-time"
                                            classNamePrefix="dropdownSelect"
                                            isDisabled={!(this.state.newSlots && this.state.newSlots.length)}
                                            value={this.state.selectedSlot}
                                            maxMenuHeight="200px"
                                            noOptionsMessage={() => t('bookings.no-slots-available')}
                                            onChange={(event) => {
                                              this.setSelectedTimeSlot(setFieldValue, event, 'selectedAppTime')
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex border-bottom align-items-start py-5 py-sm-0">
                              <div className={'due-date d-flex align-items-center card-row-item-height'}>
                                <div className="d-flex align-items-center h-100 px-15">
                                  <span className="line-height-normal mr-10">{t('bookings.customer-reminders')}</span>
                                </div>
                              </div>
                              <div
                                className={`due-date-value d-flex align-items-center justify-content-between px-15 card-row-item-height ${
                                  !this.state.service ? 'cursor-not-allowed' : ''
                                }`}>
                                {!values.appointment_reminder_flag ? (
                                  <button
                                    type="button"
                                    disabled={!this.state.service}
                                    className={`border-0 bg-white link-color px-0 line-height-normal text-left ${
                                      !this.state.service ? 'cursor-not-allowed' : ''
                                    }`}
                                    onClick={() => {
                                      this.setState({ appointment_reminder_flag: true })
                                      isFormChanged = true
                                    }}>
                                    {t('bookings.add-reminder-time')}
                                  </button>
                                ) : null}
                                {values.appointment_reminder_flag ? (
                                  <div className="w-100">
                                    {values.appointment_reminder_time.length !== 0
                                      ? values.appointment_reminder_time.map((item, index) => (
                                          <div
                                            key={index}
                                            className={
                                              'd-flex align-items-center justify-content-between b-add-reminder-time'
                                            }>
                                            <div className="mr-10 d-flex align-items-center">
                                              <TimeInput
                                                time={this.DurationtoString(item.value)}
                                                handleChange={this.handleReminderTimeChange.bind(
                                                  this,
                                                  setFieldValue,
                                                  values.appointment_reminder_time,
                                                  index
                                                )}
                                                placeholder="00:00"
                                                id={`frm_addService_txtReminder_${index}`}
                                                isTimeLimited={false}
                                              />
                                              <span className="line-height-normal ">
                                                {index === 0 ? t('bookings.before-appt') : ''}
                                              </span>
                                            </div>
                                            <div
                                              className={`d-flex align-items-center h-100 b-action-button ${style['icon-styles']}`}>
                                              {/* {values.appointment_reminder_time.length > 1 ? */}
                                              <i
                                                onClick={(event) => {
                                                  this.onRemoveReminderTime(setFieldValue, values, index)
                                                }}
                                                className={'btn-icon d-flex top-minus-1  button-remove cursor-pointer'}>
                                                <Icon name="trashEmpty" />
                                              </i>
                                              {/* : null} */}
                                              {index === values.appointment_reminder_time.length - 1 ? (
                                                <div className="ml-10">
                                                  <Tooltip
                                                    position="bottom"
                                                    type="button"
                                                    message={t('bookings.add-appointment-reminder-time')}>
                                                    <i
                                                      onClick={() => {
                                                        const value = [
                                                          ...values.appointment_reminder_time,
                                                          { hour: '00', min: '00', value: '00' }
                                                        ]
                                                        setFieldValue('appointment_reminder_time', value)
                                                        this.updateField('appointment_reminder_time', value)
                                                      }}
                                                      className={'btn-icon d-flex top-2 button-add cursor-pointer'}>
                                                      <Icon name="plusThin" />
                                                    </i>
                                                  </Tooltip>
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        ))
                                      : null}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="row-due-date d-flex align-items-center py-5 py-sm-0">
                              <div className="due-date px-15">{t('bookings.message-0')}</div>
                              <div
                                className={`due-date-value ${
                                  !this.state.service || this.state.newService ? 'cursor-not-allowed' : ''
                                } `}>
                                <div className="item-name">
                                  <div className="user-appointment-message">
                                    <Field
                                      type="text"
                                      name="appoinment-message"
                                      className={`border-0 form-control form-control-lg ${style['message-textarea']} ${
                                        !this.state.service || this.state.newService ? 'cursor-not-allowed' : ''
                                      } `}
                                      component="textarea"
                                      placeholder={t('bookings.write-a-message')}
                                      value={
                                        this.state.bookingDetails.customer_message
                                          ? this.state.bookingDetails.customer_message
                                          : t('bookings.thanks-for-your-interest-in-our-services')
                                      }
                                      onChange={(event) => {
                                        this.updateField('customer_message', event.target.value)
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {customFields.length > 0 && (
                        <div className="col-12">
                          <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide appointment-block">
                            <label className="label-text">
                              {t('bookings.additional-information')} <span className="star">*</span>
                              <ErrorMessage name="test1" component="span" className={'form-error'} />
                            </label>

                            <FieldArray
                              name="customFields"
                              render={() => (
                                <div className="booking-detail border rounded-sm">
                                  {customFields.map((field, index) => {
                                    if (!values.custom_fields[field.name]) {
                                      return <></>
                                    }
                                    const fieldPath = `custom_fields.${field.name}.value`
                                    const fieldValue = values.custom_fields[field.name].value

                                    return (
                                      <div
                                        className="row-due-date border-bottom d-flex align-items-center col-12 col-sm-12"
                                        key={`fields_${index}`}>
                                        <div className="due-date ">
                                          {field.name}
                                          {field.mandatory && (
                                            <>
                                              <span className="star">*</span>
                                              <ErrorMessage
                                                name={fieldPath}
                                                component="span"
                                                className={'form-error'}
                                              />{' '}
                                            </>
                                          )}
                                        </div>
                                        {field.type === 'text' && (
                                          <Field
                                            className="form-control form-control-md col-sm-6"
                                            type="text"
                                            name={fieldPath}
                                            style={{ border: '1px solid white' }}
                                            placeholder={field.name}
                                            onChange={(e) => {
                                              console.log('Text Selected!!', e.target.value, this)
                                              isFormChanged = true

                                              setFieldValue(fieldPath, e.target.value)
                                            }}
                                            value={fieldValue}
                                          />
                                        )}

                                        {field.type === 'number' && (
                                          <Field
                                            className="form-control form-control-md col-sm-6"
                                            type="text"
                                            name={fieldPath}
                                            placeholder={field.name}
                                            style={{ border: '1px solid white' }}
                                            onChange={(e) => {
                                              console.log('Number Selected!!', e.target.value)
                                              isFormChanged = true

                                              if (e.target.value === '') {
                                                setFieldValue(fieldPath, null)
                                                return
                                              }

                                              const re = /^-?\d*(\.)*(\d+)?$/
                                              if (re.test(e.target.value)) {
                                                setFieldValue(fieldPath, parseFloat(e.target.value))
                                              }
                                            }}
                                            value={fieldValue == null ? '' : fieldValue}
                                          />
                                        )}

                                        {field.type === 'boolean' && (
                                          <>
                                            <div className="radio mr-30 ml-15" style={{ display: 'inline-block' }}>
                                              <label>
                                                <input
                                                  type="radio"
                                                  name={fieldPath}
                                                  value="true"
                                                  className="mr-10"
                                                  checked={fieldValue}
                                                  onChange={(e) => {
                                                    isFormChanged = true
                                                    setFieldValue(fieldPath, true)
                                                  }}
                                                />
                                                {t('bookings.yes')}
                                              </label>
                                            </div>
                                            <div className="radio ml-30" style={{ display: 'inline-block' }}>
                                              <label>
                                                <input
                                                  type="radio"
                                                  name={fieldPath}
                                                  value="false"
                                                  className="mr-10"
                                                  checked={!fieldValue}
                                                  onChange={(e) => {
                                                    isFormChanged = true
                                                    setFieldValue(fieldPath, false)
                                                  }}
                                                />
                                                {t('bookings.no')}
                                              </label>
                                            </div>
                                          </>
                                        )}

                                        {field.type === 'dropdown' && fieldValue && (
                                          <div
                                            className="send-via-value position-relative col-sm-6"
                                            style={{ marginRight: '14%' }}>
                                            <div
                                              className="send-via-value-item px-15 cursor-pointer d-flex align-items-center justify-content-between"
                                              onClick={() =>
                                                this.setState({
                                                  [`toggleDropDown'${index}`]: !this.state[`toggleDropDown'${index}`]
                                                })
                                              }>
                                              {fieldValue.length === 0 ? (
                                                <span>
                                                  {t('bookings.select')} {field.name}
                                                </span>
                                              ) : fieldValue.length === 1 ? (
                                                <span>{fieldValue[0]}</span>
                                              ) : (
                                                <span>
                                                  {fieldValue.length} {t('bookings.selected')}
                                                </span>
                                              )}

                                              {this.state[`toggleDropDown'${index}`] ? (
                                                <div className={`${style['arrow-icon']}`}>
                                                  <Icon name="arrowUpThin" />
                                                </div>
                                              ) : (
                                                <div className={`${style['arrow-icon']}`}>
                                                  <Icon name="arrowDownThin" />
                                                </div>
                                              )}
                                            </div>
                                            {this.state[`toggleDropDown'${index}`] && (
                                              <div className="dropdown-send-via-menu position-absolute right-0  bg-white">
                                                {field.dropdown_extra.type === 'multiple' &&
                                                  field.dropdown_extra.options.map((option, key) => (
                                                    <div
                                                      key={option}
                                                      className="dropdown-send-via-menu-item border-bottom px-15 py-10"
                                                      onClick={() => {
                                                        console.log('Options clicked', option, fieldValue)

                                                        if (fieldValue.includes(option)) {
                                                          setFieldValue(
                                                            fieldPath,
                                                            fieldValue.filter((item) => item !== option)
                                                          )
                                                        } else {
                                                          setFieldValue(fieldPath, [...fieldValue, option])
                                                        }

                                                        isFormChanged = true
                                                        this.setState({
                                                          [`toggleDropDown'${index}`]:
                                                            !this.state[`toggleDropDown'${index}`]
                                                        })
                                                      }}>
                                                      <div className="form-group d-flex m-0">
                                                        <Field
                                                          type="checkbox"
                                                          className="h-cb mr-10"
                                                          name={option}
                                                          checked={fieldValue.includes(option)}
                                                        />
                                                        {option}
                                                      </div>
                                                    </div>
                                                  ))}

                                                {field.dropdown_extra.type === 'single' &&
                                                  field.dropdown_extra.options.map((option, key) => (
                                                    <div
                                                      key={key}
                                                      className="dropdown-send-via-menu-item border-bottom px-15 py-10"
                                                      onClick={(e) => {
                                                        console.log('Options clicked', option, fieldValue)

                                                        setFieldValue(fieldPath, [option])

                                                        isFormChanged = true
                                                        this.setState({
                                                          [`toggleDropDown'${index}`]:
                                                            !this.state[`toggleDropDown'${index}`]
                                                        })
                                                      }}>
                                                      <div className="form-group d-flex m-0">{option}</div>
                                                    </div>
                                                  ))}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    )
                                  })}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      )}
                      {/* Collect Payment at Checkout */}
                      <div className="col-12">
                        {(this.props && this.props.disabledProducts
                          ? !isDisabled('payment', this.props.disabledProducts) &&
                            this.props.me &&
                            this.props.me.account_settings &&
                            this.props.me.account_settings.payment &&
                            this.props.me.account_settings.payment.merchant_id &&
                            values.service_rate &&
                            parseFloat(values.service_rate) > 0
                          : this.props.selectedLocationProducts &&
                            this.props.selectedLocationProducts.length &&
                            this.props.selectedLocationProducts.includes('payment') &&
                            values.service_rate &&
                            parseFloat(values.service_rate) > 0) &&
                        (this.props.bookingType === 'new' ||
                          (this.props.bookingType === 'edit' &&
                            values.status === 'incomplete' &&
                            values.collect_via)) ? (
                          <div id="collectPaymentAccordion" role="tablist" className="form-group mb-15">
                            <div className="card" disabled={!this.state.payment_v2}>
                              <div
                                className={
                                  'card-header d-flex align-items-center justify-content-between px-15 py-3 border-0 accordion-card-header'
                                }
                                role="tab"
                                id="headingCollectPayment">
                                <div className="d-flex align-items-center">
                                  <h4 className="mb-0 fz-14 mr-10">{t('bookings.collect-advance-payment')}</h4>
                                </div>
                                <div className="form-switch form-group mb-0">
                                  <button
                                    className="btn p-0"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseCollectPayment"
                                    aria-expanded="false"
                                    aria-controls="collapseCollectPayment">
                                    <label>
                                      <Field
                                        type="checkbox"
                                        className={'form-control form-control-lg'}
                                        name="is_payment"
                                        checked={values.is_payment}
                                        onChange={(event) => {
                                          isFormChanged = true
                                          this.updateField('is_payment', event.target.checked)
                                        }}
                                      />
                                      <span className={'checkbox-icon'}></span>
                                    </label>
                                  </button>
                                </div>
                              </div>
                              <div
                                id="collapseCollectPayment"
                                className={`collapse ${values.is_payment ? 'show' : ''}`}
                                role="tabpanel"
                                aria-labelledby="headingCollectPayment">
                                <div className="card-body p-0">
                                  <div className="d-flex align-items-center border-top py-5 py-sm-0 position-relative">
                                    <div className={'w-50 d-flex align-items-center card-row-item-height'}>
                                      <div className="d-flex align-items-center h-100 px-15">
                                        <span className="line-height-normal">{t('bookings.amount-due')}</span>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        'd-flex align-items-center justify-content-between px-15 w-50 card-row-item-height'
                                      }>
                                      <span className="line-height-normal">
                                        {values.allow_partial_payment
                                          ? t('bookings.deposit-partial-payment-0')
                                          : t('bookings.full-payment')}
                                      </span>
                                      <div
                                        onClick={() => this.setState({ showPaymentDueTypeList: true })}
                                        className={
                                          'dropdown btn border-0 b-manage-dropdown position-absolute right-0 left-0 cursor-pointer'
                                        }
                                        id="">
                                        <div
                                          className="dropdown-toggle dropdown-toggle-manage btn-manage w-100 bg-transparent text-right"
                                          data-toggle="dropdown">
                                          <i className={`btn-icon ml-10 manage-icon-arrow ${style['arrow-icon']}`}>
                                            <Icon name="arrowDownThin" />
                                          </i>
                                        </div>
                                        {this.state.showPaymentDueTypeList ? (
                                          <OnClickOutside
                                            handleClickOutside={() => this.setState({ showPaymentDueTypeList: false })}>
                                            <ul className="dropdown-menu show dropdown-menu-manage dropdown-menu-right max-height-200 width-200 overflow-y-scrollbar">
                                              {this.amountDueTypes.map((type, index) => (
                                                <li
                                                  key={index}
                                                  className="primary-font px-15"
                                                  onClick={(e) => {
                                                    e.stopPropagation()
                                                    this.setPaymentDueType(setFieldValue, type.value)
                                                    this.setState({ showPaymentDueTypeList: false })
                                                  }}>
                                                  <span>{type.label}</span>
                                                </li>
                                              ))}
                                            </ul>
                                          </OnClickOutside>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                  {values.allow_partial_payment ? (
                                    <div className="d-flex align-items-center border-top py-5 py-sm-0">
                                      <div className={'w-50 d-flex align-items-center card-row-item-height'}>
                                        <div className="d-block h-100 px-15">
                                          <div className="d-flex align-items-center">
                                            <span className="line-height-normal">
                                              {values.allow_partial_payment ? t('bookings.partial-payment') : ''}
                                            </span>
                                            <span className="star text-danger position-relative mr-10">*</span>
                                            <div className="cursor-pointer position-relative top-minus-1">
                                              <Tooltip
                                                position="top"
                                                message={t(
                                                  'bookings.enter-the-minimum-payment-in-percentage-or-amount'
                                                )}
                                              />
                                            </div>
                                          </div>
                                          <ErrorMessage
                                            name={
                                              values.minimum_payment_type === 'percentage'
                                                ? 'minimum_payment_percentage'
                                                : 'minimum_payment_amount'
                                            }
                                            component="span"
                                            id="lbl_serviceAssignedUser"
                                            className="form-error mt-3 mb-5 ml-0"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          'd-flex align-items-center justify-content-between px-15 w-50 card-row-item-height'
                                        }>
                                        {values.minimum_payment_type === 'percentage' ? (
                                          <Field
                                            disabled={!(values.service_rate && parseFloat(values.service_rate) > 0)}
                                            id="frm_addService_txtPartialPayemnt"
                                            type="number"
                                            className={
                                              'form-control form-control-lg px-0 border-0 bg-white field-normal-width'
                                            }
                                            placeholder="0"
                                            value={values.minimum_payment_percentage}
                                            name="minimum_payment_percentage"
                                            onChange={(e) => {
                                              if (
                                                e.target.value === '' ||
                                                (e.target.value > 0 && e.target.value <= 100)
                                              ) {
                                                isFormChanged = true
                                                this.updateField('minimum_payment_percentage', e.target.value)
                                              }
                                            }}
                                          />
                                        ) : this.state.isEditing ? (
                                          <Field
                                            disabled={!(values.service_rate && parseFloat(values.service_rate) > 0)}
                                            id="frm_addService_txtPartialPayemnt"
                                            type="number"
                                            className={
                                              'form-control form-control-lg px-0 border-0 bg-white field-normal-width'
                                            }
                                            placeholder="0"
                                            value={values.minimum_payment_amount}
                                            name="minimum_payment_amount"
                                            onBlur={this.toggleEditing.bind(this)}
                                            onChange={(e) => {
                                              if (
                                                e.target.value === '' ||
                                                (e.target.value > 0 &&
                                                  parseFloat(e.target.value) <=
                                                    parseFloat(values.service_rate) * values.service_quantity)
                                              ) {
                                                isFormChanged = true
                                                this.updateField('minimum_payment_amount', e.target.value)
                                              }
                                            }}
                                          />
                                        ) : (
                                          <Field
                                            disabled={!(values.service_rate && parseFloat(values.service_rate) > 0)}
                                            id="frm_addService_txtPartialPayemnt"
                                            type="number"
                                            className={
                                              'form-control form-control-lg px-0 border-0 bg-white field-normal-width'
                                            }
                                            placeholder="0"
                                            value={UsCurrencyFormatter(Number(values.minimum_payment_amount))}
                                            name="minimum_payment_amount"
                                            onFocus={this.toggleEditing.bind(this)}
                                            readOnly
                                          />
                                        )}
                                        <div className={'d-flex align-items-center'}>
                                          <span>
                                            {values.minimum_payment_type === 'amount'
                                              ? '$'
                                              : values.minimum_payment_type === 'percentage'
                                              ? '%'
                                              : '$'}
                                          </span>
                                          <div
                                            onClick={() => this.setState({ showMinimumList: true })}
                                            className={'dropdown btn border-0 b-manage-dropdown pr-0 pl-0'}
                                            id="">
                                            <div
                                              className="dropdown-toggle dropdown-toggle-manage btn-manage w-100 bg-transparent"
                                              data-toggle="dropdown">
                                              <i className={`btn-icon ml-10 manage-icon-arrow ${style['arrow-icon']}`}>
                                                <Icon name="arrowDownThin" />
                                              </i>
                                            </div>
                                            {this.state.showMinimumList ? (
                                              <OnClickOutside
                                                handleClickOutside={() => this.setState({ showMinimumList: false })}>
                                                <ul
                                                  className={
                                                    'dropdown-menu show dropdown-menu-manage dropdown-menu-right max-height-200 overflow-y-scrollbar width-50 text-center small-dropdown'
                                                  }>
                                                  {this.amountTypes.map((type, index) => (
                                                    <li
                                                      key={index}
                                                      className="primary-font px-15"
                                                      onClick={(e) => {
                                                        e.stopPropagation()
                                                        this.selectMinimumAmountType(type.value)
                                                        this.setState({ showMinimumList: false })
                                                      }}>
                                                      <span>{type.label}</span>
                                                    </li>
                                                  ))}
                                                </ul>
                                              </OnClickOutside>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}

                                  <div className="d-flex align-items-center border-top py-5 py-sm-0 position-relative">
                                    <div className={'w-50 d-flex align-items-center card-row-item-height'}>
                                      <div className="d-flex align-items-center h-100 px-15">
                                        <span className="line-height-normal">{t('bookings.collect-via')}</span>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        'd-flex align-items-center justify-content-between px-15 w-50 card-row-item-height'
                                      }>
                                      <span className="line-height-normal">
                                        {this.getPaymentVia(values.collect_via)}
                                      </span>
                                      <div
                                        onClick={() => this.setState({ showPaymentTypeList: true })}
                                        className={
                                          'dropdown btn border-0 b-manage-dropdown position-absolute right-0 left-0 cursor-pointer'
                                        }
                                        id="">
                                        <div
                                          className="dropdown-toggle dropdown-toggle-manage btn-manage w-100 bg-transparent text-right"
                                          data-toggle="dropdown">
                                          <i className={`btn-icon ml-10 manage-icon-arrow ${style['arrow-icon']}`}>
                                            <Icon name="arrowDownThin" />
                                          </i>
                                        </div>
                                        {this.state.showPaymentTypeList ? (
                                          <OnClickOutside
                                            handleClickOutside={() => this.setState({ showPaymentTypeList: false })}>
                                            <ul className="dropdown-menu show dropdown-menu-manage dropdown-menu-right max-height-200  overflow-y-scrollbar">
                                              {this.paymentCollectVia.map((type, index) =>
                                                this.state.bookingDetails.payment_type === 'partial' &&
                                                type.value === 'invoice' ? null : (
                                                  <li
                                                    key={index}
                                                    className="primary-font px-15"
                                                    onClick={(e) => {
                                                      e.stopPropagation()
                                                      this.setPaymentViaType(setFieldValue, type.value)
                                                      this.setState({ showPaymentTypeList: false })
                                                    }}>
                                                    <span>{type.label}</span>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </OnClickOutside>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {/* end Collect Payment at Checkout */}

                      <div className="col-12 col-sm-12 col-md-12">
                        <div className="form-check">
                          <label id="gs-select-all" className="checkbox checkbox-animate no-label m-t-10">
                            <Field
                              type="checkbox"
                              className="h-cb"
                              name="send_reminder"
                              checked={values.send_reminder}
                              onChange={(event) => {
                                this.updateField('send_reminder', event.target.checked)
                              }}
                            />
                            <span className="checkbox-icon"></span>
                          </label>
                          <span className="ml-10">{t('bookings.send-me-the-reminders-via-sms')}</span>
                        </div>
                      </div>
                      {this.props.bookingType && this.props.bookingType === 'edit' ? (
                        <div className="col-12 col-sm-12 col-md-12 mt-10">
                          <div className="form-check">
                            <label id="gs-select-all" className="checkbox checkbox-animate no-label m-t-10">
                              <Field
                                type="checkbox"
                                className="h-cb"
                                name="notify_end_user"
                                checked={this.state.notify_end_user}
                                onChange={(event) => {
                                  // this.updateField("notify_end_user", event.target.checked)
                                  this.setState({ notify_end_user: event.target.checked })
                                }}
                              />
                              <span className="checkbox-icon"></span>
                            </label>
                            <span className="ml-10">
                              {t('bookings.notify-the-customer-about-the-changes-in-the-booking')}
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <footer className="d-block d-sm-flex mt-30">
                      <button
                        type="submit"
                        className="btn btn-primary border-0 btn-xl mb-10 mb-sm-0 mr-20 btn-block"
                        id="Add-Booking"
                        disabled={
                          this.props.processbooking ||
                          (this.props.bookingType === 'edit' && !isFormChanged) ||
                          (options && options.length === 0)
                        }>
                        {this.props.bookingType && this.props.bookingType === 'edit'
                          ? t('bookings.update-booking')
                          : t('bookings.continue')}
                        {this.props.processbooking ? (
                          <i className="btn-icon top-minus-2">
                            <img
                              className="spinner"
                              src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg"
                              alt=""
                            />
                          </i>
                        ) : null}
                      </button>
                      <button
                        type="reset"
                        data-dismiss="modal"
                        id="cancelPopup"
                        className="btn btn-xl m-0 cancel-btn custom-btn-width">
                        {t('bookings.cancel-0')}
                      </button>
                    </footer>
                  </Form>
                </div>
              )
            }}
          />
        </Popup>

        <ConfirmPopup
          id="modalConfirm_cancelAppointment"
          open={this.state.showCancelconfirm}
          title={t('bookings.cancel-appointment')}
          okText={t('bookings.yes-cancel-appointment')}
          cancelText="NO"
          onOk={this.handleCancelAppointment.bind(this)}
          onCancel={this.closeConfirmation.bind(this)}
          modalBackdropClicked={this.closeConfirmation.bind(this)}>
          <p>{t('bookings.do-you-want-to-cancel-this-appointment')}</p>
        </ConfirmPopup>
      </div>
    )
  }
}
