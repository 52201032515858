import * as service from './service'
import notification from '@components/notification'

export default {
  state: {
    accountSettingsError: false
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true,
        accountSettingsError: false,
        isThumbtackError: false
      }
    },
    onRequestUpadteBusinessName(state) {
      return {
        ...state,
        accountSettingsError: false
      }
    },
    onnoLoadingRequest(state) {
      return {
        ...state
      }
    },
    onError(state) {
      return {
        ...state,
        loading: false,
        accountSettingsError: true
      }
    },
    onSuccess(state) {
      return {
        ...state,
        loading: false
      }
    },
    onQBError(state, data) {
      let err = ''
      if (data && data.data && data.data.error) err = data.data.error
      notification('error', err)
      return {
        ...state,
        loading: false
      }
    },
    onThumbStackError(state, data) {
      if (data && data.data && data.data.error) notification('error', data.data.error)

      return {
        ...state,
        loading: false,
        isThumbtackError: true
      }
    },
    onFetchSettings(state, data) {
      let socialServices = []
      let enableSyncButton = false
      if (data && data.data && data.data.links) {
        socialServices = data.data.links
      }
      if (socialServices && socialServices.length) {
        socialServices.forEach((link) => {
          if (link.site === 'facebook' || link.site === 'google') {
            enableSyncButton = true
          }
        })
      }

      return {
        ...state,
        loading: false,
        accountsettings: data.data,
        socialServices: socialServices,
        enableSyncButton: enableSyncButton
      }
    },
    onupdateCustomLinkSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onupdateSesEmailSuccess(state, data) {
      notification('success', 'Sender email has been saved successfully')
      return {
        ...state,
        loading: false,
        senderEmailVerified: data.data.sender_email_verified
      }
    },
    onupdateGositeSettings(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onfetchwidgetsSucces(state, data) {
      return {
        ...state,
        loading: false,
        reviewwidgets: data
      }
    },
    onwidgetFavourateSucces(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onremoveReviewWidgetSucces(state, data) {
      notification('success', 'Widget has been deleted successfully')
      return {
        ...state,
        loading: false
      }
    },
    oncreateReviewWidgetSucces(state, data) {
      notification('success', 'Widget has been created successfully')
      return {
        ...state,
        newWidget: data,
        loading: false
      }
    },
    onUpdateReviewWidgetSucces(state, data) {
      notification('success', 'Widget has been updated successfully')
      return {
        ...state,
        newWidget: data,
        loading: false
      }
    },
    ongetReviewWidgetSucces(state, data) {
      return {
        ...state,
        selectedWidget: data,
        loading: false
      }
    },
    onresendSesEmailSucces(state, data) {
      notification('success', 'Email verification link resent successfully')
      return {
        ...state,
        loading: false
      }
    },
    oncreatePaymentButtonWidgetSucces(state, data) {
      notification('success', 'Payment site created successfully')
      return {
        ...state,
        loading: false
      }
    },
    onunLinkCalendarSucces(state, data) {
      notification('success', 'You have successfully unsynced calendar')
      return {
        ...state,
        loading: false
      }
    },
    onupdateQuickBookSettingsSucces(state, data) {
      //  notification("success", "Your quickbooks handle has been successfully unlinked")
      // if(window.location.pathname.indexOf("business-settings")>-1){
      //     notification("success", "Your QuickBooks handle has been linked successfully");
      // }
      return {
        ...state,
        loading: false,
        quickbookData: data
      }
    },
    onGetQuickBookInfoSuccess(state, data) {
      return {
        ...state,
        loading: false,
        quickbookData: data
      }
    },
    onUpdateThumbtackSettingsSucces(state, data) {
      notification('success', 'Your Thumbtack account has been successfully connected')
      // if(window.location.pathname.indexOf("business-settings")>-1){
      //     notification("success", "Your QuickBooks handle has been linked successfully");
      // }
      return {
        ...state,
        loading: false,
        thumbtackData: data
      }
    },
    onunLinkQuickbookSucces(state, data) {
      notification('success', 'Your QuickBooks handle has been unlinked successfully')
      return {
        ...state,
        loading: false
      }
    },
    onUpdateSettings(state, data) {
      return {
        ...state,
        loading: false,
        isSettingsUpdated: data
      }
    },
    onUpdateThumbtackCredentialsSuccess(state, data, payload) {
      notification('success', 'Thumbtack account updated successfully')
      return {
        ...state,
        loading: false
      }
    },
    onRemoveThumbtackServiceSuccess(state, data) {
      notification('success', 'Thumbtack account disconnected successfully')
      return {
        ...state,
        loading: false
      }
    },
    upadteBusinessNameSuccess(state, data, hideNotification) {
      if (!hideNotification) notification('success', 'Business updated successfully')
      return {
        ...state,
        loading: false,
        BussinessDatails: data.data
      }
    },
    onfetchLocationLogoSuccess(state, data) {
      return {
        ...state,
        loading: false,
        loactionLogo: data.logo
      }
    },
    upadteBusinessNameError(state, data) {
      const err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
          ? data.data.message
          : data && data.error
          ? data.error
          : data && data.data && data.data.error
          ? data.data.error
          : data && data.data && data.data.err_msg
          ? data.data.err_msg
          : 'Please try again later'
      notification('error', err)
      return {
        ...state,
        accountSettingsError: true
      }
    },
    setQuickBookAlertSuccess(state, data) {
      return {
        ...state,
        loading: false,
        showQuickBookAlert: data
      }
    },
    setThumbtackAlertSuccess(state, data) {
      return {
        ...state,
        loading: false,
        showThumbtackAlert: data
      }
    },
    onCloseQBAlert(state, data) {
      return {
        ...state,
        accountsettings: {
          ...state.accountsettings,
          isQuickbooksExpiredTokenNotified: false
        }
      }
    },
    isClosedNotificationtSuccess(state, data) {
      return {
        ...state,
        loading: false,
        accountsettings: data.data
      }
    },
    onGetUpgradeToProURLSuccess(state, data) {
      return {
        ...state,
        loading: false,
        upgradeToProURL: data.data
      }
    }
  },
  effects: {
    async fetchAccountSettings(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getAccountSettings(payload)
        this.onFetchSettings(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateAccountSettings(payload, rootState) {
      this.onRequestUpadteBusinessName()
      try {
        const res = await service.upadteAccountSettings(payload)
        this.onFetchSettings(res)
        this.onUpdateSettings(true)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async upadteBusinessName(payload, rootState, hideNotification) {
      const throwBackErr = payload.throwBackErr
      delete payload.throwBackErr
      this.onRequestUpadteBusinessName()
      try {
        const res = await service.upadteBusinessName(payload)
        this.upadteBusinessNameSuccess(res, hideNotification)
        return res
      } catch (e) {
        this.upadteBusinessNameError(e)
        if (throwBackErr) {
          throw e
        }
      }
    },
    async updateCustomLink(payload, rootState) {
      // this.onRequest();
      try {
        const res = await service.upadteCustomLink(payload.data)
        if (payload.successMessage) {
          notification('success', payload.successMessage)
        }
        this.onupdateCustomLinkSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateCustomLinkSocial(payload, rootState) {
      // this.onRequest();
      try {
        const res = await service.updateCustomLinkSocial(payload.data)
        if (payload.successMessage) {
          notification('success', payload.successMessage)
        }
        this.onupdateCustomLinkSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateSesEmail(payload, rootState) {
      // this.onRequest();
      try {
        const res = await service.updateSesEmail(payload)
        this.onupdateSesEmailSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateTemplateSettings(payload, rootState) {
      // this.onRequest();
      try {
        const res = await service.updateTemplateSettings(payload)
        if (res && res.message.toLowerCase().indexOf('success') > -1) {
          notification('success', 'Updated successfully')
        }
        this.onFetchSettings(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateGositeSettings(payload, rootState) {
      // this.onRequest();
      try {
        const res = await service.updateGositeSettings(payload.data)
        if (payload.successMessage || res.message.toLowerCase().indexOf('success') > -1) {
          notification('success', 'Gosite Account Settings Has Been Updated Succesfully')
        }
        this.onupdateGositeSettings(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchwidgets(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.fetchwidgets(payload)

        this.onfetchwidgetsSucces(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async widgetFavourate(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.widgetFavourate(payload)

        this.onwidgetFavourateSucces(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async removeReviewWidget(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.removeReviewWidget(payload)

        this.onremoveReviewWidgetSucces(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },

    async createReviewWidget(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.createReviewWidget(payload)

        this.oncreateReviewWidgetSucces(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getReviewWidget(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getReviewWidget(payload)

        this.ongetReviewWidgetSucces(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateReviewWidget(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.updateReviewWidget(payload)

        this.onUpdateReviewWidgetSucces(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async createPaymentButtonWidget(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.createPaymentButtonWidget(payload)
        this.oncreatePaymentButtonWidgetSucces(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async resendSesEmail(payload, rootState) {
      this.onnoLoadingRequest()
      try {
        const res = await service.resendSesEmail(payload)
        this.onresendSesEmailSucces(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async unLinkBookingCalendar(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.unLinkCalendar(payload)
        this.onunLinkCalendarSucces(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateQuickBookSettings(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.updateQuickBookSettings(payload)
        this.onupdateQuickBookSettingsSucces(res)
        return res
      } catch (e) {
        this.onQBError(e)
      }
    },
    async getQuickBookInfo(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getQuickBookInfo(payload)
        this.onGetQuickBookInfoSuccess(res)
        return res
      } catch (e) {
        this.onQBError(e)
      }
    },
    async updateThumbtackSettings(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.updateThumbtackSettings(payload)
        this.onUpdateThumbtackSettingsSucces(res)
        return res
      } catch (e) {
        this.onQBError(e)
      }
    },
    async getQuickBookTokens(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getQuickBookTokens(payload)
        return res
      } catch (e) {
        this.onQBError(e)
      }
    },
    async unLinkQuickbook(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.unLinkQuickbook(payload)
        this.onunLinkQuickbookSucces(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateUser(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.updateUser(payload)
        this.onSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateUserProfilePicture(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.updateUserProfilePicture(payload)
        this.onSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateSettingsStats(payload, rootState) {
      this.onUpdateSettings(false)
    },
    async updateThumbtackCredentials(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.updateThumbtackCredentials(payload)
        this.onUpdateThumbtackCredentialsSuccess(res)
        return res
      } catch (e) {
        this.onThumbStackError(e)
      }
    },
    async removeThumbtackService(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.removeThumbtackService(payload)
        this.onRemoveThumbtackServiceSuccess(res)
        return res
      } catch (e) {
        this.onThumbStackError(e)
      }
    },
    async fetchLocationLogo(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.fetchLocationLogo(payload)
        this.onfetchLocationLogoSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async setQuickBookAlert(payload, rootState) {
      try {
        this.setQuickBookAlertSuccess(payload)
        return true
      } catch (e) {
        console.log(e)
      }
    },

    async setThumbtackAlert(payload, rootState) {
      try {
        this.setThumbtackAlertSuccess(payload)
        return true
      } catch (e) {
        console.log(e)
      }
    },

    async closeQBAlert(payload, rootState) {
      try {
        const res = await service.closeQBAlert(payload)
        this.onCloseQBAlert(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async isClosedNotification(payload, rootState) {
      try {
        const res = await service.isClosedNotification(payload)
        this.isClosedNotificationtSuccess(res)
        return true
      } catch (e) {
        console.log(e)
      }
    },
    async getUpgradeToProURL(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getUpgradeToProUrl(payload)
        this.onGetUpgradeToProURLSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    }
  }
}
