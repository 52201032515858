import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Link } from 'react-router-dom'
import withPermissionHookHOC from '@helpers/WithPermissionHookHOC'
import { checkPermission, isEnabled, isMultiLocation } from '@helpers/utility'
import Icon from '@components/icons'
import GlobalCreateEnterprise from './globalCreateEnterprise/globalCreateEnterpriseModal'
import { getFeatureVal, featureFlags } from '@helpers/configcat'
import { DialogListener } from '@containers/common/DialogListener'

const mapDispatchToProps = ({ auth, messenger, accountsettings, enterpriseMessenger }) => {
  return {
    ...auth,
    ...messenger,
    ...accountsettings,
    ...enterpriseMessenger
  }
}

const mapStateToProps = ({ auth, messenger, accountsettings, enterpriseMessenger }) => {
  return {
    ...auth,
    ...messenger,
    ...accountsettings,
    ...enterpriseMessenger
  }
}

class SideBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userMenuVisible: false,
      showEnterpriseUNB: false,
      showGlobalCreateModal: false
    }
  }
  async componentDidMount() {
    if (!this.props.me) {
      await this.props.getMe()
    }
    if (!this.props.enterpriselocations) {
      await this.props.getEnterpriseLocations()
    }
    const showEnterpriseUNB = await getFeatureVal(featureFlags.showEnterpriseUNB, this.props.me)
    this.setState({
      showEnterpriseUNB
    })
  }
  logout() {
    this.props.logout()
  }

  getPlacementPath() {
    if (this.props.user && this.props.products) {
      if (isEnabled('gmb', this.props.products)) {
        return `/${this.props.user.user_unique_id}/enterprise/placement/overview`
      } else if (isEnabled('location', this.props.products)) {
        return `/${this.props.user.user_unique_id}/enterprise/placement/listing`
      } else if (isEnabled('posts', this.props.products)) {
        return `/${this.props.user.user_unique_id}/enterprise/placement/posts`
      }
    }
    return ''
  }

  sideBarScrollToBottom = (e) => {
    let menubarHeight, menubarULHeight
    const elemMenu = e.target.closest('#section_sideMenu')
    const elemMenuUl = e.target.closest('#section_sideMenu ul')
    setTimeout(function (e) {
      menubarHeight = elemMenu.clientHeight
      menubarULHeight = elemMenuUl.clientHeight
      if (menubarHeight < menubarULHeight) {
        elemMenu.scroll({
          top: 1000,
          behavior: 'smooth'
        })
      }
    }, 100)
  }

  handelCreateGlobal() {
    this.setState({ showGlobalCreateModal: true })
  }

  handelCreateGlobalClose() {
    this.setState({ showGlobalCreateModal: false })
  }

  render() {
    // var page = this.props.page || 'home'
    // const cookie = getCookie()
    const { page = 'home', permissions: { isReadOnlyRole } = {} } = this.props
    const { showGlobalCreateModal } = this.state
    return (
      <React.Fragment>
        <DialogListener />
        {this.props.user && (
          <aside className="sidemenu-panel sidemenu-panel-update" id="section_sideMenu">
            <div
              className={`px-15 ${
                this.props.showUpgradeTrial ? 'sidemenu-panel-container-for-upgrade' : 'sidemenu-panel-container'
              }`}>
              {this.state.showEnterpriseUNB && (
                <button
                  type="button"
                  id="unbButton"
                  className={`global-create-btn ${
                    showGlobalCreateModal ? 'global-create-btn-bg-active' : 'global-create-btn-bg'
                  }`}
                  onClick={() => this.handelCreateGlobal()}>
                  <Icon name="globalPlusIcon" />
                  <span className="global-create-btn-txt">New</span>
                </button>
              )}
              <ul className="b-sidemenu pl-0">
                {/* {this.props.user && this.props.user.role ? */}
                {(this.props.user &&
                  this.props.user.role &&
                  checkPermission('user', 'Admin.SiteAnalytics.View', this.props.me, this.props.products) &&
                  ['partner', 'manager'].indexOf(this.props.me.role_id.role_name) > -1) ||
                this.props.me.isMultiLocation ? (
                  <li id="navHome" className={`item ${page === 'home' ? 'item-active' : ''}`}>
                    <Link
                      className="d-block text-decoration-none"
                      to={this.props.user ? `/${this.props.user.user_unique_id}/analytics` : ''}>
                      <span className="text">Home</span>
                    </Link>
                  </li>
                ) : null}

                {isEnabled('user', this.props.products) || this.props.me.isMultiLocation ? (
                  <li id="navHome" className={`item ${page === 'locations' ? 'item-active' : ''}`}>
                    <Link
                      className="d-block text-decoration-none"
                      to={this.props.user ? `/${this.props.user.user_unique_id}/locations` : ''}>
                      <span className="text">Locations</span>
                    </Link>
                  </li>
                ) : null}
                {this.props.me &&
                checkPermission('review-manager', 'Admin.ReviewManager.View', this.props.me, this.props.products) &&
                this.props.user &&
                this.props.user.role &&
                (this.props.user.role === 'partner' ||
                  this.props.user.role === 'staff' ||
                  this.props.user.role === 'manager') ? (
                  <li id="navReviews" className={`item ${page === 'reviews' ? 'item-active' : ''}`}>
                    <Link
                      className="d-block text-decoration-none"
                      to={this.props.user ? `/${this.props.user.user_unique_id}/enterprise/reviews` : ''}>
                      <span className="text">Reviews</span>
                    </Link>
                  </li>
                ) : null}

                {isEnabled('location', this.props.products) ||
                isEnabled('gmb', this.props.products) ||
                isEnabled('posts', this.props.products) ? (
                  <li id="navPlacement" className={`item ${page === 'placement' ? 'item-active' : ''}`}>
                    <Link className="d-block text-decoration-none" to={this.getPlacementPath()}>
                      <span className="text">Placement</span>
                    </Link>
                  </li>
                ) : null}
                {this.props.me &&
                checkPermission('contact-manager', 'Admin.Contact.View', this.props.me, this.props.products) &&
                ['partner', 'manager'].indexOf(this.props.me.role_id.role_name) > -1 ? (
                  <li id="navContacthub" className={`item ${page === 'contacts' ? 'item-active' : ''}`}>
                    <Link
                      className="d-block text-decoration-none"
                      to={this.props.user ? `/${this.props.user.user_unique_id}/enterprise/contacts/mycontacts` : ''}>
                      <span className="text">Contact Hub</span>
                    </Link>
                  </li>
                ) : null}

                {isEnabled('booking', this.props.products) &&
                this.props.user &&
                this.props.user.role &&
                (this.props.user.role === 'partner' || this.props.user.role === 'manager') ? (
                  <li id="navReporting" className={`item ${page === 'services' ? 'item-active' : ''}`}>
                    <Link
                      className="d-block text-decoration-none"
                      to={this.props.user ? `/${this.props.user.user_unique_id}/enterprise/services` : ''}>
                      <span className="text">Services</span>
                    </Link>
                  </li>
                ) : null}

                {isEnabled('booking', this.props.products) &&
                this.props.user &&
                this.props.user.role &&
                (this.props.user.role === 'partner' ||
                  this.props.user.role === 'staff' ||
                  this.props.user.role === 'manager') ? (
                  <li id="navBooking" className={`item ${page === 'booking' ? 'item-active' : ''}`}>
                    <Link
                      className="d-block text-decoration-none"
                      to={this.props.user ? `/${this.props.user.user_unique_id}/enterprise/appointments` : ''}>
                      <span className="text">Booking</span>
                    </Link>
                  </li>
                ) : null}

                {isEnabled('messenger', this.props.products) &&
                ['partner', 'manager'].indexOf(this.props.me.role_id.role_name) > -1 ? (
                  <li id="navContacthub" className={`item ${page === 'messenger' ? 'item-active' : ''}`}>
                    <Link
                      className="d-block text-decoration-none"
                      to={this.props.user ? `/${this.props.user.user_unique_id}/enterprise/messenger` : ''}>
                      <span className="text">
                        Messenger{' '}
                        {this.props.messageCount && this.props.messageCount > 0 ? (
                          <span className="new-message-badge">New</span>
                        ) : null}
                      </span>
                    </Link>
                  </li>
                ) : null}
                {this.props.user && this.props.user.user_unique_id && this.props.user.role !== 'manager' ? (
                  <li id="navSettings" className={'item '}>
                    <a
                      className="d-block text-decoration-none"
                      data-toggle="collapse"
                      href="#collapseSettings"
                      aria-expanded="false"
                      aria-controls="collapseSettings">
                      <span className="text">Settings</span>
                    </a>
                    <ul
                      className={`collapse pt-30 pl-20 ${page === 'branding' || page === 'site' ? 'show' : ''}`}
                      id="collapseSettings">
                      {this.props.user &&
                      this.props.user.role &&
                      (this.props.user.role === 'partner' || this.props.user.role === 'staff') ? (
                        <li id="navBrandingSettings" className={`item ${page === 'branding' ? 'item-active' : ''}`}>
                          <Link
                            className="d-block text-decoration-none"
                            to={this.props.user ? `/${this.props.user.user_unique_id}/white-label-settings` : ''}>
                            <span className="text">Branding</span>
                          </Link>
                        </li>
                      ) : null}
                      {this.props.user &&
                      this.props.user.role &&
                      (this.props.user.role === 'partner' || this.props.user.role === 'staff') ? (
                        <li id="navSiteSettings" className={`item ${page === 'site' ? 'item-active' : ''}`}>
                          <Link
                            className="d-block text-decoration-none"
                            to={this.props.user ? `/${this.props.user.user_unique_id}/site-settings` : ''}>
                            <span className="text">Site Settings</span>
                          </Link>
                        </li>
                      ) : null}
                    </ul>
                  </li>
                ) : null}

                {this.props.me &&
                checkPermission('user', 'Admin.Manager.Manage', this.props.me, this.props.products) &&
                isEnabled('user', this.props.products) ? (
                  <li id="navPlacement" className={`item ${page === 'managers' ? 'item-active' : ''}`}>
                    <Link
                      className="d-block text-decoration-none"
                      to={this.props.user ? `/${this.props.user.user_unique_id}/enterprise/managers` : ''}>
                      <span className="text">Managers</span>
                    </Link>
                  </li>
                ) : null}
                {(isEnabled('review-manager', this.props.products) ||
                  isEnabled('booking', this.props.products) ||
                  isEnabled('messenger', this.props.products)) &&
                this.props.user &&
                this.props.user.role !== 'manager' &&
                !isMultiLocation() ? (
                  <li id="navWidget" className={'item '}>
                    <a
                      className="d-block text-decoration-none"
                      onClick={(e) => this.sideBarScrollToBottom(e)}
                      data-toggle="collapse"
                      href="#collapseWidget"
                      aria-expanded="false"
                      aria-controls="collapseWidget">
                      <span className="text">Widgets</span>
                    </a>
                    {/* <ul className={`collapse pt-30 pl-20 ${page === "widgets" || isEnabled("messenger", this.props.products) || page === "multimessenger" ? "show" : ""}`} id="collapseWidget"> */}
                    <ul
                      className={`collapse pt-30 pl-20 ${
                        page === 'widgets' || page === 'multimessenger' || page === 'multiwidget' ? 'show' : ''
                      }`}
                      id="collapseWidget">
                      {(isEnabled('review-manager', this.props.products) ||
                        isEnabled('booking', this.props.products) ||
                        (this.props.enterpriselocations && this.props.enterpriselocations.length)) &&
                      this.props.user &&
                      this.props.user.role &&
                      (this.props.user.role === 'partner' || this.props.user.role === 'manager') ? (
                        <li id="navWidget" className={`item ${page === 'widgets' ? 'item-active' : ''}`}>
                          <Link
                            className="d-block text-decoration-none"
                            to={
                              this.props.user ? `/${this.props.user.user_unique_id}/enterprise/widgets/settings` : ''
                            }>
                            <span className="text">Widgets</span>
                          </Link>
                        </li>
                      ) : null}
                      {(isEnabled('review-manager', this.props.products) ||
                        isEnabled('booking', this.props.products) ||
                        (this.props.enterpriselocations && this.props.enterpriselocations.length)) &&
                      this.props.user &&
                      this.props.user.role &&
                      (this.props.user.role === 'partner' || this.props.user.role === 'manager') ? (
                        <li id="navMultiWidget" className={`item ${page === 'multiwidget' ? 'item-active' : ''}`}>
                          <Link
                            className="d-block text-decoration-none"
                            to={this.props.user ? `/${this.props.user.user_unique_id}/enterprise/widgets/widget` : ''}>
                            <span className="text">Multi Reviews</span>
                          </Link>
                        </li>
                      ) : null}
                      {isEnabled('messenger', this.props.products) &&
                      this.props.user &&
                      this.props.user.role &&
                      (this.props.user.role === 'partner' ||
                        this.props.user.role === 'staff' ||
                        this.props.user.role === 'manager') ? (
                        <li id="navBooking" className={`item ${page === 'multimessenger' ? 'item-active' : ''}`}>
                          <Link
                            className="d-block text-decoration-none"
                            to={this.props.user ? `/${this.props.user.user_unique_id}/widgets/messenger` : ''}>
                            <span className="text">Multi Messenger</span>
                          </Link>
                        </li>
                      ) : null}
                    </ul>
                  </li>
                ) : null}
              </ul>
            </div>
          </aside>
        )}
        {this.state.showGlobalCreateModal ? (
          <GlobalCreateEnterprise
            isReadOnlyRole={isReadOnlyRole}
            me={this.props.me}
            open={this.state.showGlobalCreateModal}
            closeModal={this.handelCreateGlobalClose.bind(this)}
            permissions={this.props.permissions}
            {...this.props}
          />
        ) : (
          ''
        )}
      </React.Fragment>
    )
  }
}

export default withPermissionHookHOC(compose(connect(mapStateToProps, mapDispatchToProps))(SideBar))
